<mat-card class="body-wrapper" *ngIf="company">
    <app-return-button [path]="'/companies/'+company.id" [text]="company.name"></app-return-button>
    <h1>Coupons <span matTooltipPosition="above"
            [matTooltip]="(startDate|date:'dd. MM. yyyy')+' – '+(endDate|date:'dd. MM. yyyy')">in Kalenderwoche
            {{getWeekNumber()}}</span> von {{endDate.getFullYear()}}</h1>
    <app-week-pagination-buttons></app-week-pagination-buttons>
    <h3 *ngIf="users?.length==0">In dieser Woche wurden noch keine Coupons verwendet</h3>
    <table *ngIf="users?.length>0">
        <thead>
            <tr>
                <th>Benutzer</th>
                <th>Anzahl der Coupons in dieser Woche</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let user of users">
                <td [matTooltip]="user.username">{{user.firstname}} {{user.lastname}}</td>
                <td>{{user.coupon_count}}</td>
            </tr>
        </tbody>
        <tfoot>
            <tr>
                <td class="total">Gesamt</td>
                <td class="sum">{{total(users)}}</td>
            </tr>
        </tfoot>
    </table>
    <app-loading-animation [condition]="!users"></app-loading-animation>
</mat-card>
<app-loading-animation [condition]="!company"></app-loading-animation>