<h3 *ngIf="usersByDate?.length==0">In diesem Monat wurden noch keine Menüs bestellt</h3>
<table *ngIf="usersByDate?.length>0">
    <thead>
        <tr>
            <th>Benutzer</th>
            <th>Anzahl der bestellten Menüs pro Tag</th>
        </tr>
    </thead>
    <tbody *ngFor="let ubd of usersByDate">
        <tr>
            <td class="date" colspan="2"><strong>{{ubd.date|date:'d. MMMM'}}</strong></td>
        </tr>
        <tr class="user" *ngFor="let user of ubd.users">
            <td [matTooltip]="user.username">{{user.firstname}} {{user.lastname}}</td>
            <td>{{user.menu_count}}</td>
        </tr>
    </tbody>
</table>
<app-loading-animation [condition]="!usersByDate"></app-loading-animation>