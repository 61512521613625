import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import * as moment from 'moment';
import {CompanyService} from '../../services/company.service';

@Component({
  selector: 'app-order-stats',
  template: `
    <mat-card class="body-wrapper">
      <mat-card-content>
        <app-return-button [path]="'/dashboard'" [text]="'Dashboard'"></app-return-button>
        <h1>Bestellungsübersicht <span matTooltipPosition="above"
                                       [matTooltip]="(startDate|date:'dd. MM. yyyy')+' – '+(endDate|date:'dd. MM. yyyy')">für Kalenderwoche
          {{getWeekNumber()}}</span> von {{endDate.getFullYear()}}
        </h1>

        <button mat-raised-button (click)="changeView()">
          <mat-icon *ngIf="view=='details'">visibility_off</mat-icon>
          <mat-icon *ngIf="view=='less-details'">visibility</mat-icon>
          <span>{{view == 'details' ? "Details ausblenden" : "Details anzeigen"}}</span>
        </button>

        <app-week-pagination-buttons></app-week-pagination-buttons>

        <router-outlet></router-outlet>
      </mat-card-content>
    </mat-card>
  `,
  styles: [
    'h1 { text-align: center; }',
    'button { margin: 0 auto; display: block; }',
    'button mat-icon{ margin-right: 10px; }'
  ]
})
export class OrderStatsComponent implements OnInit {

  startDate: Date;
  endDate: Date;
  view: string;

  constructor(private route: ActivatedRoute,
              private companyService: CompanyService,
              private router: Router) {
  }

  ngOnInit(): void {

    this.view = this.route.snapshot.firstChild.url[0]?.path ?? 'less-details';

    this.route.queryParams.subscribe(params => {
      if (moment(params.date, 'YYYY-M-D', true).isValid())
        this.startDate = moment(params.date, 'YYYY-M-D').toDate();

      this.endDate = moment(params.date, 'YYYY-M-D').toDate();
      this.endDate.setDate(this.endDate.getDate() + 3);
    });

  }

  changeView(): void {
    if (this.view == 'details') {
      this.router.navigate(['/order-stats'], {queryParamsHandling: 'preserve'});
      this.view = 'less-details';
    } else {
      this.router.navigate(['/order-stats', 'details'], {queryParamsHandling: 'preserve'});
      this.view = 'details';
    }
  }

  getWeekNumber(): number {
    let tdt = new Date(this.startDate);
    let dayn = (this.startDate.getDay() + 6) % 7;
    tdt.setDate(tdt.getDate() - dayn + 3);
    let firstThursday = tdt.valueOf();
    tdt.setMonth(0, 1);
    if (tdt.getDay() !== 4) {
      tdt.setMonth(0, 1 + ((4 - tdt.getDay()) + 7) % 7);
    }
    return 1 + Math.ceil((firstThursday - tdt.valueOf()) / 604800000);
  }

}
