<mat-list-item>

  <div class="content">

    <div class="list-data-wrapper">
      <div class="main-text">
        {{message.subject | shorten}}
      </div>
      <div class="secondary-text">
        {{message.message | shorten}}
      </div>
    </div>

    <div style="flex: auto;"></div>

    <div class="message-text">
        <span>
            <strong>
                {{(parseDate(message.send_time).getTime()<=now.getTime())?'Gesendet am:':'Geplant für:'}}
            </strong>
          {{message.send_time |dateTimeFormatFilter:'D.MM.YYYY HH:mm'}}
        </span>
      <p *ngIf="message.company">
        <strong>An: </strong>
        <span class="reciever-link" [routerLink]="['/companies',message.company_id]">
                {{message.company.name}}
            </span>
      </p>
      <p *ngIf="message.user">
        <strong>An: </strong>
        {{message.user.firstname}} {{message.user.lastname}}
      </p>
    </div>
    <mat-icon *ngIf="message.send_time && parseDate(message.send_time).getTime()>now.getTime()"
              matTooltip="Geplante Nachricht löschen" (click)="remove()">clear</mat-icon>
    <mat-icon matTooltip="Details" (click)="openInfo()">info</mat-icon>
    <mat-divider></mat-divider>

  </div>

</mat-list-item>
