import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Menu } from '../../../model/menu.model';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-menu-details',
  templateUrl: './menu-details.component.html',
  styleUrls: ['./menu-details.component.scss']
})
export class MenuDetailsComponent implements OnInit {

  url:string=environment.url;

  constructor(private dialogRef:MatDialogRef<MenuDetailsComponent>,
    @Inject(MAT_DIALOG_DATA)public menu:Menu) { }

  ngOnInit(): void {
  }



}
