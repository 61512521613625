import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-loading-animation',
  template: `
        <!-- <mat-progress-bar *ngIf="show" mode="indeterminate"></mat-progress-bar> -->
        <mat-spinner *ngIf="show"></mat-spinner>
  `,
  styles: [
  ]
})
export class LoadingAnimationComponent implements OnInit {

  @Input("condition")
  show: boolean;

  constructor() { }

  ngOnInit(): void {
  }



}
