import { Pipe, PipeTransform } from '@angular/core';
import { Menu } from 'src/app/model/menu.model';

@Pipe({
  name: 'sortMenusByDate',
  pure: false
})
export class SortMenusByDatePipe implements PipeTransform {

  transform(menusByDate: { date: Date, menus: Menu[] }[]): { date: Date, menus: Menu[] }[] {
    return menusByDate.sort((a, b) => {
      return a.date < b.date ? -1 : (a.date > b.date ? 1 : 0);
    });
  }

}
