import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MessageService } from '../../../services/message.service';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { Company } from '../../../model/company.model';
import { CompanyService } from '../../../services/company.service';
import { UserService } from '../../../services/user.service';
import { Observable } from 'rxjs';
import { User } from '../../../model/user.model';
import { startWith, map, take } from 'rxjs/operators';
import { Message } from '../../../model/message.model';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-add-message',
  templateUrl: './add-message.component.html',
  styleUrls: ['./add-message.component.scss']
})
export class AddMessageComponent implements OnInit {

  addMessageForm = new UntypedFormGroup({
    subject: new UntypedFormControl('', [Validators.required]),
    message: new UntypedFormControl('', [Validators.required]),
    sendNow: new UntypedFormControl(true),
    send_time: new UntypedFormControl(null),
    reciever: new UntypedFormControl('all'),
    company: new UntypedFormControl(null),
    user: new UntypedFormControl(null)
  });


  date_now: Date = new Date();
  companyOptions: Observable<Company[]>;
  userOptions: Observable<{ name: string, user: User }[]>;

  constructor(private dialogRef: MatDialogRef<AddMessageComponent>,
    private snackbar: MatSnackBar,
    private messageService: MessageService,
    private companyService: CompanyService,
    private userService: UserService,
    private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.companyService.getAll();
    this.companyService.companies.subscribe((companies: Company[]) => {
      this.companyOptions = this.addMessageForm.get("company").valueChanges
        .pipe(
          startWith(''),
          map(value => typeof value === 'string' ? value : value.name),
          map(name => name ?
            companies.filter(option => option.name.toLowerCase().indexOf(name.toLowerCase()) >= 0) :
            companies.slice())
        );
    });

    this.userService.getAllWithoutPagination();
    this.userService.users.subscribe((users: User[]) => {
      var userOpt: { name: string, user: User }[] = [];
      if (users)
        users.forEach(user => userOpt.push({ name: `${user.firstname} ${user.lastname} - ${user.username} ${user.company ? '(' + user.company.name + ')' : ''}`, user: user }));

      this.userOptions = this.addMessageForm.get("user").valueChanges
        .pipe(
          startWith(''),
          map(value => typeof value === 'string' ? value : value.name),
          map(name => name ?
            userOpt.filter(option => option.name.toLowerCase().indexOf(name.toLowerCase()) >= 0) :
            userOpt.slice())
        );
    })

  }

  cancel() {
    this.dialogRef.close(false);
  }

  add() {
    var send_time2 = null;
    var user_id = null;
    var company_id = null;
    var date: Date = null;
    if (!this.addMessageForm.get('sendNow').value) {
      date = new Date(this.addMessageForm.get('send_time').value);
      send_time2 = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()} ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`;
    }

    if (this.addMessageForm.get('reciever').value == 'user')
      user_id = this.addMessageForm.get('user').value.id;
    if (this.addMessageForm.get('reciever').value == 'company')
      company_id = this.addMessageForm.get('company').value.id

    this.messageService.create(this.addMessageForm.get('subject').value, this.addMessageForm.get('message').value, date, send_time2, user_id, company_id).pipe(take(1)).subscribe((message: Message) => {
      this.snackbar.open("Die Benachrichtigung wurde erfolgreich erstellt", "Schließen", { duration: 5000 });
      var params = this.route.snapshot.queryParams;
      this.messageService.getAll(+(params['page'] ?? 1), params['for'] ?? 'user');
      this.dialogRef.close(true);
    }, error => this.snackbar.open(error.error.error, "Schließen", { duration: 10000, panelClass: ['warn-snackbar'] }));
  }

  isObject(val): boolean { return val instanceof Object; }
  isDate(val): boolean {
    var date = new Date(val);
    return date instanceof Date;
  }

  displayFnCompany(company: Company): string {
    return company && company.name ? company.name : '';
  }

  displayFnUser(user: User): string {
    return user ? `${user.firstname} ${user.lastname} - ${user.username} ${user.company ? '(' + user.company.name + ')' : ''}` : '';
  }

}
