import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-return-button',
  template: `
    <button mat-stroked-button [routerLink]="path" [queryParams]="queryParams"><mat-icon>navigate_before</mat-icon><span>{{text}}</span></button>
  `,
  styles: [
    `button{top:0; left:0;margin-bottom:13px}`
  ]
})
export class ReturnButtonComponent implements OnInit {

  @Input("path")
  path: string;

  @Input("queryParams")
  queryParams: string;

  @Input("text")
  text: string;

  constructor() { }

  ngOnInit(): void {
  }


}
