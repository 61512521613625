import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { Message } from '../model/message.model';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { PaginationInfo } from '../utils/pagination-info.model';
import { take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class MessageService {

  private _messages: BehaviorSubject<Message[]>;
  private _paginationInfo: BehaviorSubject<PaginationInfo>;

  get paginationInfo(): Observable<PaginationInfo> {
    return this._paginationInfo.asObservable();
  }

  get messages(): Observable<Message[]> {
    return this._messages.asObservable();
  }

  constructor(private http: HttpClient) {
    this._messages = new BehaviorSubject<Message[]>([]);
    this._paginationInfo = new BehaviorSubject<PaginationInfo>(null);
  }



  create(subject: string, message: string, send_time = null, send_time2 = null, user_id: number = null, company_id: number = null): Observable<Message> {
    return this.http.post<Message>(environment.apiURL + 'messages', {
      "subject": subject,
      "message": message,
      "send_time": send_time,
      "send_time2": send_time2,
      "user_id": user_id,
      "company_id": company_id
    });
  }

  public getAll(page: number, _for: string = 'all') {
    this.http.get<{ "pagination_info": PaginationInfo, "messages": Message[] }>(environment.apiURL + `messages?for=${_for}&page=${page}`).pipe(take(1)).subscribe(data => {
      this._paginationInfo.next(data.pagination_info);
      this._messages.next(data.messages);
    });
  }

  public delete(message_id: number): Observable<any> {
    return this.http.delete<any>(environment.apiURL + `messages/${message_id}`);
  }

}
