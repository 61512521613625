import { Pipe, PipeTransform } from '@angular/core';
import { Dish } from '../../../model/dish.model';

@Pipe({
  name: 'searchDishes',
  pure: false
})
export class SearchDishesPipe implements PipeTransform {

  transform(dishes: Dish[], searchString: string): Dish[] {
    if (searchString === '' || searchString === null)
      return dishes.sort((a, b) => this.parseDate(a.created_at).getTime() - this.parseDate(b.created_at).getTime());

    var filteredDishes: Dish[] = dishes.filter(dish => dish.name.toLowerCase().indexOf(searchString.toLowerCase()) >= 0);
    dishes.forEach(dish => {
      var exists: boolean = false;

      filteredDishes.forEach(d => {
        if (dish.id === d.id)
          exists = true;
      });

      if (!exists)
        filteredDishes.push(dish);
    });
    return filteredDishes;
  }


  private parseDate(dateString): Date {
    return new Date(dateString);
  }
}
