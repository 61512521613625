import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { UserService } from '../../services/user.service';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { User } from 'src/app/model/user.model';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  loginForm = new UntypedFormGroup({
    domain: new UntypedFormControl(''),
    username: new UntypedFormControl('', [Validators.required]),
    password: new UntypedFormControl('', [Validators.required])
  });

  loading:boolean=false;

  constructor(private userService: UserService,
    private snackBar: MatSnackBar,
    private router: Router) {
  }

  ngOnInit() {
    this.userService.user.subscribe(user => {
      if (!user)
        return;
      if (user.activated){
        this.router.navigate(['dashboard']);
        return;
      }
      if(!user.activated){
        this.router.navigate(['activate']);
        return;
      }
    });
  }

  onLogin() {
    this.loading=true;
    this.userService.login(
      this.loginForm.get("username").value,
      this.loginForm.get("password").value,
      this.loginForm.get("domain").value
    ).subscribe((user: User) => {
      if(!user.activated){
        this.router.navigate(['/activate']);
        return;
      }
      this.router.navigate(['']);
      this.loading=false;
    }, errorMessage => {
      this.snackBar.open(errorMessage, "Schließen", { duration: 10000, panelClass: ['warn-snackbar'] });
      this.loading=false;
    });
  }
}
