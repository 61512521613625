import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { CompanyService } from '../../../services/company.service';
import { Observable } from 'rxjs';
import { Company } from 'src/app/model/company.model';
import { startWith, map, take } from 'rxjs/operators';
import { UserService } from '../../../services/user.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { User } from 'src/app/model/user.model';
import { CreateUserPdfComponent } from '../create-user-pdf/create-user-pdf.component';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-add-user',
  templateUrl: './add-user.component.html',
  styleUrls: ['./add-user.component.scss']
})
export class AddUserComponent implements OnInit {

  addUserForm: UntypedFormGroup = new UntypedFormGroup({
    firstname: new UntypedFormControl('', [Validators.required]),
    lastname: new UntypedFormControl('', [Validators.required]),
    password: new UntypedFormControl(this.makePassword(8), [Validators.required, Validators.minLength(6)]),
    role: new UntypedFormControl('NORMAL', [Validators.required]),
    company: new UntypedFormControl('')
  });

  companyOptions: Observable<Company[]>;

  constructor(private dialogRef: MatDialogRef<AddUserComponent>,
    private companyService: CompanyService,
    private userService: UserService,
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
    private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.companyService.getAll();
    this.companyService.companies.subscribe((companies: Company[]) => {
      this.companyOptions = this.addUserForm.get("company").valueChanges
        .pipe(
          startWith(''),
          map(value => typeof value === 'string' ? value : value.name),
          map(name => name ?
            companies.filter(option => option.name.toLowerCase().indexOf(name.toLowerCase()) >= 0) :
            companies.slice())
        );
    });
  }

  displayFn(company: Company): string {
    return company && company.name ? company.name : '';
  }

  isObject(val): boolean { return val instanceof Object; }

  private makePassword(length: number) {
    var result = '';
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  cancel() {
    this.dialogRef.close();
  }

  add() {
    this.userService.register(
      this.addUserForm.get("firstname").value,
      this.addUserForm.get("lastname").value,
      this.addUserForm.get("password").value,
      this.addUserForm.get("role").value,
      this.addUserForm.get("company").value.domain).pipe(take(1)).subscribe(
        (user: User) => {
          this.snackBar.open(`Der Benutzer ${user.firstname} ${user.lastname} wurde erstellt`, "Schließen", { duration: 3000 });
          this.userService.getAll(+(this.route.snapshot.queryParams["page"] ?? 1));
          this.dialog.open(CreateUserPdfComponent, {
            data: {
              "user": user,
              "password": this.addUserForm.get("password").value,
              "company": this.addUserForm.get("company").value
            }
          })
          this.dialogRef.close();
        },
        error => this.snackBar.open("Es ist ein Fehler aufgetreten", "Schließen", { duration: 10000, panelClass: ['warn-snackbar'] })
      )
  }

}
