import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import { take } from 'rxjs/operators';
import { User } from 'src/app/model/user.model';
import { UserService } from '../../services/user.service';
import { Company } from '../../model/company.model';
import { CompanyService } from '../../services/company.service';

@Component({
  selector: 'app-coupon-view',
  templateUrl: './coupon-view.component.html',
  styleUrls: ['./coupon-view.component.scss']
})
export class CouponViewComponent implements OnInit {


  startDate: Date;
  endDate: Date;
  company: Company;
  users: User[];

  constructor(private route: ActivatedRoute,
    private userSerivce: UserService,
    private companyService: CompanyService) { }

  ngOnInit(): void {
    this.companyService.getOne(+this.route.snapshot.params['company_id']).pipe(take(1)).subscribe((company: Company) => this.company = company);
    this.route.queryParams.subscribe(params => {
      this.users=null;

      if (moment(params['date'], "YYYY-M-D", true).isValid())
        this.startDate = new Date(params['date']);

      this.endDate = new Date(params['date']);
      this.endDate.setDate(this.endDate.getDate() + 3);

      this.userSerivce.getUsersByCompanyWithCouponCount(+this.route.snapshot.params['company_id'], this.makeDateString(this.startDate), this.makeDateString(this.endDate)).pipe(take(1)).subscribe(
        (users: User[]) => {
          this.users = users;
        });
    });
  }

  getWeekNumber() {
    var tdt = new Date(this.startDate);
    var dayn = (this.startDate.getDay() + 6) % 7;
    tdt.setDate(tdt.getDate() - dayn + 3);
    var firstThursday = tdt.valueOf();
    tdt.setMonth(0, 1);
    if (tdt.getDay() !== 4) {
      tdt.setMonth(0, 1 + ((4 - tdt.getDay()) + 7) % 7);
    }
    return 1 + Math.ceil((firstThursday - tdt.valueOf()) / 604800000);
  }

  total(users:User[]):number{
    return users.reduce((a,b)=>a+(b.coupon_count||0),0);
  }

  private makeDateString(date: Date): string {
    return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`
  }
}
