import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Dish } from '../../model/dish.model';
import { DishService } from '../../services/dish.service';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AddDishComponent } from './add-dish/add-dish.component';
import { environment } from 'src/environments/environment';
import { ConfirmationDialogComponent } from '../../utils/confirmation-dialog/confirmation-dialog.component';
import { take } from 'rxjs/operators';
import { EditDishComponent } from './edit-dish/edit-dish.component';

@Component({
  selector: 'app-dishes',
  templateUrl: './dishes.component.html',
  styleUrls: ['./dishes.component.scss']
})
export class DishesComponent implements OnInit {

  dishes: Observable<Dish[]>;
  url: string = environment.url;
  searchDish: string = "";

  constructor(private dishService: DishService,
    private dialog: MatDialog,
    private snackbar: MatSnackBar) { }

  ngOnInit(): void {
    this.dishes = this.dishService.dishes;
    this.dishService.getAll();
  }

  add() {
    this.dialog.open(AddDishComponent, {
      width: "600px"
    });
  }

  edit(dish: Dish) {
    this.dialog.open(EditDishComponent, {
      width: "600px",
      data: dish
    });
  }

  remove(dish: Dish) {
    var dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: "350px",
      data: `Wollen Sie das Gericht '${dish.name}' wirklich löschen?`
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.dishService.delete(dish.id).pipe(take(1)).subscribe(() => {
          this.snackbar.open(`Das Gericht '${dish.name}' wurde gelöscht`, "Schließen", { duration: 5000 });
          this.dishService.getAll();
        }, error => this.snackbar.open("Beim Löschen ist etwas fehlgeschlagen", "Schließen", { duration: 10000, panelClass: ['warn-snackbar'] }));
      }
    });
  }

}
