import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { UserService } from '../../services/user.service';
import { Subscription } from 'rxjs';
import { User } from '../../model/user.model';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { EditUserComponent } from '../users/edit-user/edit-user.component';
import { ResetUserPasswordComponent } from '../users/reset-user-password/reset-user-password.component';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit, OnDestroy {
  isAuthenticated: boolean = false;
  currentUser: User;
  private userSub: Subscription;

  windowWidth: number;

  constructor(private userService: UserService,
    private router: Router,
    private dialog: MatDialog) { }

  ngOnInit() {
    this.userSub = this.userService.user.subscribe(user => {
      this.isAuthenticated = !!user;
      this.currentUser = user;
    });
  }
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.windowWidth = event.target.innerWidth
  }

  ngOnDestroy(): void {
    this.userSub.unsubscribe();
  }

  logout() {
    this.userService.logout();
  }

  edit() {
    var dialogRef = this.dialog.open(EditUserComponent, {
      width: "600px",
      data: this.currentUser
    });
    dialogRef.afterClosed().subscribe(user => {
      if (user) {
        this.userService.logout();
      }
    });
  }

  changePassword() {
    var dialogRef = this.dialog.open(ResetUserPasswordComponent, {
      width: '600px',
      data: this.currentUser
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.userService.logout();
      }
    });
  }

}