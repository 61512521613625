import {Component, OnInit} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {ActivatedRoute} from '@angular/router';
import * as moment from 'moment';
import {Observable} from 'rxjs/internal/Observable';
import {take} from 'rxjs/operators';
import {Company} from 'src/app/model/company.model';
import {Menu} from 'src/app/model/menu.model';
import {CompanyService} from 'src/app/services/company.service';
import {MenuService} from 'src/app/services/menu.service';
import {MenuDetailsComponent} from '../../menus/menu-details/menu-details.component';

@Component({
  selector: 'app-order-stats-less-details',
  templateUrl: './order-stats-less-details.component.html',
  styleUrls: ['./order-stats-less-details.component.scss']
})
export class OrderStatsLessDetailsComponent implements OnInit {

  startDate: Date;
  endDate: Date;
  menus: Observable<Menu[]>;
  companies: Observable<Company[]>;
  loading: boolean = true;

  menusByDate: { date: Date, menus: Menu[] }[];
  companiesWithGroupedMenus: { company_id: number, company_name: string, groupedMenus: { available_date: Date, menu_id: number, name: string, menu_count: number, vegetarian: boolean, low_carb: boolean, special: boolean }[] }[];
  menusSum: { available_date: Date, menu_id: number, name: string, menu_count: number, vegetarian: boolean, low_carb: boolean, special: boolean }[]
  menusSumByDate: { available_date: Date, menu_count: number, availableMenusCount: number }[]

  constructor(private route: ActivatedRoute,
              private menuService: MenuService,
              private dialog: MatDialog,
              private companyService: CompanyService) {
  }

  ngOnInit(): void {

    this.companies = this.companyService.companies;
    this.menus = this.menuService.menus;

    this.route.queryParams.subscribe(params => {

      this.loading = true;

      if (moment(params['date'], "YYYY-M-D", true).isValid())
        this.startDate = moment(params['date'], "YYYY-M-D").toDate(); // parse date with moment.js to also work in Safari
      // this.startDate = new Date(params['date']);

      // this.endDate = new Date(params['date']);
      this.endDate = moment(params['date'], "YYYY-M-D").toDate(); // parse date with moment.js to also work in Safari
      this.endDate.setDate(this.endDate.getDate() + 3);
      this.menuService.getAll(this.makeDateString(this.startDate), this.makeDateString(this.endDate), true);

      this.companyService.getAllWithMenus(this.makeDateString(this.startDate), this.makeDateString(this.endDate)).pipe(take(1)).subscribe(
        (groupedMenus: {
          available_date: Date,
          company_id: number,
          company_name: string,
          menu_count: number,
          menu_id: number,
          name: string,
          vegetarian: boolean,
          low_carb: boolean,
          special: boolean
        }[]) => {
          this.companiesWithGroupedMenus = [];

          groupedMenus.forEach(gm => {

            var companyExists = false;

            this.companiesWithGroupedMenus.forEach(cgm => {
              if (gm.company_id == cgm.company_id)
                companyExists = true;
            });
            if (!companyExists) {
              this.companiesWithGroupedMenus.push(
                {
                  company_id: gm.company_id,
                  company_name: gm.company_name,
                  groupedMenus: [
                    {
                      available_date: gm.available_date,
                      menu_id: gm.menu_id,
                      name: gm.name,
                      menu_count: gm.menu_count,
                      vegetarian: gm.vegetarian,
                      low_carb: gm.low_carb,
                      special: gm.special
                    }
                  ]
                });
            } else {
              let updateCGM = this.companiesWithGroupedMenus.find(cgm => cgm.company_id === gm.company_id);
              let index = this.companiesWithGroupedMenus.indexOf(updateCGM);

              updateCGM.groupedMenus.push({
                available_date: gm.available_date,
                menu_id: gm.menu_id,
                name: gm.name,
                menu_count: gm.menu_count,
                vegetarian: gm.vegetarian,
                low_carb: gm.low_carb,
                special: gm.special
              });
              this.companiesWithGroupedMenus[index] = updateCGM;
            }
          });

          this.menuService.getAllAsObservable(this.makeDateString(this.startDate), this.makeDateString(this.endDate), true).pipe(take(1)).subscribe((menus: Menu[]) => {

            this.menusByDate = [];
            menus.forEach(menu => {
              var set = false;
              this.menusByDate.forEach(md => {
                if (md.date == menu.available_date) {
                  set = true;
                  md.menus.push(menu);
                  return;
                }
              });
              if (!set) {
                this.menusByDate.push({date: menu.available_date, menus: [menu]});
              }

            });


            // add menus that have not been ordered to list
            menus.forEach(menu => {
              this.companiesWithGroupedMenus.forEach(cgm => {
                var exists = false;
                cgm.groupedMenus.forEach(gm => {
                  if (gm.menu_id == menu.id)
                    exists = true;
                });
                if (!exists)
                  cgm.groupedMenus.push({
                    available_date: menu.available_date,
                    menu_id: menu.id,
                    name: menu.name,
                    menu_count: 0,
                    vegetarian: menu.vegetarian,
                    low_carb: menu.low_carb,
                    special: menu.special
                  });
              });
            });

            // create List for footer
            this.menusSum = [];
            menus.forEach(m => {
              var exists = false;
              this.menusSum.forEach(ms => {
                if (ms.menu_id == m.id)
                  exists = true;
              });
              if (!exists) {
                var menu_count = 0;
                groupedMenus.forEach(gm => gm.menu_id == m.id ? menu_count += gm.menu_count : null);
                this.menusSum.push({
                  menu_id: m.id,
                  menu_count: menu_count,
                  available_date: m.available_date,
                  name: m.name,
                  vegetarian: m.vegetarian,
                  low_carb: m.low_carb,
                  special: m.special
                });
              }
            });

            // create List for second footer
            this.menusSumByDate = [];
            this.menusSum.forEach(ms => {
              var exists = false;
              this.menusSumByDate.forEach(msd => {
                if (ms.available_date == msd.available_date)
                  exists = true;
              });
              if (!exists)
                this.menusSumByDate.push({
                  available_date: ms.available_date,
                  menu_count: ms.menu_count,
                  availableMenusCount: 1
                })
              else {
                let updateMsd = this.menusSumByDate.find(msd => msd.available_date === ms.available_date);
                let index = this.menusSumByDate.indexOf(updateMsd);
                updateMsd.menu_count += ms.menu_count;
                updateMsd.availableMenusCount++;
                this.menusSumByDate[index] = updateMsd;
              }
            });

            this.loading = false;
          });
        });

    });
  }


  openMenuDetails(menu: Menu) {
    this.dialog.open(MenuDetailsComponent, {
      data: menu
    })
  }

  getWeekNumber() {
    var tdt = new Date(this.startDate);
    var dayn = (this.startDate.getDay() + 6) % 7;
    tdt.setDate(tdt.getDate() - dayn + 3);
    var firstThursday = tdt.valueOf();
    tdt.setMonth(0, 1);
    if (tdt.getDay() !== 4) {
      tdt.setMonth(0, 1 + ((4 - tdt.getDay()) + 7) % 7);
    }
    return 1 + Math.ceil((firstThursday - tdt.valueOf()) / 604800000);
  }

  private makeDateString(date: Date): string {
    return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`
  }
}
