<h3 *ngIf="users?.length==0">In diesem Monat wurden noch keine Menüs bestellt</h3>
<table *ngIf="users?.length>0">
    <thead>
        <tr>
            <th>Benutzer</th>
            <th>Anzahl der bestellten Menüs in diesem Monat</th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let user of users">
            <td [matTooltip]="user.username">{{user.firstname}} {{user.lastname}}</td>
            <td>{{user.menu_count}}</td>
        </tr>
    </tbody>
    <tfoot>
        <tr>
            <td class="total">Gesamt</td>
            <td class="sum">{{total(users)}}</td>
        </tr>
    </tfoot>
</table>
<app-loading-animation [condition]="!users"></app-loading-animation>