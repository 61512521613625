import { Component, OnInit, Inject } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UserService } from '../../../services/user.service';
import { User } from '../../../model/user.model';
import { take } from 'rxjs/operators';
import { CreateUserPdfComponent } from '../create-user-pdf/create-user-pdf.component';

@Component({
  selector: 'app-reset-user-password',
  templateUrl: './reset-user-password.component.html',
  styleUrls: ['./reset-user-password.component.scss']
})
export class ResetUserPasswordComponent implements OnInit {

  resetPasswordForm: UntypedFormGroup = new UntypedFormGroup({
    'password': new UntypedFormControl('', [Validators.required, Validators.minLength(6)])
  });

  constructor(private dialogRef: MatDialogRef<ResetUserPasswordComponent>,
    private snackbar: MatSnackBar,
    private userService: UserService,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public user: User) { }

  ngOnInit(): void {
  }

  cancel() {
    this.dialogRef.close(false);
  }

  changePassword() {
    this.userService.changePassword(this.user.id, this.resetPasswordForm.get('password').value).pipe(take(1)).subscribe(() => {

      this.snackbar.open("Das Passwort wurde erfolgreich geändert", "Schließen", { duration: 5000 });
      this.dialog.open(CreateUserPdfComponent, {
        data: {
          "user": this.user,
          "password": this.resetPasswordForm.get('password').value,
          "company": this.user.company
        }
      });

      this.dialogRef.close(true);
    }, error => this.snackbar.open("Es ist ein Fehler aufgetreten", "Schließen", { duration: 10000, panelClass: ['warn-snackbar'] }))
  }

}
