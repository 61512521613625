import { Component, OnInit, Input } from '@angular/core';
import { PaginationInfo } from '../pagination-info.model';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent implements OnInit {

  @Input('paginationInfo')
  paginationInfo: PaginationInfo;

  constructor(private router: Router,
    private route: ActivatedRoute) { }

  ngOnInit(): void {
  }

  changePage(e) {
    let targetPage: number = e.target.value;
    if (targetPage > 0 && targetPage <= this.paginationInfo.pages)
      this.router.navigate(
        [],
        {
          relativeTo: this.route,
          queryParams: { 'page': targetPage },
          queryParamsHandling: 'merge'
        });
  }

  nextPage() {
    if (this.paginationInfo.currentPage < this.paginationInfo.pages)
      this.router.navigate(
        [],
        {
          relativeTo: this.route,
          queryParams: { 'page': this.paginationInfo.currentPage + 1 },
          queryParamsHandling: 'merge'
        });
  }

  lastPage() {
    if (this.paginationInfo.currentPage < this.paginationInfo.pages - 1)
      this.router.navigate(
        [],
        {
          relativeTo: this.route,
          queryParams: { 'page': this.paginationInfo.pages },
          queryParamsHandling: 'merge'
        });
  }

  previousPage() {
    if (this.paginationInfo.currentPage > 1)
      this.router.navigate(
        [],
        {
          relativeTo: this.route,
          queryParams: { 'page': this.paginationInfo.currentPage - 1 },
          queryParamsHandling: 'merge'
        });
  }

  firstPage() {
    if (this.paginationInfo.currentPage > 2)
      this.router.navigate(
        [],
        {
          relativeTo: this.route,
          queryParams: { 'page': 1 },
          queryParamsHandling: 'merge'
        });
  }



}
