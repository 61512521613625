import {Component, OnInit} from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {DishService} from '../../../services/dish.service';
import {UntypedFormGroup, UntypedFormControl, Validators} from '@angular/forms';
import {take} from 'rxjs/operators';
import {Dish} from 'src/app/model/dish.model';
import {environment} from '../../../../environments/environment';
import {AllergenService} from '../../../services/allergen.service';
import {Observable} from 'rxjs';
import {Allergen} from 'src/app/model/allergen.model';

@Component({
  selector: 'app-add-dish',
  templateUrl: './add-dish.component.html',
  styleUrls: ['./add-dish.component.scss']
})
export class AddDishComponent implements OnInit {

  addDishForm: UntypedFormGroup = new UntypedFormGroup({
    name: new UntypedFormControl('', [Validators.required]),
    price: new UntypedFormControl(8.50, [Validators.required, Validators.min(0)]),
    description: new UntypedFormControl(''),
    vegetarian: new UntypedFormControl(false, [Validators.required]),
    special: new UntypedFormControl(false, [Validators.required]),
    low_carb: new UntypedFormControl(false, [Validators.required])
  });

  image: any = null;
  allergensCbx: { checked: boolean, allergen: Allergen }[] = [];

  constructor(private dialogRef: MatDialogRef<AddDishComponent>,
              private snackbar: MatSnackBar,
              private dishService: DishService,
              private allergenService: AllergenService) {
  }

  ngOnInit(): void {
    this.allergenService.getAll().pipe(take(1)).subscribe((allergens: Allergen[]) => {
      allergens.forEach(a => this.allergensCbx.push({checked: false, allergen: a}));
    });
  }


  add() {
    var formData = new FormData()
    formData.append('name', this.addDishForm.get('name').value);
    formData.append('price', this.addDishForm.get('price').value);
    formData.append('description', this.addDishForm.get('description').value);
    formData.append('vegetarian', this.addDishForm.get('vegetarian').value);
    formData.append('special', this.addDishForm.get('special').value);
    formData.append('low_carb', this.addDishForm.get('low_carb').value);


    if (this.image)
      formData.append('image', this.image);
    var allergenIds = [];
    this.allergensCbx.forEach(a => a.checked ? allergenIds.push(a.allergen.id) : null);
    formData.append('allergens', JSON.stringify(allergenIds));
    this.dishService.create(formData).pipe(take(1)).subscribe((dish: Dish) => {
      this.snackbar.open("Das Gericht wurde erfolgreich erstellt", "Schließen", {duration: 5000});
      this.dishService.getAll();
      this.dialogRef.close(dish);
    }, (error) => { this.snackbar.open(
      error.error.error.error, "Schließen", {duration: 10000, panelClass: ['warn-snackbar']}
    );
      console.log(error);
  }
    );
  }

  imageUpload(event) {
    this.image = event.target.files[0];
  }


}
