import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Company } from '../../model/company.model';
import { CompanyService } from '../../services/company.service';
import { MatDialog } from '@angular/material/dialog';
import { AddCompanyComponent } from './add-company/add-company.component';
import { EditCompanyComponent } from './edit-company/edit-company.component';
import { ConfirmationDialogComponent } from 'src/app/utils/confirmation-dialog/confirmation-dialog.component';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-companies',
  templateUrl: './companies.component.html',
  styleUrls: ['./companies.component.scss']
})
export class CompaniesComponent implements OnInit {

  date: Date = new Date();
  startDateString: string;
  endDateString: string;
  companies: Observable<Company[]>;
  searchUser: string = "";

  constructor(private companyService: CompanyService,
    private dialog: MatDialog,
    private snackBar: MatSnackBar) { }

  ngOnInit(): void {
    this.setStartAndEndDateOfCurrentWeek();
    this.companies = this.companyService.companies;
    this.companyService.getAll();
  }


  add() {
    const dialogRef = this.dialog.open(AddCompanyComponent, {
      width: "600px"
    });
  }

  edit(company: Company) {
    const dialogRef = this.dialog.open(EditCompanyComponent, {
      width: "600px",
      data: company
    });
  }

  remove(company: Company) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: "350px",
      data: `Wollen Sie die Firma "${company.name}" und alle ihre Daten wirklich löschen?`
    });
    dialogRef.afterClosed().subscribe(result => {
      if (!result) return;

      this.companyService.delete(company.id).subscribe(() => {
        this.companyService.getAll();
        this.snackBar.open(`Die Firma ${company.name} wurde gelöscht`, "Schließen", { duration: 3000 });
      }, error =>
        this.snackBar.open("Beim Löschen ist ein Fehler aufgetreten", "Schließen", { duration: 10000, panelClass: ['warn-snackbar'] })
      );
    });
  }

  private setStartAndEndDateOfCurrentWeek() {
    var curr = new Date();
    var first = curr.getDate() - ((curr.getDay() == 0 ? 7 : curr.getDay()) - 1);
    var startDate = new Date(curr.setDate(first));
    this.startDateString = `${startDate.getFullYear()}-${startDate.getMonth() + 1}-${startDate.getDate()}`;

    var endDate = startDate;
    endDate.setDate(startDate.getDate() + 3);
    this.endDateString = `${endDate.getFullYear()}-${endDate.getMonth() + 1}-${endDate.getDate()}`;
  }

}
