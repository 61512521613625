import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';

@Component({
  selector: 'app-week-pagination-buttons',
  templateUrl: './week-pagination-buttons.component.html',
  styleUrls: ['./week-pagination-buttons.component.scss']
})
export class WeekPaginationButtonsComponent implements OnInit {

  startDate: Date;
  endDate: Date;
  startDatePreviousWeek: Date;
  endDatePreviousWeek: Date;
  startDateNextWeek: Date;
  endDateNextWeek: Date;

  constructor(private route: ActivatedRoute,
    private router: Router) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      if (moment(params['date'], "YYYY-M-D", true).isValid())
        this.startDate = moment(params['date'], "YYYY-M-D").toDate();
      this.endDate = moment(params['date'], "YYYY-M-D").toDate();
      this.endDate.setDate(this.endDate.getDate() + 3);

      this.startDatePreviousWeek = moment(params['date'], "YYYY-M-D").toDate();
      this.startDatePreviousWeek.setDate(this.startDatePreviousWeek.getDate() - 7);
      this.endDatePreviousWeek = moment(params['date'], "YYYY-M-D").toDate();
      this.endDatePreviousWeek.setDate(this.endDatePreviousWeek.getDate() - 4);

      this.startDateNextWeek = moment(params['date'], "YYYY-M-D").toDate();
      this.startDateNextWeek.setDate(this.startDateNextWeek.getDate() + 7);
      this.endDateNextWeek = moment(params['date'], "YYYY-M-D").toDate();
      this.endDateNextWeek.setDate(this.endDateNextWeek.getDate() + 11);
    });
  }

  getWeekNumber() {
    var tdt = new Date(this.startDate);
    var dayn = (this.startDate.getDay() + 6) % 7;
    tdt.setDate(tdt.getDate() - dayn + 3);
    var firstThursday = tdt.valueOf();
    tdt.setMonth(0, 1);
    if (tdt.getDay() !== 4) {
      tdt.setMonth(0, 1 + ((4 - tdt.getDay()) + 7) % 7);
    }
    return 1 + Math.ceil((firstThursday - tdt.valueOf()) / 604800000);
  }

  previousWeek() {
    this.router.navigate([], {
      queryParams: { 'date': this.makeDateString(this.startDatePreviousWeek)},
      queryParamsHandling: 'merge'
    });
  }

  nextWeek() {
    this.router.navigate([], {
      queryParams: { 'date': this.makeDateString(this.startDateNextWeek)},
      queryParamsHandling: 'merge'
    });
  }

  private makeDateString(date: Date): string {
    return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`
  }

}
