import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { CompanyService } from '../../../services/company.service';
import { CompanyTypeService } from '../../../services/company-type.service';
import { Company } from 'src/app/model/company.model';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable } from 'rxjs';
import { CompanyType } from 'src/app/model/company-type.model';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-add-company',
  templateUrl: './add-company.component.html',
  styleUrls: ['./add-company.component.scss']
})
export class AddCompanyComponent implements OnInit {

  domainRegex: RegExp = new RegExp('[a-z0-9äöü]+[a-z0-9äöü\-]*[a-z0-9äöü]+(\.[a-z0-9äöü]+[a-z0-9äöü\-]*[a-z0-9äöü]+)+');

  addCompanyForm = new UntypedFormGroup({
    name: new UntypedFormControl('', [Validators.required]),
    domain: new UntypedFormControl('', [Validators.required, Validators.pattern(this.domainRegex)]),
    coupon_discount: new UntypedFormControl(null, [Validators.min(0)]),
    uses_coupons: new UntypedFormControl(true, [Validators.required]),
    max_coupons_week: new UntypedFormControl(null, [Validators.pattern("^[0-9]*$"), Validators.min(1)]),
    needs_cutlery: new UntypedFormControl(true, [Validators.required]),
    company_type: new UntypedFormControl(null, [Validators.required])
  });

  company_types: CompanyType[];

  constructor(private dialogRef: MatDialogRef<AddCompanyComponent>,
    private companyService: CompanyService,
    private companyTypeService: CompanyTypeService,
    private snackBar: MatSnackBar) { }

  ngOnInit(): void {
    // set company_type to initial value
    this.companyTypeService.getAll().pipe(take(1)).subscribe(cts => {
      this.company_types = cts;
      this.addCompanyForm.get('company_type').setValue(this.company_types[0]);
    });
  }

  cancel() {
    this.dialogRef.close();
  }

  add() {

    if (!this.addCompanyForm.get('uses_coupons').value) {
      this.addCompanyForm.get("coupon_discount").setValue(null);
      this.addCompanyForm.get("max_coupons_week").setValue(null);
    }


    this.companyService.create(
      this.addCompanyForm.get("name").value,
      this.addCompanyForm.get("domain").value,
      this.addCompanyForm.get('uses_coupons').value,
      this.addCompanyForm.get('company_type').value.id,
      this.addCompanyForm.get('needs_cutlery').value,
      this.addCompanyForm.get("coupon_discount").value,
      this.addCompanyForm.get("max_coupons_week").value
    ).subscribe((company: Company) => {
      this.companyService.getAll();
      this.snackBar.open(`Die Firma ${company.name} wurde erstellt`, "Schließen", { duration: 3000 });
      this.dialogRef.close();
    }, error => {
      console.log(error);
      this.snackBar.open(error.error.error, "Schließen", { duration: 10000, panelClass: ['warn-snackbar'] })
    });
  }

}
