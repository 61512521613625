import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-month-pagination',
  templateUrl: './month-pagination.component.html',
  styleUrls: ['./month-pagination.component.scss']
})
export class MonthPaginationComponent implements OnInit {

  startDate: Date;
  endDate: Date;
  previousMonth: Date;
  nextMonth: Date;

  constructor(private route: ActivatedRoute,
    private router: Router) { }

  ngOnInit(): void {

    this.route.queryParams.subscribe(params => {
      let currentDate: Date = new Date;
      let month: number = +(params['month'] ?? currentDate.getMonth());
      let year: number = +(params['year'] ?? currentDate.getFullYear());

      this.startDate = new Date(year, month, 1);
      this.endDate = new Date(year, month + 1, 0);
      this.previousMonth = new Date(year, month - 1, 1);
      this.nextMonth = new Date(year, month + 1, 1);
    });
  }


  gotoPreviousMonth() {
    this.router.navigate([], {
      queryParams: { 'month': this.previousMonth.getMonth(), 'year': this.previousMonth.getFullYear() },
      queryParamsHandling: 'merge'
    });
  }

  gotoNextMonth() {
    this.router.navigate([], {
      queryParams: { 'month': this.nextMonth.getMonth(), 'year': this.nextMonth.getFullYear() },
      queryParamsHandling: 'merge'
    });
  }

}
