<h1 mat-dialog-title>Firma erstellen</h1>

<div mat-dialog-content>
  <form [formGroup]="addCompanyForm">

    <mat-form-field>
      <mat-label>Firmenname</mat-label>
      <input matInput type="text" formControlName="name">
      <mat-error *ngIf="addCompanyForm.get('name').hasError('required')">Ein Firmenname ist erforderlich</mat-error>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Domäne</mat-label>
      <input type="text" matInput formControlName="domain">
      <mat-hint>Die Domäne der Firma (z.B. itkaufmann.at)</mat-hint>
      <mat-error *ngIf="addCompanyForm.get('domain').hasError('required')">Die Domäne ist erforderlich</mat-error>
      <mat-error *ngIf="addCompanyForm.get('domain').hasError('pattern')">Die Domäne entspricht nicht dem gegebenen
        Muster</mat-error>
    </mat-form-field>

    <mat-form-field *ngIf="company_types?.length>0">
      <mat-label>Lieferart</mat-label>
      <mat-select formControlName="company_type">
        <mat-option *ngFor="let ct of company_types" [value]="ct">
          {{ct.name}}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-checkbox formControlName="needs_cutlery">Firma benötigt Besteck</mat-checkbox>

    <mat-checkbox formControlName="uses_coupons">Firma nutzt Essenmarken</mat-checkbox>

    <mat-form-field *ngIf="addCompanyForm.get('uses_coupons').value">
      <mat-label>Wert einer Essensmarke</mat-label>
      <input type="number" matInput formControlName="coupon_discount">
      <mat-hint>Wert in € angeben</mat-hint>
      <mat-error *ngIf="addCompanyForm.get('coupon_discount').hasError('min')">Der Wert darf nicht weniger als 0 sein
      </mat-error>
    </mat-form-field>

    <mat-form-field *ngIf="addCompanyForm.get('uses_coupons').value">
      <mat-label>Essensmarken pro Woche</mat-label>
      <input type="number" matInput formControlName="max_coupons_week">
      <mat-hint>Maximale Menge an Essensmarken die in einer Woche verwendet werden dürfen (leer lassen wenn Menge
        unbegrenzt ist)</mat-hint>
      <mat-error *ngIf="addCompanyForm.get('max_coupons_week').hasError('min')">Der Wert darf nicht weniger als 1 sein
      </mat-error>
      <mat-error *ngIf="addCompanyForm.get('max_coupons_week').hasError('pattern')">Wert muss ganzzahlig sein
      </mat-error>
    </mat-form-field>
  </form>
</div>

<div mat-dialog-actions>

  <button mat-button (click)="cancel()">Abbrechen</button>

  <button mat-button (click)="add()"
    [disabled]="addCompanyForm.invalid || (!addCompanyForm.get('coupon_discount').value&&addCompanyForm.get('uses_coupons').value)"
    cdkFocusInitial>Erstellen</button>

</div>
