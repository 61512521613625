import { Pipe, PipeTransform } from '@angular/core';
import { WeeklyUsersOrderStatsElement } from './weekly-users-order-stats-element.model';

@Pipe({
  name: 'sortWeeklyUsersOrderStats',
  pure: false
})
export class SortWeeklyUsersOrderStatsPipe implements PipeTransform {

  // sort WeeklyUsersOrderStatsElements by username
  transform(wuosElements: WeeklyUsersOrderStatsElement[]): WeeklyUsersOrderStatsElement[] {
    return wuosElements.sort((a, b) => a.user.username < b.user.username ? -1 : (a.user.username > b.user.username ? 1 : 0));
  }

}
