import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DishService } from '../../../services/dish.service';
import { MenuService } from '../../../services/menu.service';
import { Observable } from 'rxjs';
import { Dish } from 'src/app/model/dish.model';
import { environment } from 'src/environments/environment';
import { AddDishComponent } from '../../dishes/add-dish/add-dish.component';
import { UntypedFormControl, FormGroup, Validators } from '@angular/forms';
import { take } from 'rxjs/operators';
import { Menu } from 'src/app/model/menu.model';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-add-menu',
  templateUrl: './add-menu.component.html',
  styleUrls: ['./add-menu.component.scss']
})
export class AddMenuComponent implements OnInit {

  availableDateFormControl: UntypedFormControl = new UntypedFormControl('', [Validators.required])

  dishes: Observable<Dish[]>;
  url: string = environment.url;
  selectedDishes: number[] = [];
  searchDish: string = "";
  savedDishes: number[] = [];

  constructor(private dialogRef: MatDialogRef<AddMenuComponent>,
    private snackbar: MatSnackBar,
    private dishService: DishService,
    private menuService: MenuService,
    private dialog: MatDialog,
    private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.dishes = this.dishService.dishes;
    this.dishService.getAll();
  }


  add() {
    var available_date: Date = new Date(this.availableDateFormControl.value);
    var datestring: string = available_date.getFullYear() + '-' + (available_date.getMonth() + 1) + '-' + available_date.getDate();
    this.menuService.createMany(datestring, this.selectedDishes).pipe(take(1)).subscribe(() => {
      this.menuService.getAll(this.route.snapshot.queryParams['from'], this.route.snapshot.queryParams['to']);
      this.snackbar.open("Das Menü wurde hinzugefügt", "Schließen", { duration: 5000 });
      this.dialogRef.close(true);
    }, error => this.snackbar.open(error.error.error, "Schließen", { duration: 10000, panelClass: ['warn-snackbar'] }));
  }

  addDish() {
    var dialogRef = this.dialog.open(AddDishComponent, { width: "600px" });

    dialogRef.afterClosed().subscribe((dish: Dish) => {
      if (dish)
        this.selectedDishes.push(dish.id);
    })
  }

  dayFilter = (d: Date | null): boolean => {
    const day = (d || new Date()).getDay();
    return day !== 0 && day !== 6 && day !== 5;
  }

}
