<h1 mat-dialog-title>Stornierungsanfrage</h1>

<div mat-dialog-content>

  <p>
    {{order.cancelation.message}}
  </p>

</div>

<div mat-dialog-actions>

  <button mat-button (click)="close()">Abbrechen</button>
  <button mat-stroked-button (click)="cancel()" cdkFocusInitial>Stornierung zulassen</button>
  <button mat-stroked-button (click)="reject()" color="warn">Stornierung ablehnen</button>

</div>
