import {Pipe, PipeTransform} from '@angular/core';
import {GroupedCompanyOrderStatsTableElement} from './grouped-company-order-stats-table-element.model';

@Pipe({
  name: 'sortGcostElementsByCompanyName',
  pure: false
})
export class SortGcostElementsByCompanyNamePipe implements PipeTransform {

  transform(gcostElements: GroupedCompanyOrderStatsTableElement[]): GroupedCompanyOrderStatsTableElement[] {
    return gcostElements.sort((a, b) => a.company.name < b.company.name ? -1 : (a.company.name > b.company.name ? 1 : 0));
  }

}
