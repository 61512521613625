import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpHeaders,
  HttpErrorResponse
} from '@angular/common/http';
import {Observable, of, throwError} from 'rxjs';
import { UserService } from '../services/user.service';
import {take, exhaustMap, catchError} from 'rxjs/operators';
import {Router} from "@angular/router";

@Injectable({
  providedIn: 'root'
})
export class AuthInterceptorService implements HttpInterceptor {

  constructor(private userService:UserService,
              private router: Router) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.userService.user.value) {
      const modifiedRequest = req.clone({headers: new HttpHeaders().append("Authorization", "Bearer " + this.userService.user.value.token)});
      return next.handle(modifiedRequest).pipe(
        catchError((error: HttpErrorResponse) => {
          return this.handleAuthError(error);
        }));
    }

    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {
        return this.handleAuthError(error);
      }));
  }

  private handleAuthError(err: HttpErrorResponse): Observable<any> {
    if ((err.status === 401) && this.router.url !== "/login") {
      this.userService.user.next(null)
      sessionStorage.clear()
      localStorage.clear()
      window.location.href = ''
      return of(err.message)
    }
    return throwError(err);
  }

}
