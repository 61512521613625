<mat-card class="body-wrapper" *ngIf="order">

  <mat-card-content>

    <app-return-button [path]="'/companies/'+order.user.company_id+'/orders'"
                       [text]="'Bestellungen von '+order.user.company.name" [queryParams]="{page:page}"></app-return-button>
    <h1>Bestellung <strong>#{{order.id}}</strong></h1>
    <table>
      <tbody>
      <tr>
        <td><strong>Benutzer</strong></td>
        <td class="detail">
          <!-- <span class="link"> -->
          {{order.user.firstname}} {{order.user.lastname}}
          <!-- </span> -->
        </td>
      </tr>
      <tr>
        <td><strong>Firma</strong></td>
        <td class="detail"><span class="link"
                                 [routerLink]="['/companies',order.user.company_id]">{{order.user.company.name}}</span></td>
      </tr>
      <tr>
        <td><strong>Bestelldatum</strong></td>
        <td class="detail">{{order.order_date|date:"dd.MM.yyyy"}}</td>
      </tr>
      <tr *ngIf="order.user.company?.uses_coupons">
        <td><strong>Rabattmarke Wert</strong></td>
        <td class="detail">€ {{order.coupon_discount}}</td>
      </tr>
      <tr *ngIf="order.user.company?.uses_coupons">
        <td><strong>Rabattmarken Anzahl</strong></td>
        <td class="detail">{{coupon_count}}</td>
      </tr>
      <tr *ngIf="order.user.company?.uses_coupons">
        <td><strong>Gesamtsumme</strong></td>
        <td class="detail">€ {{sum|number}}</td>
      </tr>
      <tr *ngIf="order.user.company?.uses_coupons">
        <td><strong>Rabatt</strong></td>
        <td class="detail">€ {{discount|number}}</td>
      </tr>
      <tr>
        <td><strong>zu zahlender Betrag</strong></td>
        <td class="detail">€ {{(sum-discount<0?0:sum-discount)|number}} </td>
      </tr>
      </tbody>
    </table>
    <h2>Bestellte Menüs</h2>
    <app-order-menus-table [order_menus]="order.order_menus" [uses_coupons]="order.user.company.uses_coupons">
    </app-order-menus-table>

  </mat-card-content>

</mat-card>
<app-loading-animation [condition]="!order"></app-loading-animation>
