import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { take } from 'rxjs/operators';
import { User } from '../../../model/user.model';
import { UserService } from '../../../services/user.service';

@Component({
  selector: 'app-users-order-monthly-stats',
  templateUrl: './users-order-monthly-stats.component.html',
  styleUrls: ['./users-order-monthly-stats.component.scss']
})
export class UsersOrderMonthlyStatsComponent implements OnInit {


  startDate: Date;
  endDate: Date;
  users: User[];

  constructor(private route:ActivatedRoute,
    private userService:UserService) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.users=null;
      let currentDate: Date = new Date;
      let month: number = +(params['month'] ?? currentDate.getMonth());
      let year: number = +(params['year'] ?? currentDate.getFullYear());

      this.startDate = new Date(year, month, 1);
      this.endDate = new Date(year, month + 1, 0);

      this.userService.getUsersByCompanyWithMenuCount(+this.route.parent.snapshot.paramMap.get('company_id'), this.makeDateString(this.startDate), this.makeDateString(this.endDate)).pipe(take(1)).subscribe((users: User[]) => {
        this.users = users;
      });
    });
  }

  total(users:User[]):number{
    return users.reduce((a,b)=>a+(b.menu_count||0),0);
  }

  private makeDateString(date: Date): string {
    return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`
  }

}
