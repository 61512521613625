<div class="content-body">
  <div class="spacing"></div>
  <form class="login-wrapper" [formGroup]="loginForm" (ngSubmit)="onLogin()">
    <mat-card-title>Login</mat-card-title>
    <mat-form-field appearance="outline">
      <mat-label>Domäne</mat-label>
      <input matInput type="text" formControlName="domain">
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>Benutzername</mat-label>
      <input matInput type="text" formControlName="username">
      <mat-error *ngIf="loginForm.get('username').errors">Benutzername ist erforderlich</mat-error>
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>Passwort</mat-label>
      <input type="password" matInput formControlName="password">
      <mat-error *ngIf="loginForm.get('password').errors">Passwort ist erforderlich</mat-error>
    </mat-form-field>
    <button class="login-button" [disabled]="loginForm.invalid ||loading" [class.spinner]="loading" mat-raised-button color="primary"
      type="submit">Login</button>
  </form>
  <div class="spacing"></div>
</div>