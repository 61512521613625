import {Component, OnInit} from '@angular/core';
import {PaginationInfo} from '../../utils/pagination-info.model';
import {Observable} from 'rxjs';
import {OrderService} from '../../services/order.service';
import {ActivatedRoute, Router} from '@angular/router';
import {take} from 'rxjs/operators';
import {Order} from 'src/app/model/order.model';
import {MatDialog} from '@angular/material/dialog';
import {AddOrderComponent} from './add-order/add-order.component';

@Component({
  selector: 'app-orders',
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.scss']
})
export class OrdersComponent implements OnInit {

  paginationInfo: PaginationInfo;
  orders: Observable<Order[]>;
  ordersWithOpenCancelation: Order[];
  filter: string;
  searchOrderId: number;

  page: number = null;

  constructor(private orderService: OrderService,
              private route: ActivatedRoute,
              private router: Router,
              private dialog: MatDialog) {
  }

  ngOnInit(): void {
    this.orders = this.orderService.orders;
    this.orderService.paginationInfo.subscribe(pi => this.paginationInfo = pi);
    this.route.queryParams.subscribe(params => {
      this.page = params.page;
      this.filter = params.filter ?? 'none';
      this.searchOrderId = +params.order_id ?? null;
      this.orderService.getAll(+(params.page ?? 1), 10, this.filter, this.searchOrderId);
    });

    this.orderService.getWithOpenCancelation().pipe(take(1)).subscribe((orders: Order[]) => this.ordersWithOpenCancelation = orders);
  }

  add(): void {
    this.dialog.open(AddOrderComponent, {
      // width: "600px"
    });
  }

  changeFilter(): void {
    this.searchOrderId = null;

    this.router.navigate([], {
      queryParams: {page: 1, filter: this.filter, order_id: this.searchOrderId},
      queryParamsHandling: 'merge'
    });
  }

  resetSearch() {
    this.searchOrderId = null;

    this.search();
  }

  search(): void {
    this.filter = 'none';

    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {page: 1, filter: this.filter, order_id: this.searchOrderId},
      queryParamsHandling: 'merge'
    });
  }

}
