import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable, BehaviorSubject} from 'rxjs';
import {Company} from '../model/company.model';
import {environment} from 'src/environments/environment';
import {take} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CompanyService {

  private _companies: BehaviorSubject<Company[]>;

  get companies(): Observable<Company[]> {
    return this._companies.asObservable();
  }

  constructor(private http: HttpClient) {
    this._companies = new BehaviorSubject<Company[]>([]);
  }

  public getOne(company_id: number, withUsers: boolean = false): Observable<Company> {
    return this.http.get<Company>(environment.apiURL + `companies/${company_id}?withUsers=${withUsers}`);
  }

  public getAll(): void {
    this.http.get<Company[]>(environment.apiURL + 'companies').pipe(take(1)).subscribe(
      (companies: Company[]) => this._companies.next(companies)
    );
  }

  public getAllWithMenus(from: string, to: string): Observable<any> {
    return this.http.get<any>(environment.apiURL + `companies/menus?from=${from}&to=${to}`);
  }

  public getByCompanyTypeWithMenus(from: string, to: string, company_type_id: number): Observable<Company[]> {
    return this.http.get<Company[]>(environment.apiURL + `companiesWithMenus?from=${from}&to=${to}&company_type_id=${company_type_id}`);
  }

  public create(name: string,
                domain: string,
                uses_coupons: boolean,
                company_type_id: number,
                needs_cutlery: boolean,
                coupon_discount: number = null,
                max_coupons_week: number = null): Observable<Company> {
    return this.http.post<Company>(environment.apiURL + 'companies', {
      name,
      domain,
      uses_coupons,
      coupon_discount,
      max_coupons_week,
      needs_cutlery,
      company_type_id
    });
  }

  public update(company_id: number,
                name: string, domain: string,
                uses_coupons: boolean,
                company_type_id: number,
                needs_cutlery: boolean,
                coupon_discount: number = null,
                max_coupons_week: number = null): Observable<Company> {
    return this.http.put<Company>(environment.apiURL + 'companies/' + company_id, {
      name,
      domain,
      uses_coupons,
      coupon_discount,
      max_coupons_week,
      needs_cutlery,
      company_type_id
    });
  }

  public delete(company_id: number): Observable<any> {
    return this.http.delete(environment.apiURL + 'companies/' + company_id);
  }
}
