import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Allergen } from '../model/allergen.model';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AllergenService {


  constructor(private http:HttpClient) { }

  getAll():Observable<Allergen[]>{
    return this.http.get<Allergen[]>(environment.apiURL+"allergens");
  }
}
