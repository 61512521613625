import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MessageService } from '../../services/message.service';
import { AddMessageComponent } from './add-message/add-message.component';
import { MatTabGroup } from '@angular/material/tabs';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-messages',
  templateUrl: './messages.component.html',
  styleUrls: ['./messages.component.scss']
})
export class MessagesComponent implements OnInit {

  for: string = "user";

  constructor(private dialog: MatDialog,
    private messageService: MessageService,
    private route: ActivatedRoute,
    private router: Router) { }


  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.for = params['for'] ?? 'user'
      this.messageService.getAll(+(params['page'] ?? 1),this.for);
    });
  }


  add() {
    var dialogRef = this.dialog.open(AddMessageComponent)
  }

  changeRoute() {
    this.router.navigate(['/messages'], { queryParams: { for: this.for } })
  }

}
