<h1 mat-dialog-title>Benutzer bearbeiten</h1>

<div mat-dialog-content>

  <form [formGroup]="editUserForm">

    <mat-form-field>
      <mat-label>Vorname</mat-label>
      <input matInput type="text" formControlName="firstname">
      <mat-error *ngIf="editUserForm.get('firstname').hasError('required')">Ein Vorname ist erforderlich</mat-error>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Nachname</mat-label>
      <input matInput type="text" formControlName="lastname">
      <mat-error *ngIf="editUserForm.get('lastname').hasError('required')">Ein Nachname ist erforderlich</mat-error>
    </mat-form-field>

    <!-- the following two inputs are only shown to Admins and those properites can only be altered by admins at the moment -->

    <mat-form-field *ngIf="currentUser.role.toString()=='ADMIN'">
      <mat-label>Benutzer-Art</mat-label>
      <mat-select formControlName="role">
        <mat-option value="NORMAL">Normal</mat-option>
        <mat-option value="COMPANY">Firmenadmin</mat-option>
        <mat-option value="ADMIN">Administrator</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field *ngIf="currentUser.role.toString()=='ADMIN'">
      <mat-label>Firma</mat-label>
      <input type="text" aria-label="Number" matInput formControlName="company" [matAutocomplete]="auto">
      <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
        <mat-option *ngFor="let company of companyOptions | async" [value]="company">
          {{company.name}}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>

  </form>

</div>
<div mat-dialog-actions>

  <button mat-button (click)="cancel()">Abbrechen</button>

  <button mat-stroked-button (click)="edit()"
    [disabled]="editUserForm.invalid
  || (editUserForm.get('role').value!='ADMIN'&&editUserForm.get('company').value=='')
  || !(editUserForm.get('company').value==''||editUserForm.get('company').value==null||isObject(editUserForm.get('company').value))"
    cdkFocusInitial>Ändern</button>

</div>
