import { Component, OnInit, Input } from '@angular/core';
import { Order } from 'src/app/model/order.model';
import { User } from 'src/app/model/user.model';
import { Router, ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { CancelOrderComponent } from '../cancel-order/cancel-order.component';
import { ShowCancelRequestOrderComponent } from '../show-cancel-request-order/show-cancel-request-order.component';

@Component({
  selector: 'app-order-item',
  templateUrl: './order-item.component.html',
  styleUrls: ['./order-item.component.scss']
})
export class OrderItemComponent implements OnInit {

  @Input('order')
  order: Order;

  @Input('user')
  user: User;

  sum: number = 0;
  discount: number = 0;

  constructor(private router: Router,
    private route: ActivatedRoute,
    private dialog: MatDialog) { }

  ngOnInit(): void {
    this.order.order_menus.forEach(om => {
      this.sum += +om.menu.price;
      if (om.has_coupon)
        this.discount += +this.order.coupon_discount;
    });
  }


  openOrderDetails() {
    this.router.navigate(
      [this.order.id],
      {relativeTo: this.route, queryParams: {'page': this.route.snapshot.queryParamMap.get('page')}}
    )

    this.router.navigate(['/orders', this.order.id], {state: {page: this.route.snapshot.queryParams['page']}});
  }

  openCancelationDialog() {
    this.dialog.open(CancelOrderComponent, {
      width: "600px",
      data: this.order
    })
  }

  openShowCancelationDialog() {
    this.dialog.open(ShowCancelRequestOrderComponent, {
      width: "600px",
      data: this.order
    })
  }

}
