<mat-toolbar color="primary">
    <mat-toolbar-row>
        <!-- <mat-icon routerLink="/" class="inventory-icon" aria-hidden="false" aria-label="Example user verified icon">
            restaurant</mat-icon> -->
            <img src="/assets/logo.png" routerLink="/" alt="image">
        <span routerLink="/" class="navbar-text" *ngIf="windowWidth==null||windowWidth>560"><strong>Adminoberfläche Lunch@Work</strong></span>
        <div class="spacer"></div>
        <!--      <button mat-flat-button routerLink="/login" *ngIf="!isAuthenticated">Login</button>-->
        <button mat-flat-button [matMenuTriggerFor]="userMenu" *ngIf="isAuthenticated&&currentUser!=null"
            class="user-button">
            <mat-icon class="user-icon">person</mat-icon>{{currentUser?.username}}
        </button>
        <mat-menu #userMenu="matMenu">
            <button mat-menu-item *ngIf="currentUser?.role==1" [routerLink]="['/user-management']">
                <mat-icon>people</mat-icon>Benutzerverwaltung
            </button>
            <button mat-menu-item *ngIf="currentUser?.role==1" [routerLink]="['/group-management']">
                <mat-icon>dashboard</mat-icon>Gruppenverwaltung
            </button>
            <button mat-menu-item (click)="edit()">
                <mat-icon>edit</mat-icon>Bearbeiten
            </button>
            <button mat-menu-item (click)="changePassword()">
                <mat-icon>restore</mat-icon>Passwort ändern
            </button>
            <button mat-menu-item (click)="logout()">
                <mat-icon>exit_to_app</mat-icon>Logout
            </button>
        </mat-menu>

    </mat-toolbar-row>
</mat-toolbar>