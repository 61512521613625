import { Component, OnInit, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MenuDetailsComponent } from '../../../menus/menu-details/menu-details.component';
import { Menu } from '../../../../model/menu.model';
import { OrderMenu } from '../../../../model/order-menu.model';

@Component({
  selector: 'app-order-menus-table',
  templateUrl: './order-menus-table.component.html',
  styleUrls: ['./order-menus-table.component.scss']
})
export class OrderMenusTableComponent implements OnInit {

  @Input('order_menus')
  order_menus: OrderMenu[];

  @Input('uses_coupons')
  uses_coupons: boolean = true;

  displayedColumns: string[] = ['index', 'name', 'available_date', 'has_coupon', 'price', 'details'];

  constructor(private dialog: MatDialog) { }

  ngOnInit(): void {
    if (!this.uses_coupons)
      this.displayedColumns = ['index', 'name', 'available_date', 'price', 'details'];
  }

  openMenuDetails(menu: Menu) {
    this.dialog.open(MenuDetailsComponent, {
      data: menu
    });
  }

}
