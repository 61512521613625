<h1 mat-dialog-title>Benutzer-PDF herunterladen</h1>
<div mat-dialog-content>
    <table *ngIf="company && usersCbx?.length>0">
        <thead>
            <tr>
                <th></th>
                <th>Vorname</th>
                <th>Nachname</th>
                <th>Benutzername</th>
                <th>Passwort</th>
                <th>Domän</th>
                <th>Firmenname</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let userCbx of usersCbx">
                <td>
                    <mat-checkbox [id]="'user-'+userCbx.user.id+'-cbx'" [(ngModel)]="userCbx.checked"></mat-checkbox>
                </td>
                <td><label [for]="'user-'+userCbx.user.id+'-cbx-input'">{{userCbx.user.firstname}}</label></td>
                <td><label [for]="'user-'+userCbx.user.id+'-cbx-input'">{{userCbx.user.lastname}}</label></td>
                <td><label [for]="'user-'+userCbx.user.id+'-cbx-input'">{{userCbx.user.username}}</label></td>
                <td><label [for]="'user-'+userCbx.user.id+'-cbx-input'">{{userCbx.user.generated_password}}</label></td>
                <td><label [for]="'user-'+userCbx.user.id+'-cbx-input'">{{company?.domain}}</label></td>
                <td><label [for]="'user-'+userCbx.user.id+'-cbx-input'">{{company?.name}}</label></td>
            </tr>
        </tbody>
    </table>
    <app-loading-animation [condition]="!company || !(usersCbx?.length>0)"></app-loading-animation>
</div>
<div mat-dialog-actions>
    <button mat-button (click)="cancel()">Abbrechen</button>
    <button mat-button (click)="download()" cdkFocusInitial>
        <mat-icon>get_app</mat-icon>Download PDF
    </button>
</div>