<h1 mat-dialog-title>Bestellung erstellen</h1>

<div mat-dialog-content>

  <form [formGroup]="addOrderForm">

    <mat-form-field>
      <mat-label>Benutzer</mat-label>
      <input type="text" aria-label="Number" matInput formControlName="user" [matAutocomplete]="autoUser">
      <mat-autocomplete #autoUser="matAutocomplete" [displayWith]="displayFnUser">
        <mat-option *ngFor="let user of userOptions | async " [value]="user.user">
          {{user.name}}
        </mat-option>
      </mat-autocomplete>
      <mat-error *ngIf="addOrderForm.get('user').hasError('required')">Ein Nutzer ist erforderlich</mat-error>
    </mat-form-field>

  </form>

  <div class="week-pagination-wrapper" *ngIf="startDate && endDate">
    <mat-icon matTooltip="Vorherige Woche" (click)="previousWeek()">chevron_left</mat-icon>
    <span>{{startDate|date:'dd. MM. yyyy'}} – {{endDate|date:'dd. MM. yyyy'}}</span>
    <mat-icon matTooltip="Nächste Woche" (click)="nextWeek()">chevron_right</mat-icon>
  </div>

  <div *ngIf="menusByDate">
    <mat-card class="menus-group" *ngFor="let md of menusByDate | sortMenusByDate"
      [ngClass]="{'display-none':md.menus.length<0}">

      <mat-card-content>

        <h4 class="menu-title-span"><strong>{{md.date | date:'dd. MMMM y'}}</strong></h4>

        <mat-list>

          <mat-list-item *ngFor="let menu of md.menus" class="menu-list-item">

            <div class="content">

              <img [src]="menu.image_url?url+menu.image_url:null">

              <div class="list-data-wrapper">
                <div class="main-text">
                  {{menu.name|shorten}}<mat-icon class="vegetarian-icon" *ngIf="menu.vegetarian">eco
                </mat-icon>
                </div>

                <div class="secondary-text">
                  Bestellungen: {{menu.order_count}}
                </div>
              </div>

              <div class="spacer"></div>

              <mat-icon matTooltip="Details" (click)="openMenuDetails(menu)">info</mat-icon>

              <button mat-stroked-button color="accent" matTooltip="Zur Bestellung hinzufügen" (click)="addToOrder(menu)">
                <mat-icon>add</mat-icon>
              </button>

              <mat-divider></mat-divider>

            </div>

          </mat-list-item>

        </mat-list>

      </mat-card-content>

    </mat-card>
  </div>

  <h2>Menüs in der Bestellung: {{orderMenus.length}}</h2>

  <mat-list class="order-menu-list" *ngIf="orderMenus.length>0">

    <mat-list-item *ngFor="let om of orderMenus; let i = index" class="menu-list-item">

      <div class="content">

        {{om.menu.name|shorten}}

        <div class="spacer"></div>

        <mat-checkbox *ngIf="om" [(ngModel)]="om.has_coupon">Essenmarke?</mat-checkbox>

        <button mat-stroked-button color="accent" matTooltip="Aus der Bestellung entfernen" (click)="removeFromOrder(i)">
          <mat-icon>remove</mat-icon>
        </button>

        <mat-divider></mat-divider>

      </div>



    </mat-list-item>

  </mat-list>

</div>

<div mat-dialog-actions>

  <button mat-button [mat-dialog-close]="false">Abbrechen</button>
  <button mat-stroked-button (click)="add()"
    [disabled]="addOrderForm.invalid||!isObject(addOrderForm.get('user').value)||orderMenus.length<1"
    cdkFocusInitial>Erstellen</button>

</div>
