import { BrowserModule } from '@angular/platform-browser';
import { LOCALE_ID, NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatToolbarModule } from '@angular/material/toolbar';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { LoginComponent } from './components/login/login.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NavbarComponent } from './components/navbar/navbar.component';
import { DateAdapter, MatRippleModule } from '@angular/material/core';
import { MatListModule } from '@angular/material/list';
import { AuthInterceptorService } from './utils/auth-interceptor.service';
import { AdminDashboardComponent } from './components/admin-dashboard/admin-dashboard.component';
import { CompanyDashboardComponent } from './components/companies/company-dashboard/company-dashboard.component';
import { CompaniesComponent } from './components/companies/companies.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { AddCompanyComponent } from './components/companies/add-company/add-company.component';
import { MatDialogModule } from '@angular/material/dialog';
import { EditCompanyComponent } from './components/companies/edit-company/edit-company.component';
import { ConfirmationDialogComponent } from './utils/confirmation-dialog/confirmation-dialog.component';
import { UsersComponent } from './components/users/users.component';
import { AddUserComponent } from './components/users/add-user/add-user.component';
import { EditUserComponent } from './components/users/edit-user/edit-user.component';
import { MatSelectModule } from '@angular/material/select';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { CreateUserPdfComponent } from './components/users/create-user-pdf/create-user-pdf.component';
import { ActivateUserComponent } from './components/activate-user/activate-user.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { ResetUserPasswordComponent } from './components/users/reset-user-password/reset-user-password.component';
import { DishesComponent } from './components/dishes/dishes.component';
import { AddDishComponent } from './components/dishes/add-dish/add-dish.component';
import { EditDishComponent } from './components/dishes/edit-dish/edit-dish.component';
import { MenusComponent } from './components/menus/menus.component';
import { AddMenuFastComponent } from './components/menus/add-menu-fast/add-menu-fast.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { ReturnButtonComponent } from './utils/return-button/return-button.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { SortMenusByDatePipe } from './components/menus/sort-menus-by-date.pipe';
import { OrdersComponent } from './components/orders/orders.component';
import { OrderItemComponent } from './components/orders/order-item/order-item.component';
import { MatPaginatorModule } from '@angular/material/paginator';
import { PaginationComponent } from './utils/pagination/pagination.component';
import { OrderComponent } from './components/orders/order/order.component';
import { MatTableModule } from '@angular/material/table';
import { MenuDetailsComponent } from './components/menus/menu-details/menu-details.component';
import { OrderMenusTableComponent } from './components/orders/order/order-menus-table/order-menus-table.component';
import { CancelOrderComponent } from './components/orders/cancel-order/cancel-order.component';
import { CompanyUsersComponent } from './components/companies/company-dashboard/company-users/company-users.component';
import { CompanyOrdersComponent } from './components/companies/company-dashboard/company-orders/company-orders.component';
import { CompanyOrderItemComponent } from './components/companies/company-dashboard/company-orders/company-order-item/company-order-item.component';
import { CompanyOrderComponent } from './components/companies/company-dashboard/company-orders/company-order/company-order.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { LoadingAnimationComponent } from './utils/loading-animation.component';
import { AddCompanyUserComponent } from './components/companies/company-dashboard/company-users/add-company-user/add-company-user.component';
import { CreateUsersPdfComponent } from './components/companies/company-dashboard/company-users/create-users-pdf/create-users-pdf.component';
import { MessagesComponent } from './components/messages/messages.component';
import { AddMessageComponent } from './components/messages/add-message/add-message.component';
import { MatTabsModule } from '@angular/material/tabs';
import { MatRadioModule } from '@angular/material/radio';
import { NgxMatDatetimePickerModule, NgxMatTimepickerModule, NgxMatDateFormats, NGX_MAT_DATE_FORMATS } from '@angular-material-components/datetime-picker';
import { NgxMatMomentModule } from '@angular-material-components/moment-adapter';
import { FilterUserWithoutAppPipe } from './components/messages/add-message/filter-user-without-app.pipe';
import { ShowCancelRequestOrderComponent } from './components/orders/show-cancel-request-order/show-cancel-request-order.component';
import { HideWithOpenCancelationPipe } from './components/orders/hide-with-open-cancelation.pipe';
import { MatBadgeModule } from '@angular/material/badge';
import { UserMessagesComponent } from './components/messages/user-messages/user-messages.component';
import { CompanyMessagesComponent } from './components/messages/company-messages/company-messages.component';
import { AllMessagesComponent } from './components/messages/all-messages/all-messages.component';
import { MessageItemComponent } from './components/messages/message-item/message-item.component';
import { ShortenPipe } from './utils/shorten.pipe';
import { MessageInfoComponent } from './components/messages/message-info/message-info.component';
import { AddMenuComponent } from './components/menus/add-menu/add-menu.component';
import { SearchDishesPipe } from './components/menus/add-menu/search-dishes.pipe';
import { registerLocaleData } from '@angular/common';
import localeDeAT from '@angular/common/locales/de-AT';
import { DateTimeFormatPipe } from './utils/date-time-format-filter.pipe';
import { WeekPaginationComponent } from './components/menus/week-pagination/week-pagination.component';
import { OrderStatsComponent } from './components/order-stats/order-stats.component';
import { WeekPaginationButtonsComponent } from './utils/week-pagination-buttons/week-pagination-buttons.component';
import { SortMenusPipe } from './components/order-stats/order-stats-less-details/sort-pipes/sort-menus.pipe';
import { SortGroupedMenusPipe } from './components/order-stats/order-stats-less-details/sort-pipes/sort-grouped-menus.pipe';
import { SortMenusSumPipe } from './components/order-stats/order-stats-less-details/sort-pipes/sort-menus-sum.pipe';
import { SortMenusSumByDatePipe } from './components/order-stats/order-stats-less-details/sort-pipes/sort-menus-sum-by-date.pipe';
import { CustomDateAdapter } from './utils/custom-date-adapter';
import { CouponViewComponent } from './components/coupon-view/coupon-view.component';
import { UsersOrderStatsComponent } from './components/users-order-stats/users-order-stats.component';
import { MonthPaginationComponent } from './components/users-order-stats/month-pagination/month-pagination.component';
import { SearchCompanyPipe } from './components/companies/search-company.pipe';
import { AddOrderComponent } from './components/orders/add-order/add-order.component';
import { UsersOrderMonthlyStatsComponent } from './components/users-order-stats/users-order-monthly-stats/users-order-monthly-stats.component';
import { UsersOrderDailyStatsComponent } from './components/users-order-stats/users-order-daily-stats/users-order-daily-stats.component';
import { SearchDishPipe } from './components/dishes/search-dish.pipe';
import { WeeklyUsersOrderStatsComponent } from './components/weekly-users-order-stats/weekly-users-order-stats.component';
import { SortWeeklyUsersOrderStatsPipe } from './components/weekly-users-order-stats/sort-weekly-users-order-stats.pipe';
import { OrderStatsLessDetailsComponent } from './components/order-stats/order-stats-less-details/order-stats-less-details.component';
import { OrderStatsMoreDetailsComponent } from './components/order-stats/order-stats-more-details/order-stats-more-details.component';
import { GroupedCompanyOrderStatsTableComponent } from './components/order-stats/order-stats-more-details/grouped-company-order-stats-table/grouped-company-order-stats-table.component';
import { SortCompaniesByNamePipe } from './components/order-stats/order-stats-less-details/sort-pipes/sort-companies-by-name.pipe';
import { SortGcostElementsByCompanyNamePipe } from './components/order-stats/order-stats-more-details/grouped-company-order-stats-table/sort-gcost-elements-by-company-name.pipe';


registerLocaleData(localeDeAT);


@NgModule({
    declarations: [
        AppComponent,
        LoginComponent,
        NavbarComponent,
        AdminDashboardComponent,
        CompanyDashboardComponent,
        CompaniesComponent,
        AddCompanyComponent,
        EditCompanyComponent,
        ConfirmationDialogComponent,
        UsersComponent,
        AddUserComponent,
        EditUserComponent,
        CreateUserPdfComponent,
        ActivateUserComponent,
        ResetUserPasswordComponent,
        DishesComponent,
        AddDishComponent,
        EditDishComponent,
        MenusComponent,
        AddMenuFastComponent,
        ReturnButtonComponent,
        SortMenusByDatePipe,
        OrdersComponent,
        OrderItemComponent,
        PaginationComponent,
        OrderComponent,
        MenuDetailsComponent,
        OrderMenusTableComponent,
        CancelOrderComponent,
        CompanyUsersComponent,
        CompanyOrdersComponent,
        CompanyOrderItemComponent,
        CompanyOrderComponent,
        LoadingAnimationComponent,
        AddCompanyUserComponent,
        CreateUsersPdfComponent,
        MessagesComponent,
        AddMessageComponent,
        FilterUserWithoutAppPipe,
        ShowCancelRequestOrderComponent,
        HideWithOpenCancelationPipe,
        UserMessagesComponent,
        CompanyMessagesComponent,
        AllMessagesComponent,
        MessageItemComponent,
        ShortenPipe,
        MessageInfoComponent,
        AddMenuComponent,
        SearchDishesPipe,
        DateTimeFormatPipe,
        WeekPaginationComponent,
        OrderStatsComponent,
        WeekPaginationButtonsComponent,
        SortMenusPipe,
        SortGroupedMenusPipe,
        SortMenusSumPipe,
        SortMenusSumByDatePipe,
        CouponViewComponent,
        UsersOrderStatsComponent,
        MonthPaginationComponent,
        SearchCompanyPipe,
        AddOrderComponent,
        UsersOrderMonthlyStatsComponent,
        UsersOrderDailyStatsComponent,
        SearchDishPipe,
        WeeklyUsersOrderStatsComponent,
        SortWeeklyUsersOrderStatsPipe,
        OrderStatsLessDetailsComponent,
        OrderStatsMoreDetailsComponent,
        GroupedCompanyOrderStatsTableComponent,
        SortCompaniesByNamePipe,
        SortGcostElementsByCompanyNamePipe
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        HttpClientModule,
        MatCardModule,
        MatFormFieldModule,
        MatSnackBarModule,
        MatToolbarModule,
        MatIconModule,
        MatMenuModule,
        FormsModule,
        ReactiveFormsModule,
        MatButtonModule,
        MatInputModule,
        MatRippleModule,
        MatListModule,
        MatTooltipModule,
        MatDialogModule,
        MatSelectModule,
        MatAutocompleteModule,
        MatCheckboxModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatExpansionModule,
        MatPaginatorModule,
        MatTableModule,
        MatProgressSpinnerModule,
        MatProgressBarModule,
        MatTabsModule,
        MatRadioModule,
        NgxMatDatetimePickerModule,
        NgxMatTimepickerModule,
        NgxMatMomentModule,
        MatBadgeModule
    ],
    providers: [
        { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptorService, multi: true },
        { provide: LOCALE_ID, useValue: 'de-AT' },
        { provide: DateAdapter, useClass: CustomDateAdapter }
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
