import { Pipe, PipeTransform } from '@angular/core';
import { Menu } from '../../../../model/menu.model';

@Pipe({
  name: 'sortMenus',
  pure: false
})
export class SortMenusPipe implements PipeTransform {

  transform(menus: Menu[]): Menu[] {
    return menus.sort((a, b) => {
      if (a.available_date != b.available_date)
        return a.available_date < b.available_date ? -1 : (a.available_date > b.available_date ? 1 : 0);
      else
        return a.id < b.id ? -1 : (a.id > b.id ? +1 : 0);
    });
  }

}
