import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MenuService } from '../../services/menu.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DishService } from '../../services/dish.service';
import { Dish } from '../../model/dish.model';
import { Observable } from 'rxjs';
import { startWith, map, take } from 'rxjs/operators';
import { Menu } from 'src/app/model/menu.model';
import { environment } from '../../../environments/environment';
import { AddMenuComponent } from './add-menu/add-menu.component';
import { ActivatedRoute } from '@angular/router';
import { MenuDetailsComponent } from './menu-details/menu-details.component';
import { ConfirmationDialogComponent } from '../../utils/confirmation-dialog/confirmation-dialog.component';


@Component({
  selector: 'app-menus',
  templateUrl: './menus.component.html',
  styleUrls: ['./menus.component.scss']
})
export class MenusComponent implements OnInit {

  menus: Observable<Menu[]>;
  menusByDate: { date: Date, menus: Menu[] }[];
  url: string = environment.url;

  constructor(private dialog: MatDialog,
    private snackbar: MatSnackBar,
    private menuService: MenuService,
    private route: ActivatedRoute) {
    this.menusByDate = [];
  }


  ngOnInit(): void {

    this.route.queryParams.subscribe(params => {
      this.menuService.getAll(params['from'], params['to']);
    });

    this.menus = this.menuService.menus;
    
    this.menus.subscribe((menus: Menu[]) => {
      this.menusByDate = [];
      menus.forEach(menu => {
        var set = false;
        this.menusByDate.forEach(md => {
          if (md.date == menu.available_date) {
            set = true;
            md.menus.push(menu);
            return;
          }
        });
        if (!set) {
          this.menusByDate.push({ date: menu.available_date, menus: [menu] });
        }
      });
    });
  }

  remove(md: { date: Date, menus: Menu[] }, menu: Menu) {
    let dialogRef: MatDialogRef<ConfirmationDialogComponent>;

    if (menu.order_count > 0) {
      dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        data: `Sind Sie sicher, dass Sie das Menü löschen wollen? Es wurde bereits ${menu.order_count} Mal bestellt.`
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result)
          this.deleteMenu(menu);
      });

    } else {
      this.deleteMenu(menu);
    }
  }


  private deleteMenu(menu: Menu) {
    this.menuService.delete(menu.id).pipe(take(1)).subscribe(() => {
      this.menusByDate.forEach(md => {
        if (md.date == menu.available_date)
          md.menus = md.menus.filter(m => m !== menu);
      })
      this.snackbar.open("Das Menü wurde gelöscht", "Schließen", { duration: 5000 });
    }, error => this.snackbar.open(error.error.error, "Schließen", { duration: 10000, panelClass: ['warn-snackbar'] }));
  }

  add() {
    this.dialog.open(AddMenuComponent, { width: "800px" });
  }


  openMenuDetails(menu: Menu) {
    this.dialog.open(MenuDetailsComponent, {
      data: menu
    })
  }

}
