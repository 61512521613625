import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AddCompanyComponent } from '../add-company/add-company.component';
import { CompanyService } from 'src/app/services/company.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Company } from '../../../model/company.model';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { CompanyTypeService } from 'src/app/services/company-type.service';
import { take } from 'rxjs/operators';
import { CompanyType } from 'src/app/model/company-type.model';

@Component({
  selector: 'app-edit-company',
  templateUrl: './edit-company.component.html',
  styleUrls: ['./edit-company.component.scss']
})
export class EditCompanyComponent implements OnInit {

  domainRegex: RegExp = new RegExp('[a-z0-9äöü]+[a-z0-9äöü\-]*[a-z0-9äöü]+(\.[a-z0-9äöü]+[a-z0-9äöü\-]*[a-z0-9äöü]+)+');

  editCompanyForm = new UntypedFormGroup({
    name: new UntypedFormControl(null, [Validators.required]),
    domain: new UntypedFormControl(null, [Validators.required, Validators.pattern(this.domainRegex)]),
    coupon_discount: new UntypedFormControl(null, [Validators.min(0)]),
    uses_coupons: new UntypedFormControl(true, [Validators.required]),
    max_coupons_week: new UntypedFormControl(null, [Validators.pattern("^[0-9]*$"), Validators.min(1)]),
    needs_cutlery: new UntypedFormControl(null, [Validators.required]),
    company_type_id: new UntypedFormControl(null, [Validators.required])
  });

  company_types: CompanyType[];

  constructor(private dialogRef: MatDialogRef<AddCompanyComponent>,
    private companyService: CompanyService,
    private companyTypeService: CompanyTypeService,
    private snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public company: Company) { }

  ngOnInit(): void {

    this.companyTypeService.getAll().pipe(take(1)).subscribe(cts => this.company_types = cts);

    this.editCompanyForm.setValue({
      name: this.company.name,
      domain: this.company.domain,
      coupon_discount: this.company.coupon_discount,
      uses_coupons: this.company.uses_coupons,
      max_coupons_week: this.company.max_coupons_week,
      needs_cutlery: this.company.needs_cutlery,
      company_type_id: this.company.company_type_id
    });
  }

  cancel() {
    this.dialogRef.close(false);
  }

  edit() {

    if (!this.editCompanyForm.get('uses_coupons').value) {
      this.editCompanyForm.get('coupon_discount').setValue(null);
      this.editCompanyForm.get('max_coupons_week').setValue(null)
    }

    this.companyService.update(
      this.company.id,
      this.editCompanyForm.get("name").value,
      this.editCompanyForm.get("domain").value,
      this.editCompanyForm.get('uses_coupons').value,
      this.editCompanyForm.get('company_type_id').value,
      this.editCompanyForm.get('needs_cutlery').value,
      this.editCompanyForm.get("coupon_discount").value,
      this.editCompanyForm.get("max_coupons_week").value).subscribe(
        (company: Company) => {

          this.companyService.getAll();
          this.snackBar.open(`Die Firma ${company.name} wurde geändert`, "Schließen", { duration: 3000 });
          this.dialogRef.close(true);

        },
        error => this.snackBar.open(error.error.error, "Schließen", { duration: 10000, panelClass: ['warn-snackbar'] }));
  }

}
