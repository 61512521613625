import { Pipe, PipeTransform } from '@angular/core';
import { User } from 'src/app/model/user.model';

@Pipe({
  name: 'filterUserWithoutApp',
  pure: false
})
export class FilterUserWithoutAppPipe implements PipeTransform {

  transform(users: { name: string, user: User }[]): { name: string, user: User }[] {
    return users.filter(user => user.user.player_id);
  }

}
