import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { User } from 'src/app/model/user.model';
import { UserService } from '../../services/user.service';
import { AddUserComponent } from './add-user/add-user.component';
import { MatDialog } from '@angular/material/dialog';
import { ResetUserPasswordComponent } from './reset-user-password/reset-user-password.component';
import { CreateUserPdfComponent } from './create-user-pdf/create-user-pdf.component';
import { ConfirmationDialogComponent } from 'src/app/utils/confirmation-dialog/confirmation-dialog.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { EditUserComponent } from './edit-user/edit-user.component';
import { PaginationInfo } from '../../utils/pagination-info.model';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit {

  paginationInfo: PaginationInfo;
  users: Observable<User[]> = new Observable<User[]>(null);
  currentUser: User;
  searchUser: string;

  constructor(private userService: UserService,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private route: ActivatedRoute,
    private router: Router) { }



  ngOnInit(): void {
    this.users = this.userService.users;
    this.userService.user.subscribe(user => this.currentUser = user);
    this.userService.paginationInfo.subscribe(pi => this.paginationInfo = pi);

    this.route.queryParams.subscribe(params => {
      this.userService.getAll(+(params['page'] ?? 1), 20, (this.searchUser && this.searchUser.length > 2) ? this.searchUser : null);
    });
  }

  search() {
    if (this.searchUser && this.searchUser.length > 2) {
      this.userService.getAll(1, 20, this.searchUser);
      this.router.navigate(
        [],
        {
          relativeTo: this.route,
          queryParams: { 'page': 1 },
          queryParamsHandling: 'merge'
        });
    }
    if (!this.searchUser || this.searchUser.length == 0) {
      this.userService.getAll(1);
      this.router.navigate(
        [],
        {
          relativeTo: this.route,
          queryParams: { 'page': 1 },
          queryParamsHandling: 'merge'
        });
    }
  }

  add() {
    const dialogRef = this.dialog.open(AddUserComponent, {
      width: "600px"
    });
  }

  edit(user: User) {
    const dialogRef = this.dialog.open(EditUserComponent, {
      width: "600px",
      data: user
    })
  }

  remove(user: User) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: "350px",
      data: `Wollen Sie den Benutzer "${user.firstname} ${user.lastname}" und alle seine Daten wirklich löschen?`
    });
    dialogRef.afterClosed().subscribe(result => {
      if (!result) return;

      this.userService.delete(user.id).subscribe(() => {
        this.userService.getAll();
        this.snackBar.open(`Der Benutzer "${user.firstname} ${user.lastname}" wurde gelöscht`, "Schließen", { duration: 3000 });
      }, error =>
        this.snackBar.open("Beim Löschen ist ein Fehler aufgetreten", "Schließen", { duration: 10000, panelClass: ['warn-snackbar'] })
      );
    });
  }

  changePassword(user: User) {
    this.dialog.open(ResetUserPasswordComponent, {
      width: '600px',
      data: user
    });
  }

  createUserPdf(user: User) {
    this.dialog.open(CreateUserPdfComponent, {
      data: {
        "user": user,
        "password": user.generated_password,
        "company": user.company
      }
    });
  }

}
