<mat-card class="body-wrapper">

  <mat-card-content>

    <app-return-button [path]="'/dashboard'" [text]="'Dashboard'"></app-return-button>
    <h1>Benachrichtigungen</h1>

    <div class="wrapper">
      <button mat-stroked-button color="primary" (click)="add()">
        <mat-icon>add</mat-icon> Erstellen
      </button>
      <mat-form-field appearance="outline">
        <mat-label>Zeige Nachrichten an</mat-label>
        <mat-select [(ngModel)]="for" (ngModelChange)="changeRoute()">
          <mat-option value="user">Benutzer</mat-option>
          <mat-option value="company">Firmen</mat-option>
          <mat-option value="all">Alle</mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <app-all-messages *ngIf="for==='all'"></app-all-messages>
    <app-company-messages *ngIf="for==='company'"></app-company-messages>
    <app-user-messages *ngIf="for==='user'"></app-user-messages>

  </mat-card-content>

</mat-card>
