<mat-card class="body-wrapper">

  <mat-card-content>

    <app-return-button [path]="'/dashboard'" [text]="'Dashboard'"></app-return-button>

    <h1>Firmen</h1>

    <button mat-stroked-button color="primary" (click)="add()">
      <mat-icon>add</mat-icon> Erstellen
    </button>

    <mat-form-field class="search" appearance="fill">
      <mat-label>Suchen</mat-label>
      <mat-icon matPrefix>search</mat-icon>
      <input type="text" matInput [(ngModel)]="searchUser">
      <button *ngIf="searchUser" matSuffix mat-icon-button aria-label="Clear" (click)="searchUser=''">
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>

    <mat-list>

      <mat-list-item *ngFor="let company of companies|async|searchCompany:searchUser" class="company-list-item">

        <div class="content">

          <div class="list-data-wrapper">
            <div class="main-text">
              {{company.name}}
            </div>
            <div class="secondary-text">
              {{company.domain}}
            </div>
          </div>

          <div class="spacer"></div>

          <mat-icon matTooltip="Dashboard" [routerLink]="['/companies',company.id]">dashboard</mat-icon>

          <mat-icon [matMenuTriggerFor]="menu">more_vert</mat-icon>

          <mat-menu #menu="matMenu">
            <button mat-menu-item [routerLink]="['/companies',company.id,'coupon-view']"
                    [queryParams]="{'date':startDateString}" *ngIf="company.uses_coupons">
              <mat-icon>verified</mat-icon><span>Couponliste</span>
            </button>

            <button mat-menu-item [routerLink]="['/companies',company.id,'users-order-stats']"
                    [queryParams]="{'month':date.getMonth(),'year':date.getFullYear()}" *ngIf="!company.uses_coupons">
              <mat-icon>table_chart</mat-icon><span>Bestellstatistik</span>
            </button>

            <button (click)="edit(company)" mat-menu-item>
              <mat-icon>edit</mat-icon><span>Firma bearbeiten</span>
            </button>

            <button (click)="remove(company)" mat-menu-item>
              <mat-icon>delete</mat-icon><span>Firma löschen</span>
            </button>
          </mat-menu>

          <mat-divider></mat-divider>

        </div>

      </mat-list-item>

    </mat-list>

  </mat-card-content>

</mat-card>
