import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Dish } from '../model/dish.model';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class DishService {

  private _dishes: BehaviorSubject<Dish[]>;

  get dishes(): Observable<Dish[]> {
    return this._dishes.asObservable();
  }

  constructor(private http: HttpClient) {
    this._dishes = new BehaviorSubject<Dish[]>(null);
  }

  getAll() {
    this.http.get<Dish[]>(environment.apiURL + 'dishes').pipe(take(1)).subscribe((dishes: Dish[]) => {
      this._dishes.next(dishes);
    });
  }

  create(data: FormData) {
    var headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });
    return this.http.post<Dish>(environment.apiURL + 'dishes', data, { headers: headers });
  }

  update(dish_id: number, data: FormData) {
    var headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });
    return this.http.post<Dish>(environment.apiURL + `dishes/${dish_id}`, data, { headers: headers });
  }

  delete(dish_id: number) {
    return this.http.delete(environment.apiURL + `dishes/${dish_id}`);
  }

}
