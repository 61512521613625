<div class="paginator-container" *ngIf='paginationInfo && paginationInfo.pages>1'>
    <label>Seite <input type="number" #currentPage [value]="paginationInfo.currentPage" (keydown.enter)="changePage($event)"
            [max]="paginationInfo.pages" [min]="1" (focusout)="currentPage.value=paginationInfo.currentPage"> von
        {{paginationInfo.pages}}</label>
    <mat-icon [ngClass]="{'disabled':paginationInfo.currentPage<=2}"
        [matTooltip]="paginationInfo.currentPage<=2?'':'Erste Seite'" (click)="firstPage()">
        first_page
    </mat-icon>
    <div class="space-between"></div>
    <mat-icon [ngClass]="{'disabled':paginationInfo.currentPage<=1}"
        [matTooltip]="paginationInfo.currentPage<=1?'':'Vorherige Seite'" (click)="previousPage()">
        chevron_left
    </mat-icon>
    <div class="space-between"></div>
    <mat-icon [ngClass]="{'disabled':paginationInfo.currentPage>=paginationInfo.pages}"
        [matTooltip]="paginationInfo.currentPage>=paginationInfo.pages?'':'Nächste Seite'" (click)="nextPage()">
        chevron_right
    </mat-icon>
    <div class="space-between"></div>
    <mat-icon [ngClass]="{'disabled':paginationInfo.currentPage>=paginationInfo.pages-1}"
        [matTooltip]="paginationInfo.currentPage>=paginationInfo.pages-1?'':'Letzte Seite'" (click)="lastPage()">
        last_page
    </mat-icon>
</div>
