<form class="add-menu-form" [formGroup]="addMenuForm" (ngSubmit)="add()">
    <mat-form-field appearance="fill">
        <mat-label>Datum des Menüs</mat-label>
        <input matInput (focus)="picker.open()" formControlName="available_date" [matDatepicker]="picker" [matDatepickerFilter]="dayFilter">
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>

    <mat-form-field appearance="fill" class="dish-selection">
        <mat-label>Gericht</mat-label>
        <input #dish type="text" aria-label="Number" matInput formControlName="dish" [matAutocomplete]="auto">
        <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
            <mat-option *ngFor="let dish of dishOptions | async" [value]="dish">
                {{dish.name}} - €{{dish.price}}
            </mat-option>
        </mat-autocomplete>
    </mat-form-field>

<!--    <button class="login-button" [disabled]="addMenuForm.invalid || !isObject(addMenuForm.get('dish').value)"-->
<!--        mat-stroked-button color="primary" type="submit">Erstellen</button>-->
    <button mat-stroked-button color="primary" class="add-btn" [disabled]="addMenuForm.invalid || !isObject(addMenuForm.get('dish').value)" type="submit">
<!--      <mat-icon>add</mat-icon>-->
      Erstellen
    </button>
</form>
