<div class="table-responsive" *ngIf="gcostHeader">

  <table>

    <thead>

    <tr>
      <th colspan="100">
        Gesamt
      </th>
    </tr>

    <tr>

      <th *ngFor="let dwm of gcostSum.daysWithMenus" [colSpan]="dwm.menus.length">
        {{dwm.date|date:'EEEE, d. M.'}}
      </th>

    </tr>

    <tr>
      <ng-container *ngFor="let dwm of gcostSum.daysWithMenus">

        <th class="menu"
            [ngClass]="{
            'vegetarian':m.menu.vegetarian,
            'meat':!m.menu.vegetarian,
            'low-carb':m.menu.low_carb,
            'special':m.menu.special,
            'last-menu':dwm.menus.length-1==i
            }"
            (click)="openMenuDetails(m.menu)"
            [matTooltip]="m.menu.name"
            *ngFor="let m of dwm.menus;let i=index">
          {{m.menu.special ? "Special" : (m.menu.vegetarian ? "Veggie" : "Fleisch")}}
        </th>

      </ng-container>
    </tr>

    </thead>

    <tbody>

    <tr>

      <ng-container *ngFor="let dwm of gcostSum.daysWithMenus">

        <td
          [ngClass]="{
          'vegetarian':m.menu.vegetarian,
          'meat':!m.menu.vegetarian,
          'low-carb':m.menu.low_carb,
          'special':m.menu.special,
          'last-menu':dwm.menus.length-1==i}"
          *ngFor="let m of dwm.menus;let i=index">
          {{m.count}}
        </td>

      </ng-container>

    </tr>

    <tr>

      <td *ngFor="let dwm of gcostSum.daysWithMenus" [colSpan]="dwm.menus.length">
        {{dwm.menuCount}}
      </td>

    </tr>

    </tbody>

    <tfoot>

    <tr>
      <td colspan="100">
        {{gcostSum.sumOfMenus}} Essen insgesamt
      </td>
    </tr>

    <tr>
      <td colspan="100">
        Ø {{gcostSum.avgMenusPerDay | number}} pro Tag
      </td>
    </tr>

    </tfoot>

  </table>

</div>

<ng-container *ngIf="gcostHeader;else spinner">

  <app-grouped-company-order-stats-table *ngFor="let company_type of companyTypes$ | async"
                                         [company_type]="company_type"
                                         [gcostHeader]="gcostHeader"
                                         (calculatedGcostFooter)="addGcostFooterToSum($event)"></app-grouped-company-order-stats-table>

</ng-container>

<ng-template #spinner>
  <mat-spinner></mat-spinner>
</ng-template>
