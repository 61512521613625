import { Component, OnInit } from '@angular/core';
import { Order } from 'src/app/model/order.model';
import { ActivatedRoute } from '@angular/router';
import { OrderService } from 'src/app/services/order.service';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-company-order-details',
  templateUrl: './company-order.component.html',
  styleUrls: ['./company-order.component.scss']
})
export class CompanyOrderComponent implements OnInit {
  
  order: Order;
  page: number = 1;
  sum: number = 0;
  discount: number = 0;
  coupon_count: number = 0;

  constructor(private route: ActivatedRoute,
    private orderService: OrderService) { }


  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.orderService.getOne(+params['order_id']).pipe(take(1)).subscribe((order: Order) => {
        this.order = order;

        this.order.order_menus.forEach(om => {
          this.sum += +om.menu.price;
          if (om.has_coupon) {
            this.discount += +this.order.coupon_discount;
            this.coupon_count++;
          }
        });

      });
    });
    if (history.state.page)
      this.page = history.state.page;
  }



}
