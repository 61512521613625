<mat-card class="body-wrapper" *ngIf="company">

  <mat-card-content>

    <app-return-button [path]="'/companies/'+company.id" [text]="company.name"></app-return-button>
    <h1>Benutzer von {{company.name}}</h1>

    <button mat-stroked-button color="primary" (click)="add()" class="add-button">
      <mat-icon>add</mat-icon> Erstellen
    </button>
    <button mat-stroked-button color="primary" (click)="createUsersPDF()" *ngIf="company?.user_count>0">
      <mat-icon>get_app</mat-icon> Zugangsdaten-PDF generieren
    </button>

    <div class="search-pagination-wrapper">
      <mat-form-field appearance="fill">
        <mat-label>Suchen</mat-label>
        <mat-icon matPrefix>search</mat-icon>
        <input type="text" matInput [(ngModel)]="searchUser" (ngModelChange)="search()">
        <button mat-button *ngIf="searchUser" matSuffix mat-icon-button aria-label="Clear" (click)="searchUser='';search()">
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>
      <app-pagination [paginationInfo]="paginationInfo"></app-pagination>
    </div>
    <mat-list *ngIf="currentUser">
      <mat-list-item *ngFor="let user of users|async"
                     [ngClass]="{'not-activated':!user.activated,'display-none':user.id==currentUser.id}">

        <div class="content">

          <mat-icon class="activated-icon" *ngIf='user.activated' matTooltip="Benutzer wurde bereits aktiviert">done
          </mat-icon>
          <mat-icon class="activated-icon" *ngIf='!user.activated' matTooltip="Benutzer wurde noch nicht aktiviert">
            schedule</mat-icon>
          <div class="list-data-wrapper">
            <div class="main-text">
              {{user.firstname}} {{user.lastname}}
              <mat-icon matTooltip="Firmenadmin" *ngIf="user.role.toString()=='COMPANY'">star_outline</mat-icon>
              <mat-icon matTooltip="Administrator" *ngIf="user.role.toString()=='ADMIN'">star</mat-icon>
            </div>
            <div class="secondary-text">
              {{user.username}}
            </div>
          </div>
          <div style="flex: auto;"></div>
          <mat-icon [matMenuTriggerFor]="menu">more_vert</mat-icon>
          <mat-menu #menu="matMenu">
            <button (click)="changePassword(user)" mat-menu-item *ngIf="user.activated">
              <mat-icon>restore</mat-icon>Passwort ändern
            </button>
            <button (click)="edit(user)" mat-menu-item>
              <mat-icon>edit</mat-icon>Benutzer bearbeiten
            </button>
            <button (click)="remove(user)" mat-menu-item>
              <mat-icon>delete</mat-icon>Benutzer löschen
            </button>
            <button (click)="createUserPdf(user)" mat-menu-item *ngIf="!user.activated">
              <mat-icon>get_app</mat-icon>Zugangsdaten-PDF generieren
            </button>
          </mat-menu>
          <mat-divider></mat-divider>

        </div>

      </mat-list-item>
    </mat-list>

  </mat-card-content>

</mat-card>
<app-loading-animation [condition]="!company"></app-loading-animation>
