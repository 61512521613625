<h1 mat-dialog-title>Benutzer erstellen</h1>
<div mat-dialog-content>
    <form [formGroup]="addCompanyUserForm">
        <mat-form-field>
            <mat-label>Vorname</mat-label>
            <input #firstname matInput type="text" formControlName="firstname">
            <mat-error *ngIf="addCompanyUserForm.get('firstname').hasError('required')">Ein Vorname ist erforderlich
            </mat-error>
        </mat-form-field>
        <mat-form-field>
            <mat-label>Nachname</mat-label>
            <input type="text" matInput formControlName="lastname">
            <mat-error *ngIf="addCompanyUserForm.get('lastname').hasError('required')">Ein Nachname ist erforderlich
            </mat-error>
        </mat-form-field>
        <mat-form-field>
            <mat-label>Passwort</mat-label>
            <input type="text" matInput formControlName="password">
            <mat-hint>Das Passwort muss beim ersten Anmelden geändert werden</mat-hint>
            <mat-error *ngIf="addCompanyUserForm.get('password').hasError('required')">Ein Passwort ist erforderlich
            </mat-error>
            <mat-error *ngIf="addCompanyUserForm.get('password').hasError('minlength')">Das Passwort muss mindestens 6
                Zeichen lang sein</mat-error>
        </mat-form-field>
        <mat-form-field>
            <mat-label>Benutzer-Art</mat-label>
            <mat-select formControlName="role">
                <mat-option value="NORMAL">Normal</mat-option>
                <mat-option value="COMPANY">Firmenadmin</mat-option>
            </mat-select>
        </mat-form-field>
    </form>
</div>
<div mat-dialog-actions>
    <button mat-button (click)="cancel()">Abbrechen</button>
    <button mat-stroked-button (click)="add()" [disabled]="addCompanyUserForm.invalid" cdkFocusInitial>Erstellen</button>
    <mat-checkbox [(ngModel)]="stayOpen">Fenster offen lassen</mat-checkbox>
</div>