import { Component, OnInit } from '@angular/core';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { UserService } from '../../../services/user.service';
import { OrderService } from '../../../services/order.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { User } from 'src/app/model/user.model';
import { map, startWith, take } from 'rxjs/operators';
import * as moment from 'moment';
import { MenuService } from '../../../services/menu.service';
import { Menu } from 'src/app/model/menu.model';
import { MenuDetailsComponent } from '../../menus/menu-details/menu-details.component';
import { environment } from 'src/environments/environment';
import { Order } from 'src/app/model/order.model';

@Component({
  selector: 'app-add-order',
  templateUrl: './add-order.component.html',
  styleUrls: ['./add-order.component.scss']
})
export class AddOrderComponent implements OnInit {

  addOrderForm: UntypedFormGroup = new UntypedFormGroup({
    user: new UntypedFormControl(null, [Validators.required])
  });

  menus: Observable<Menu[]>;
  menusByDate: { date: Date, menus: Menu[] }[];
  userOptions: Observable<{ name: string, user: User }[]>;
  startDate: Date;
  endDate: Date;
  url: string = environment.url;
  orderMenus: { menu: Menu, has_coupon: boolean }[] = [];


  constructor(private dialogRef: MatDialogRef<AddOrderComponent>,
    private userService: UserService,
    private orderService: OrderService,
    private snackbar: MatSnackBar,
    private menuService: MenuService,
    private dialog: MatDialog) { }

  ngOnInit(): void {

    // get menus for current week
    this.startDate = moment().startOf('week').toDate();
    this.endDate = moment().startOf('week').toDate();
    this.startDate.setDate(this.startDate.getDate() + 1);
    this.endDate.setDate(this.endDate.getDate() + 4);
    this.menuService.getAll(this.makeDateString(this.startDate), this.makeDateString(this.endDate));
    this.menus = this.menuService.menus;


    this.userService.getAllWithoutPagination();
    this.userService.users.subscribe((users: User[]) => {
      var userOpt: { name: string, user: User }[] = [];
      if (users)
        users.forEach(user => userOpt.push({ name: `${user.firstname} ${user.lastname} - ${user.username} ${user.company ? '(' + user.company.name + ')' : ''}`, user: user }));

      this.userOptions = this.addOrderForm.get("user").valueChanges
        .pipe(
          startWith(''),
          map(value => typeof value === 'string' ? value : value.name),
          map(name => name ?
            userOpt.filter(option => option.name.toLowerCase().indexOf(name.toLowerCase()) >= 0) :
            userOpt.slice())
        );
    });

    this.menus.subscribe((menus: Menu[]) => {

      this.menusByDate = [];
      menus.forEach(menu => {
        var set = false;
        
        this.menusByDate.forEach(md => {
          if (md.date == menu.available_date) {
            set = true;
            md.menus.push(menu);
            return;
          }
        });

        if (!set) {
          this.menusByDate.push({ date: menu.available_date, menus: [menu] });
        }

      });
    });
  }

  previousWeek() {
    this.startDate = new Date(this.startDate.setDate(this.startDate.getDate() - 7));
    this.endDate = new Date(this.endDate.setDate(this.endDate.getDate() - 7));
    this.menuService.getAll(this.makeDateString(this.startDate), this.makeDateString(this.endDate));
  }

  nextWeek() {
    this.startDate = new Date(this.startDate.setDate(this.startDate.getDate() + 7));
    this.endDate = new Date(this.endDate.setDate(this.endDate.getDate() + 7));
    this.menuService.getAll(this.makeDateString(this.startDate), this.makeDateString(this.endDate));
  }

  add() {
    let orderMenus: { menu_id: number, has_coupon: boolean }[] = this.orderMenus.map<{ menu_id: number, has_coupon: boolean }>(om => ({ menu_id: om.menu.id, has_coupon: om.has_coupon }));
    let user_id = this.addOrderForm.get('user').value.id;

    this.orderService.create(user_id, orderMenus).pipe(take(1)).subscribe((order: Order) => {

      this.dialogRef.close(true);
      this.orderService.getAll();
      this.snackbar.open("Die Bestellung wurden erstellt.", "Schließen", { duration: 2000 });

    },
      error => this.snackbar.open(error.error.error, "Schließen", { duration: 10000, panelClass: ['warn-snackbar', 'allow-linebreak-snackbar'] }));
  }

  addToOrder(menu: Menu) {
    this.orderMenus.push({ menu: menu, has_coupon: false });
  }

  removeFromOrder(index: number) {
    this.orderMenus.splice(index, 1);
  }

  openMenuDetails(menu: Menu) {
    this.dialog.open(MenuDetailsComponent, {
      data: menu
    })
  }

  isObject(val): boolean { return val instanceof Object; }

  displayFnUser(user: User): string {
    return user ? `${user.firstname} ${user.lastname} - ${user.username} ${user.company ? '(' + user.company.name + ')' : ''}` : '';
  }


  private makeDateString(date: Date): string {
    return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`
  }
}
