<h1 mat-dialog-title>Neues Passwort festlegen</h1>

<div mat-dialog-content>

  <form [formGroup]="resetPasswordForm">

    <mat-form-field>
      <mat-label>Passwort</mat-label>
      <input matInput type="text" formControlName="password">
      <mat-error *ngIf="resetPasswordForm.get('password').hasError('required')">Ein Passwort ist erforderlich
      </mat-error>
      <mat-error *ngIf="resetPasswordForm.get('password').hasError('minlength')">Das Passwort muss mindestens 6 Zeichen
        lang sein</mat-error>
    </mat-form-field>

  </form>

</div>

<div mat-dialog-actions>

  <button mat-button (click)="cancel()">Abbrechen</button>
  <button mat-stroked-button (click)="changePassword()" [disabled]="resetPasswordForm.invalid"
    cdkFocusInitial>Ändern</button>

</div>
