<h1 mat-dialog-title>Bestellung <strong>#{{order.id}}</strong> stornieren</h1>

<div mat-dialog-content>
  <form [formGroup]="addCancelationForm">

    <mat-form-field>
      <mat-label>Nachricht an den Kunden</mat-label>
      <textarea matInput type="text" formControlName="message"></textarea>
      <mat-hint>Diese Nachricht wird dem Kunden per Push-Benachrichtigung ans Smartphone gesendet</mat-hint>
      <mat-error *ngIf="addCancelationForm.get('message').hasError('required')">Ein Nachricht ist erforderlich
      </mat-error>
    </mat-form-field>

  </form>
</div>

<div mat-dialog-actions>

  <button mat-button (click)="cancel()">Abbrechen</button>
  <button mat-stroked-button (click)="add()" [disabled]="addCancelationForm.invalid" cdkFocusInitial
    color="warn">Stornieren</button>

</div>
