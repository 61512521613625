import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { CompanyType } from '../model/company-type.model';

@Injectable({
  providedIn: 'root'
})
export class CompanyTypeService {

  constructor(private http: HttpClient) { }

  getAll(): Observable<CompanyType[]> {
    return this.http.get<CompanyType[]>(environment.apiURL + 'company_types');
  }
}
