import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Menu } from '../model/menu.model';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { take } from 'rxjs/operators';
import { PaginationInfo } from '../utils/pagination-info.model';
import { Message } from 'src/app/model/message.model';

@Injectable({
  providedIn: 'root'
})
export class MenuService {

  private _menus: BehaviorSubject<Menu[]>;
  private startDateString: string;
  private endDateString: string;

  get menus(): Observable<Menu[]> {
    return this._menus.asObservable();
  }


  constructor(private http: HttpClient) {
    this._menus = new BehaviorSubject<Menu[]>([]);
  }



  public getAll(from?: string, to?: string, hidden: boolean = false) {
    if (!from || !to) {
      this.setStartAndEndDateOfCurrentWeek();
      from = this.startDateString;
      to = this.endDateString;
    }
    this.http.get<Menu[]>(environment.apiURL + `menus/admin?from=${from}&to=${to}${hidden?'&hidden':''}`).pipe(take(1)).subscribe((menus: Menu[]) => {
      this._menus.next(menus);
    })
  }

  public getAllAsObservable(from?: string, to?: string, hidden: boolean = false): Observable<Menu[]> {
    if (!from || !to) {
      this.setStartAndEndDateOfCurrentWeek();
      from = this.startDateString;
      to = this.endDateString;
    }
    return this.http.get<Menu[]>(environment.apiURL + `menus/admin?from=${from}&to=${to}${hidden?'&hidden':''}`);
  }

  public create(available_date, dish_id: number): Observable<Menu> {
    return this.http.post<Menu>(environment.apiURL + 'menus', {
      "available_date": available_date,
      "dish_id": dish_id
    });
  }

  public createMany(available_date, dish_ids: number[]): Observable<any> {
    return this.http.post(environment.apiURL + 'menus/many', {
      "available_date": available_date,
      "dish_ids": dish_ids
    });
  }

  public delete(menu_id: number): Observable<any> {
    return this.http.delete<any>(environment.apiURL + `menus/${menu_id}`);
  }

  private setStartAndEndDateOfCurrentWeek() {
    var curr = new Date();
    var first = curr.getDate() - ((curr.getDay() == 0 ? 7 : curr.getDay()) - 1);
    var startDate = new Date(curr.setDate(first));
    this.startDateString = `${startDate.getFullYear()}-${startDate.getMonth() + 1}-${startDate.getDate()}`;

    var endDate = startDate;
    endDate.setDate(startDate.getDate() + 3);
    this.endDateString = `${endDate.getFullYear()}-${endDate.getMonth() + 1}-${endDate.getDate()}`;
  }
}
