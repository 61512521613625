<div class="content-body" *ngIf="user">

  <div class="spacing"></div>

  <form class="login-wrapper" [formGroup]="activateUserForm" (ngSubmit)="activate()">

    <mat-card-title>Der Benutzer {{user.firstname}} {{user.lastname}} muss erst aktiviert werden</mat-card-title>

    <mat-form-field appearance="fill">
      <mat-label>Altes Passwort</mat-label>
      <input matInput type="password" formControlName="old_password">
      <mat-error *ngIf="activateUserForm.get('old_password').hasError('required')">Das alte Passwort ist
        erforderlich</mat-error>
      <mat-error *ngIf="activateUserForm.get('old_password').hasError('minlength')">Das alte Passwort muss
        mindestens 6 Zeichen lang sein</mat-error>
    </mat-form-field>

    <mat-form-field appearance="fill">
      <mat-label>Neues Passwort</mat-label>
      <input matInput type="password" formControlName="password">
      <mat-error *ngIf="activateUserForm.get('password').hasError('required')">Das neue Passwort ist erforderlich
      </mat-error>
      <mat-error *ngIf="activateUserForm.get('password').hasError('minlength')">Das neue Passwort muss mindestens
        6 Zeichen lang sein</mat-error>
    </mat-form-field>

    <mat-form-field appearance="fill">
      <mat-label>Neues Passwort bestätigen</mat-label>
      <input type="password" matInput formControlName="password_confirmation">
      <mat-error *ngIf="activateUserForm.get('password_confirmation').hasError('required')">Bitte bestätigen Sie
        Ihr Passwort</mat-error>
    </mat-form-field>

    <button class="login-button" [disabled]="activateUserForm.invalid
      ||activateUserForm.get('password_confirmation').value!=activateUserForm.get('password').value
      ||activateUserForm.get('old_password').value==activateUserForm.get('password').value" mat-raised-button
      color="primary" type="submit">Benutzer aktivieren</button>

    <mat-error
      *ngIf="activateUserForm.get('password').value!=''&&activateUserForm.get('old_password').value==activateUserForm.get('password').value">
      Neues Passwort darf nicht gleich sein wie das alte</mat-error>
    <mat-error
      *ngIf="activateUserForm.get('password_confirmation').value!=''&&activateUserForm.get('password_confirmation').value!=activateUserForm.get('password').value">
      Neues Passwort stimmt nicht mit der Bestätigung überein</mat-error>

  </form>

  <div class="spacing"></div>

</div>
