<mat-card [ngClass]="{'canceled':order.cancelation?.accepted || order.cancelation?.rejected}">

  <mat-card-header>

    <h2>Bestellung <strong>#{{order.id}}</strong></h2>
    <div style="flex: auto;"></div>
    <div class="cancelation-request-wrapper"
      *ngIf="order.cancelation &&  !order.cancelation.accepted && !order.cancelation.rejected">
      <h3>Stornierung wurde beantrag</h3>
      <button mat-stroked-button color="warn" (click)="openShowCancelationDialog()">Anzeigen</button>
    </div>
    <div style="flex: auto;" *ngIf="order.cancelation &&  !order.cancelation.accepted && !order.cancelation.rejected">
    </div>
    <p>
      <mat-icon>person</mat-icon>

      <span *ngIf="user; else deletedUser">{{user.firstname}} {{user.lastname}}</span>

      <ng-template #deletedUser>
        <span><i>gelöschter Benutzer</i></span>
      </ng-template>

    </p>

  </mat-card-header>

  <mat-divider></mat-divider>

  <mat-card-content>

    <table>
      <tbody>

        <tr *ngIf="order.user?.company?.uses_coupons">
          <td><strong>Gesamtsumme</strong></td>
          <td class="detail">€ {{sum|number}}</td>
        </tr>

        <tr *ngIf="order.user?.company?.uses_coupons">
          <td><strong>Rabatt</strong></td>
          <td class="detail">€ {{discount|number}}</td>
        </tr>

        <tr>
          <td><strong>zu zahlender Betrag</strong></td>
          <td class="detail">€ {{(sum-discount<0?0:sum-discount)|number}} </td>
        </tr>

      </tbody>
    </table>

    <div style="flex: auto;"></div>

    <div class="order-status">
      <p>
        <mat-icon *ngIf="order.paid">done</mat-icon>
        <mat-icon *ngIf="!order.paid">schedule</mat-icon>
        <span>Bezahlt</span>
      </p>
      <br *ngIf="order.cancelation?.rejected">
      <p *ngIf="order.cancelation?.rejected">
        <mat-icon *ngIf="order.completed">done</mat-icon>
        <span>Stornierung abgelehnt</span>
      </p>
      <br>
      <p *ngIf="order.cancelation">
        <mat-icon *ngIf="order.cancelation.accepted">done</mat-icon>
        <mat-icon *ngIf="!order.cancelation.accepted">schedule</mat-icon>
        <span>Storniert</span>
      </p>
    </div>

  </mat-card-content>

  <mat-card-actions>

    <button mat-stroked-button color="accent" (click)="openOrderDetails()" [disabled]="!order.user">Detailansicht</button>
    <button mat-stroked-button color="warn"
      [disabled]="order.cancelation && (order.cancelation.accepted || order.cancelation.rejected)"
      (click)="openCancelationDialog()">Stornieren</button>
    <div style="flex: auto;"></div>
    <span><strong>Bestelldatum: </strong>{{order.order_date|date:"dd.MM.yyyy"}}</span>

  </mat-card-actions>

</mat-card>
