import { Component, OnInit } from '@angular/core';
import { take } from 'rxjs/operators';
import { Order } from 'src/app/model/order.model';
import { OrderService } from '../../services/order.service';

@Component({
  selector: 'app-admin-dashboard',
  templateUrl: './admin-dashboard.component.html',
  styleUrls: ['./admin-dashboard.component.scss']
})
export class AdminDashboardComponent implements OnInit {

  openCancelationsCount: number = 0;
  startDateString: string;
  endDateString: string;

  constructor(private orderService: OrderService) { }

  ngOnInit(): void {
    this.setStartAndEndDateOfCurrentWeek();
    this.orderService.getWithOpenCancelation().pipe(take(1)).subscribe((orders: Order[]) => this.openCancelationsCount = orders.length);
  }

  private setStartAndEndDateOfCurrentWeek() {
    var curr = new Date();
    var first = curr.getDate() - ((curr.getDay() == 0 ? 7 : curr.getDay()) - 1);
    var startDate = new Date(curr.setDate(first));
    this.startDateString = `${startDate.getFullYear()}-${startDate.getMonth() + 1}-${startDate.getDate()}`;

    var endDate = startDate;
    endDate.setDate(startDate.getDate() + 3);
    this.endDateString = `${endDate.getFullYear()}-${endDate.getMonth() + 1}-${endDate.getDate()}`;
  }

}
