<div class="dashboard-card-wrapper">
  <mat-card routerLink="/companies">
    <mat-card-header>
      <div class="card-header">
        <mat-icon mat-card-avatar>business</mat-icon>
        <mat-card-title>Firmen</mat-card-title>
      </div>
      <mat-card-subtitle>Übersicht aller Firmen</mat-card-subtitle>
    </mat-card-header>
  </mat-card>

  <mat-card routerLink="/users">
    <mat-card-header>
      <div class="card-header">
        <mat-icon mat-card-avatar>group</mat-icon>
        <mat-card-title>Benutzer</mat-card-title>
      </div>
      <mat-card-subtitle>Übersicht aller Benutzer</mat-card-subtitle>
    </mat-card-header>
  </mat-card>

  <mat-card routerLink="/dishes">
    <mat-card-header>
      <div class="card-header">
        <mat-icon mat-card-avatar>fastfood</mat-icon>
        <mat-card-title>Gerichte</mat-card-title>
      </div>
      <mat-card-subtitle>Übersicht aller Gerichte</mat-card-subtitle>
    </mat-card-header>
  </mat-card>

  <mat-card routerLink="/menus" [queryParams]="{'from':startDateString,'to':endDateString}">
    <mat-card-header>
      <div class="card-header">
        <mat-icon mat-card-avatar>event</mat-icon>
        <mat-card-title>Menüs</mat-card-title>
      </div>
      <mat-card-subtitle>Die Menüs an den jeweiligen Tagen</mat-card-subtitle>
    </mat-card-header>
  </mat-card>


  <mat-card routerLink="/orders" [ngClass]="{'warn':openCancelationsCount>0}"
    [matTooltip]="openCancelationsCount>0?'Für '+openCancelationsCount+' Bestellung(en) wurde eine Stornierung beantragt':''">
    <mat-card-header>
      <div class="card-header">
        <mat-icon mat-card-avatar>shopping_basket</mat-icon>
        <mat-card-title>Bestellungen</mat-card-title>
      </div>
      <mat-card-subtitle>Alle Bestellungen <br><br></mat-card-subtitle>
    </mat-card-header>
  </mat-card>

  <mat-card routerLink="/order-stats/details" [queryParams]="{'date':startDateString}">
    <mat-card-header>
      <div class="card-header">
        <mat-icon mat-card-avatar>insert_chart_outlined</mat-icon>
        <mat-card-title>Bestellstatistik</mat-card-title>
      </div>
      <mat-card-subtitle>Übersicht der Bestellungen pro Woche<br><br></mat-card-subtitle>
    </mat-card-header>
  </mat-card>

  <mat-card routerLink="/messages" [queryParams]="{'for':'user'}">
    <mat-card-header>
      <div class="card-header">
        <mat-icon mat-card-avatar>campaign</mat-icon>
        <mat-card-title>Benachrichtigungen</mat-card-title>
      </div>
      <mat-card-subtitle>Benachrichtigungen an die Benutzer verwalten<br><br></mat-card-subtitle>
    </mat-card-header>
  </mat-card>
</div>
