import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Message } from '../../../model/message.model';
import { MessageService } from '../../../services/message.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-message-info',
  templateUrl: './message-info.component.html',
  styleUrls: ['./message-info.component.scss']
})
export class MessageInfoComponent implements OnInit {

  now: Date = new Date();

  constructor(private dialogRef: MatDialogRef<MessageInfoComponent>,
    @Inject(MAT_DIALOG_DATA) public message: Message,
    private messageService:MessageService,
    private snackbar:MatSnackBar,
    private route:ActivatedRoute) { }

  ngOnInit(): void {
  }

  close(){
    this.dialogRef.close();
  }

  remove() {
    this.messageService.delete(this.message.id).pipe(take(1)).subscribe(()=>{
      var params = this.route.snapshot.queryParams;
      this.messageService.getAll(+(params['page'] ?? 1), params['for'] ?? 'user');
      this.snackbar.open("Die Benachrichtigung wurde gelöscht","Schließen",{duration:5000});
      this.dialogRef.close();
    },()=>this.snackbar.open("Es ist ein Fehler aufgetreten","Schließen",{duration:10000,panelClass:['warn-snackbar']}))
  }

  parseDate(dateString:string):Date{
    return new Date(dateString.replace(/-/g,"/"));
  }

}
