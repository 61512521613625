import { Injectable } from '@angular/core';
import { BehaviorSubject, from, Observable, throwError } from 'rxjs';
import { User, ERole } from '../model/user.model';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { environment } from '../../environments/environment';
import { catchError, tap, take } from 'rxjs/operators';
import { PaginationInfo } from '../utils/pagination-info.model';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  public user: BehaviorSubject<User> = new BehaviorSubject<User>(null);
  private _users: BehaviorSubject<User[]>;
  private _paginationInfo: BehaviorSubject<PaginationInfo>;
  private startDateString: string;
  private endDateString: string;

  get paginationInfo(): Observable<PaginationInfo> {
    return this._paginationInfo.asObservable();
  }

  private expirationTimer: any;

  get users(): Observable<User[]> {
    return this._users.asObservable();
  }

  constructor(private http: HttpClient,
    private router: Router) {
    this._users = new BehaviorSubject<User[]>([]);
    this._paginationInfo = new BehaviorSubject<PaginationInfo>(null);
  }

  public login(username: string, password: string, domain: string = null) {
    return this.http.post<User>(environment.apiURL + "login", {
      "domain": domain,
      "username": username,
      "password": password
    }).pipe(catchError(this.handleError), tap((resData: User) => {
      const user: User = resData;
      if (user.role.toString() == "NORMAL")
        return this.handleError();

      var dt = new Date();
      dt.setHours(dt.getHours() + 2);
      user.expirationDate = dt;
      this.user.next(user);
      localStorage.removeItem('userData');
      localStorage.setItem('userData', JSON.stringify(user));
      this.autoLogout(user.expirationDate);
    }));
  }

  public logout() {
    this.http.post(environment.apiURL + 'logout', {}).pipe(take(1)).subscribe(x => {
    });
    this.user.next(null);
    localStorage.removeItem("userData");
    this.router.navigate(['login']);
    if (this.expirationTimer) clearTimeout(this.expirationTimer);
    this.expirationTimer = null;
  }

  public autoLogin() {
    const user: User = JSON.parse(localStorage.getItem('userData'));
    if (!user) return;

    if (!user.expirationDate || new Date() > user.expirationDate) return;
    this.user.next(user);
    this.autoLogout(user.expirationDate);
  }

  private autoLogout(expirationDate: Date) {
    if (this.expirationTimer) clearTimeout(this.expirationTimer);
    this.expirationTimer = null;
    this.expirationTimer = setTimeout(() => this.logout(), new Date(expirationDate).getTime() - (new Date()).getTime());
  }

  public register(firstname: string, lastname: string, password: string, role: string, domain: string = null): Observable<User> {
    return this.http.post<User>(environment.apiURL + "register", {
      "firstname": firstname,
      "lastname": lastname,
      "password": password,
      "role": role,
      "domain": domain
    });
  }

  public registerCompanyUser(firstname: string, lastname: string, password: string, role: string, company_id: number): Observable<User> {
    return this.http.post<User>(environment.apiURL + `companies/${company_id}/register`, {
      "firstname": firstname,
      "lastname": lastname,
      "password": password,
      "role": role
    });
  }

  public activate(old_password: string, password: string, password_confirmation: string) {
    return this.http.post(environment.apiURL + "activate", {
      "old_password": old_password,
      "password": password,
      "password_confirmation": password_confirmation
    });
  }

  public changePassword(user_id: number, password: string) {
    return this.http.patch(environment.apiURL + `users/${user_id}/password`, { "password": password });
  }

  public update(user_id: number, firstname: string, lastname: string, role: string, company_id: number): Observable<User> {
    return this.http.put<User>(environment.apiURL + `users/${user_id}`, {
      "firstname": firstname,
      "lastname": lastname,
      "role": role,
      "company_id": company_id
    })
  }


  public delete(user_id: number) {
    return this.http.delete(environment.apiURL + `users/${user_id}`);
  }

  public getUserById(user_id): Observable<User> {
    return this.http.get<User>(environment.apiURL + `users/${user_id}`);
  }

  public getAll(page: number = 1, count: number = 20, queryString: string = null) {
    this.http.get<{ "pagination_info": PaginationInfo, "users": User[] }>(environment.apiURL + `users?page=${page}&count=${count}${queryString ? '&q=' + queryString : ''}`).pipe(take(1)).subscribe(data => {
      this._paginationInfo.next(data.pagination_info);
      this._users.next(data.users);
    }
    );
  }

  public getAllWithoutPagination() {
    this.http.get<User[]>(environment.apiURL + 'allUsers').pipe(take(1)).subscribe((users: User[]) => this._users.next(users));
  }

  public getUsersByCompany(company_id: number, page: number = 1, count: number = 20, queryString: string = null) {
    this.http.get<{ "pagination_info": PaginationInfo, "users": User[] }>(environment.apiURL + `companies/${company_id}/users?page=${page}&count=${count}${queryString ? '&q=' + queryString : ''}`).pipe(take(1)).subscribe(data => {
      this._paginationInfo.next(data.pagination_info);
      this._users.next(data.users);
    }
    );
  }

  public getUsersByCompanyWithCouponCount(company_id: number, from?: string, to?: string): Observable<User[]> {
    if (!from || !to) {
      this.setStartAndEndDateOfCurrentWeek();
      from = this.startDateString;
      to = this.endDateString;
    }

    return this.http.get<User[]>(environment.apiURL + `companies/${company_id}/usersWithCouponCount?from=${from}&to=${to}`);
  }

  public getUsersByCompanyWithMenuCount(company_id: number, from?: string, to?: string): Observable<User[]> {
    if (!from || !to) {
      this.setStartAndEndDateOfCurrentWeek();
      from = this.startDateString;
      to = this.endDateString;
    }

    return this.http.get<User[]>(environment.apiURL + `companies/${company_id}/usersWithMenuCount?from=${from}&to=${to}`);
  }

  public getUsersByCompanyWithMenuCountPerDay(company_id: number, from?: string, to?: string): Observable<any[]> {
    if (!from || !to) {
      this.setStartAndEndDateOfCurrentWeek();
      from = this.startDateString;
      to = this.endDateString;
    }

    return this.http.get<any[]>(environment.apiURL + `companies/${company_id}/usersWithMenuCountPerDay?from=${from}&to=${to}`);
  }

  public getUsersByCompanyWithMenus(company_id: number, from: string, to: string): Observable<any[]> {
    return this.http.get<any[]>(environment.apiURL + `companies/${company_id}/usersWithMenus?from=${from}&to=${to}`)
  }

  private setStartAndEndDateOfCurrentWeek() {
    var curr = new Date();
    var first = curr.getDate() - ((curr.getDay() == 0 ? 7 : curr.getDay()) - 1);
    var startDate = new Date(curr.setDate(first));
    this.startDateString = `${startDate.getFullYear()}-${startDate.getMonth() + 1}-${startDate.getDate()}`;

    var endDate = startDate;
    endDate.setDate(startDate.getDate() + 3);
    this.endDateString = `${endDate.getFullYear()}-${endDate.getMonth() + 1}-${endDate.getDate()}`;
  }

  private handleError(error?: HttpErrorResponse) {
    console.log(error)
    let errorMessage = "Ein Fehler ist aufgetreten";
    if (error?.error?.error)
      switch (error.error.error) {
        case 'Domain existiert nicht': errorMessage = "Domäne, Nutzername oder Passwort sind falsch"; break;
        case 'Benutzer existiert nicht': errorMessage = "Domäne, Nutzername oder Passwort sind falsch"; break;
        case 'Passwort falsch': errorMessage = "Domäne, Nutzername oder Passwort sind falsch"; break;
        default: errorMessage = "Ein Fehler ist aufgetreten"; break;
      }
    return throwError(errorMessage);
  }
}
