import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'sortCompaniesByName',
  pure: false
})
export class SortCompaniesByNamePipe implements PipeTransform {


  transform(companiesWithGroupedMenus: {
    company_id: number,
    company_name: string,
    groupedMenus: {
      available_date: Date,
      menu_id: number,
      name: string,
      menu_count: number,
      vegetarian: boolean,
      low_carb:boolean,
      special: boolean
    }[]
  }[]):
    {
      company_id: number,
      company_name: string,
      groupedMenus: {
        available_date: Date,
        menu_id: number,
        name: string,
        menu_count: number,
        vegetarian: boolean,
        low_carb:boolean,
        special: boolean
      }[]
    }[] {
    return companiesWithGroupedMenus.sort((a, b) => a.company_name < b.company_name ? -1 : (a.company_name > b.company_name ? 1 : 0));
  }

}
