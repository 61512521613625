<mat-card class="body-wrapper" *ngIf="company">

  <mat-card-content>

    <app-return-button [path]="'/companies/'+company.id" [text]="company.name"></app-return-button>

    <h1>Bestellungen von {{company.name}}</h1>

    <div class="filter-pagination-wrapper">

      <mat-form-field appearance="fill">

        <mat-label>Filter</mat-label>

        <mat-icon matPrefix>filter_list</mat-icon>

        <mat-select [(ngModel)]="filter" (ngModelChange)="changeFilter()">
          <mat-option value="none">Kein Filter</mat-option>
          <mat-option value="canceled">Stornierte</mat-option>
          <mat-option value="unpaid">Nicht bezahlte</mat-option>
        </mat-select>

      </mat-form-field>

      <app-pagination [paginationInfo]="paginationInfo"></app-pagination>

    </div>

    <app-company-order-item *ngFor="let order of orders | async" [order]="order" [user]="order.user">
    </app-company-order-item>

    <app-pagination [paginationInfo]="paginationInfo"></app-pagination>

  </mat-card-content>

</mat-card>

<app-loading-animation [condition]="!company"></app-loading-animation>
