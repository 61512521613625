import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {OrderService} from '../../../services/order.service';
import {Order} from '../../../model/order.model';
import {take} from 'rxjs/operators';
import {MatDialog} from '@angular/material/dialog';
import {ConfirmationDialogComponent} from '../../../utils/confirmation-dialog/confirmation-dialog.component';

@Component({
  selector: 'app-order',
  templateUrl: './order.component.html',
  styleUrls: ['./order.component.scss']
})
export class OrderComponent implements OnInit {

  order: Order;
  page: number = 1;
  sum: number = 0;
  discount: number = 0;
  coupon_count: number = 0;

  constructor(private route: ActivatedRoute,
              private router: Router,
              private orderService: OrderService,
              private dialog: MatDialog) {
  }


  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.orderService.getOne(+params['order_id']).pipe(take(1)).subscribe((order: Order) => {
        this.order = order;

        this.order.order_menus.forEach(om => {
          this.sum += +om.menu.price;

          if (om.has_coupon) {
            this.discount += +this.order.coupon_discount;
            this.coupon_count++;
          }
        });

      });
    });

    this.page = +(this.route.snapshot.queryParamMap.get('page') ?? 1);
    this.router.navigate(
      [],
      {
      queryParams: {page: null},
      queryParamsHandling: 'merge'
      })
  }


  setPaid() {
    let dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: "Wollen Sie die Bestellung wirklich auf 'Bezahlt' setzen? Dieser Vorgang kann nicht rückgängig gemacht werden."
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result)
        this.orderService.setPaid(this.order.id).subscribe(() => this.order.paid = true);
    });
  }

}
