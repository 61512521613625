<mat-card class="body-wrapper">

  <mat-card-content>

    <app-return-button [path]="'/dashboard'" [text]="'Dashboard'"></app-return-button>
    <h1>Menüs</h1>
    <p>Ein Menü beschreibt immer einen bestimmten Tag und
      ein Gericht das an diesem Tag bestellbar sein soll.
      Es können pro Tag mehrere Menüs erstellt werden</p>

    <button mat-stroked-button color="primary" class="add-btn" (click)="add()">
      <mat-icon>add</mat-icon>
      Erstellen
    </button>

    <app-add-menu-fast></app-add-menu-fast>

    <app-week-pagination></app-week-pagination>

    <div *ngIf="menusByDate">
      <mat-card class="menus-group" *ngFor="let md of menusByDate | sortMenusByDate"
                [ngClass]="{'display-none':md.menus.length<0}">


        <mat-card-content>

          <h2 class="menu-title-span">Menü für den <strong>{{md.date | date:'dd. MMMM y'}}</strong></h2>

          <mat-list>
            <mat-list-item *ngFor="let menu of md.menus" class="menu-list-item">

              <div class="content">

                <img [src]="menu.image_url?url+menu.image_url:null">

                <div class="list-data-wrapper">
                  <div class="main-text">
                    {{menu.name}}
                    <mat-icon class="vegetarian-icon" *ngIf="menu.vegetarian">eco</mat-icon>
                    <mat-icon class="low-carb-icon" *ngIf="menu.low_carb">no_food</mat-icon>
                  </div>

                  <div class="secondary-text">
                    Bestellungen: {{menu.order_count}}
                  </div>
                </div>

                <div class="spacer"></div>

                <mat-icon matTooltip="Details" (click)="openMenuDetails(menu)">info</mat-icon>

                <mat-icon matTooltip="Menü löschen" (click)="remove(md,menu)">clear</mat-icon>

                <mat-divider></mat-divider>

              </div>

            </mat-list-item>
          </mat-list>

        </mat-card-content>

      </mat-card>
    </div>

  </mat-card-content>

</mat-card>
