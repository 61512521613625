<div class="table-responsive" *ngIf="gcostElements?.length>0 && gcostFooter">

  <table id="{{ 'groupedStatsTable-' + company_type.name }}">

    <thead>

    <tr>
      <th class="header" colSpan="100%">
        {{company_type.name}}
      </th>
    </tr>

    <tr>

      <th class="cutlery" rowspan="2">
        <span>Besteck?</span>
      </th>

      <th rowspan="2">
        Kunde
      </th>

      <th *ngFor="let dwm of gcostHeader.daysWithMenus" [colSpan]="dwm.menus.length">
        {{dwm.date|date:'EEEE, d. M.'}}
      </th>

    </tr>

    <tr>
      <ng-container *ngFor="let dwm of gcostHeader.daysWithMenus">

        <th class="menu"
            [ngClass]="{
            'vegetarian':m.vegetarian,
            'meat':!m.vegetarian,
            'low-carb':m.low_carb,
            'special':m.special,
            'last-menu':dwm.menus.length-1==i}"
            (click)="openMenuDetails(m)"
            [matTooltip]="m.name"
            *ngFor="let m of dwm.menus; let i=index">
          {{m.special ? "Special" : (m.vegetarian ? "Veggie" : "Fleisch")}}
        </th>

      </ng-container>
    </tr>

    </thead>

    <tbody>

    <tr *ngFor="let e of gcostElements | sortGcostElementsByCompanyName">

      <td class="cutlery">
        {{e.company.needs_cutlery ? 'B' : ''}}
      </td>

      <td class="company" [matTooltip]="e.company.domain" [routerLink]="['/companies',e.company.id]">
        {{e.company.name}}
      </td>

      <ng-container *ngFor="let dwm of e.daysWithMenus">

        <td class="menu"
            [ngClass]="{
            'vegetarian':m.menu.vegetarian,
            'meat':!m.menu.vegetarian,
            'low-carb':m.menu.low_carb,
            'special':m.menu.special,
            'last-menu':dwm.menus.length-1==i}"
            *ngFor="let m of dwm.menus;let i=index">
          {{m.count}}
        </td>

      </ng-container>

    </tr>

    </tbody>

    <tfoot>

    <tr>

      <td class="invisible"><!-- empty cell --></td>

      <td>
        Gesamt
      </td>

      <ng-container *ngFor="let dwm of gcostFooter.daysWithMenus">

        <td
          [ngClass]="{
          'vegetarian':m.menu.vegetarian,
          'meat':!m.menu.vegetarian,
          'low-carb':m.menu.low_carb,
          'special':m.menu.special,
          'last-menu':dwm.menus.length-1==i}"
          *ngFor="let m of dwm.menus;let i=index">
          {{m.count}}
        </td>

      </ng-container>

    </tr>

    <tr>

      <td class="invisible"><!-- empty cell --></td>

      <td class="invisible"><!-- empty cell --></td>

      <td *ngFor="let dwm of gcostFooter.daysWithMenus" [colSpan]="dwm.menus.length">
        {{dwm.menuCount}}
      </td>

    </tr>

    </tfoot>

  </table>

</div>
