<h1 mat-dialog-title>Benutzer-PDF herunterladen</h1>

<div mat-dialog-content>
  <table>

    <thead>
      <tr>
        <th>Vorname</th>
        <th>Nachname</th>
        <th>Benutzername</th>
        <th>Passwort</th>
        <th>Domän</th>
        <th>Firmenname</th>
      </tr>
    </thead>

    <tbody>
      <tr>
        <td>{{user.firstname}}</td>
        <td>{{user.lastname}}</td>
        <td>{{user.username}}</td>
        <td>{{password}}</td>
        <td>{{company?.domain}}</td>
        <td>{{company?.name}}</td>
      </tr>
    </tbody>

  </table>
</div>

<div mat-dialog-actions>

  <button mat-button (click)="cancel()">Abbrechen</button>

  <button mat-stroked-button (click)="download()" cdkFocusInitial>
    <mat-icon>get_app</mat-icon>Download PDF
  </button>

</div>
