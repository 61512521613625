import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import * as moment from 'moment';
import {take} from 'rxjs/operators';
import {CompanyType} from 'src/app/model/company-type.model';
import {CompanyTypeService} from 'src/app/services/company-type.service';
import {MenuService} from 'src/app/services/menu.service';
import {GroupedCompanyOrderStatsTableHeader} from './grouped-company-order-stats-table/grouped-company-order-stats-table-header.model';
import {Observable} from 'rxjs/internal/Observable';
import {GroupedCompanyOrderStatsTableSum} from './grouped-company-order-stats-table/grouped-company-order-stats-table-sum.model';
import {GroupedCompanyOrderStatsTableFooter} from './grouped-company-order-stats-table/grouped-company-order-stats-table-footer.model';
import {Menu} from '../../../model/menu.model';
import {MenuDetailsComponent} from '../../menus/menu-details/menu-details.component';
import {MatDialog} from '@angular/material/dialog';

@Component({
  selector: 'app-order-stats-more-details',
  templateUrl: './order-stats-more-details.component.html',
  styleUrls: ['./order-stats-more-details.component.scss']
})
export class OrderStatsMoreDetailsComponent implements OnInit {

  startDate: Date;
  endDate: Date;
  gcostHeader: GroupedCompanyOrderStatsTableHeader;
  gcostSum: GroupedCompanyOrderStatsTableSum;
  companyTypes$: Observable<CompanyType[]>;

  constructor(private route: ActivatedRoute,
              private menuService: MenuService,
              private companyTypeService: CompanyTypeService,
              private dialog: MatDialog) {
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.gcostHeader = null;

      if (moment(params.date, 'YYYY-M-D', true).isValid())
        this.startDate = moment(params.date, 'YYYY-M-D').toDate();

      this.endDate = moment(params.date, 'YYYY-M-D').toDate();
      this.endDate.setDate(this.endDate.getDate() + 3);

      this.menuService.getAllAsObservable(this.makeDateString(this.startDate), this.makeDateString(this.endDate), true)
        .pipe(take(1))
        .subscribe(menus => {
          this.gcostHeader = new GroupedCompanyOrderStatsTableHeader(this.startDate, this.endDate, menus);
          this.gcostSum = new GroupedCompanyOrderStatsTableSum(this.gcostHeader);
        });

    });
    this.companyTypes$ = this.companyTypeService.getAll();
  }

  addGcostFooterToSum(gcostFooter: GroupedCompanyOrderStatsTableFooter): void {
    this.gcostSum.addGcostFooter(gcostFooter);
  }

  openMenuDetails(menu: Menu): void {
    this.dialog.open(MenuDetailsComponent, {
      data: menu
    });
  }

  private makeDateString(date: Date): string {
    return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
  }
}
