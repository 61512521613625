import { Component, OnInit, Input } from '@angular/core';
import { User } from 'src/app/model/user.model';
import { Order } from 'src/app/model/order.model';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-company-order-item',
  templateUrl: './company-order-item.component.html',
  styleUrls: ['./company-order-item.component.scss']
})
export class CompanyOrderItemComponent implements OnInit {

  @Input('order')
  order: Order;

  @Input('user')
  user: User;

  sum: number = 0;
  discount: number = 0;

  constructor(private router: Router,
    private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.order.order_menus.forEach(om => {
      this.sum += +om.menu.price;
      if (om.has_coupon)
        this.discount += +this.order.coupon_discount;
    });
  }


  openOrderDetails() {
    this.router.navigate(['companies', this.user.company_id, 'orders', this.order.id], { state: { page: this.route.snapshot.queryParams['page'] } });
  }

}
