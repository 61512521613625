import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CancelationService {

  constructor(private http: HttpClient) { }


  create(order_id: number, message: string) {
    return this.http.post(environment.apiURL + `orders/${order_id}/cancelation`, { 'message': message });
  }

  accept(order_id: number) {
    return this.http.patch(environment.apiURL + `orders/${order_id}/cancelation/accept`, {});
  }

  reject(order_id: number) {
    return this.http.patch(environment.apiURL + `orders/${order_id}/cancelation/reject`, {});
  }

}
