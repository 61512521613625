import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { User } from '../../../../../model/user.model';
import { Company } from '../../../../../model/company.model';
import pdfMake from 'pdfmake/build/pdfmake';
import { CompanyService } from '../../../../../services/company.service';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-create-users-pdf',
  templateUrl: './create-users-pdf.component.html',
  styleUrls: ['./create-users-pdf.component.scss']
})
export class CreateUsersPdfComponent implements OnInit {

  usersCbx: { checked: boolean, user: User }[] = [];
  company: Company;


  constructor(private dialogRef: MatDialogRef<CreateUsersPdfComponent>,
    private companyService: CompanyService,
    @Inject(MAT_DIALOG_DATA) private company_id: number) {
    this.companyService.getOne(company_id, true).pipe(take(1)).subscribe((company: Company) => {
      this.company = company;
      company.users.forEach(user => {
        if (!user.activated)
          this.usersCbx.push({ checked: true, user: user });
      });
    });

  }

  ngOnInit(): void {
  }

  cancel() {
    this.dialogRef.close();
  }

  download() {
    var documentDefinition = { content: [] };
    this.usersCbx.forEach(userCbx => {
      if (!userCbx.checked) return;


      documentDefinition.content.push({
        table: {
          headerRows: 1,
          body: [
            [
              { text: 'Vorname', bold: true },
              { text: 'Nachname', bold: true },
              { text: 'Benutzername', bold: true },
              { text: 'Passwort', bold: true },
              { text: 'Domäne', bold: true },
              { text: 'Firmenname', bold: true }
            ],
            [
              userCbx.user.firstname,
              userCbx.user.lastname,
              userCbx.user.username,
              userCbx.user.generated_password,
              this.company.domain ?? "",
              this.company.name ?? ""
            ]
          ]
        },
        margin: [5, 2, 10, 20]
      });

    })
    pdfMake.createPdf(documentDefinition).download(`${this.company.name}_Zugangsdaten`);
  }

}
