import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'sortMenusSum',
  pure: false
})
export class SortMenusSumPipe implements PipeTransform {

  transform(menusSum: { available_date: Date, menu_id: number, name: string, menu_count: number, vegetarian: boolean, low_carb: boolean, special: boolean }[]): { available_date: Date, menu_id: number, name: string, menu_count: number, vegetarian: boolean, low_carb: boolean, special: boolean }[] {
    return menusSum.sort((a, b) => {
      if (a.available_date != b.available_date)
        return a.available_date < b.available_date ? -1 : (a.available_date > b.available_date ? 1 : 0);
      else
        return a.menu_id < b.menu_id ? -1 : (a.menu_id > b.menu_id ? +1 : 0);
    });
  }

}
