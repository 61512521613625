<table mat-table [dataSource]="order_menus">

  <ng-container matColumnDef="index">
    <th mat-header-cell *matHeaderCellDef> # </th>
    <td mat-cell *matCellDef="let i=index"> {{i+1}} </td>
  </ng-container>

  <ng-container matColumnDef="name">
    <th mat-header-cell *matHeaderCellDef> Name </th>
    <td mat-cell *matCellDef="let om"> {{om.menu.name}} </td>
  </ng-container>

  <ng-container matColumnDef="available_date">
    <th mat-header-cell *matHeaderCellDef> Datum </th>
    <td mat-cell *matCellDef="let om">{{om.menu.available_date |date:"dd.MM.yyyy"}} </td>
  </ng-container>

  <ng-container matColumnDef="has_coupon">
    <th mat-header-cell *matHeaderCellDef> Rabattmarke </th>
    <td mat-cell *matCellDef="let om">
      <mat-icon *ngIf="om.has_coupon">check</mat-icon>
      <mat-icon *ngIf="!om.has_coupon">not_interested</mat-icon>
    </td>
  </ng-container>

  <ng-container matColumnDef="price">
    <th mat-header-cell *matHeaderCellDef> Preis </th>
    <td mat-cell *matCellDef="let om"> € {{om.menu.price}} </td>
  </ng-container>

  <ng-container matColumnDef="details">
    <th mat-header-cell *matHeaderCellDef> </th>
    <td mat-cell *matCellDef="let om" class="align-right"> <button mat-stroked-button
        (click)="openMenuDetails(om.menu)">Details</button> </td>
  </ng-container>



  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns; let i=index"></tr>

</table>
