import {GroupedCompanyOrderStatsTableHeader} from './grouped-company-order-stats-table-header.model';
import {Menu} from '../../../../model/menu.model';
import * as moment from 'moment';
import {GroupedCompanyOrderStatsTableFooter} from './grouped-company-order-stats-table-footer.model';

// this model is for summing up all the menus of all the GroupedCompanyOrderStatsTables
export class GroupedCompanyOrderStatsTableSum {

  private _daysWithMenus: { date: Date, menuCount: number, menus: { menu: Menu, count: number }[] }[];

  public get daysWithMenus(): { date: Date, menuCount: number, menus: { menu: Menu, count: number }[] }[] {
    let returnDWM = this._daysWithMenus;

    // sort menus array of each day by id
    returnDWM.map(dwm => dwm.menus.sort((a, b) => a.menu.id < b.menu.id ? -1 : (a.menu.id > b.menu.id ? 1 : 0)));

    // sort days by date
    return returnDWM.sort((a, b) => a.date < b.date ? -1 : (a.date > b.date ? 1 : 0));
  }

  public get sumOfMenus(): number {
    return this._daysWithMenus.reduce((prev, dwm) => prev + dwm.menuCount, 0);
  }

  public get avgMenusPerDay(): number {
    return this.sumOfMenus / this._daysWithMenus.length;
  }

  constructor(private _gcostHeader: GroupedCompanyOrderStatsTableHeader) {
    this.createEmptyDayList();
  }


  public addGcostFooter(gcostFooter: GroupedCompanyOrderStatsTableFooter): void {

    this._daysWithMenus.map(dwm => {
      let foundDwm = gcostFooter.daysWithMenus.find(d => moment(d.date).isSame(dwm.date));

      foundDwm.menus.forEach(menu => {
        let foundMenu = dwm.menus.find(m => m.menu.id == menu.menu.id);
        foundMenu.count += menu.count;
      });

      dwm.menuCount += foundDwm.menuCount;

      return dwm;
    });
  }


  // will create the inital list with all the distinct menus in the week
  // will set the menu count to 0
  private createEmptyDayList(): void {
    this._daysWithMenus = [];

    this._gcostHeader.daysWithMenus.forEach(dwm => {
      let newDwm = {date: dwm.date, menuCount: 0, menus: []};

      dwm.menus.forEach(m => newDwm.menus.push({menu: m, count: 0}));

      this._daysWithMenus.push(newDwm);
    });

  }
}
