import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CancelationService } from '../../../services/cancelation.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Order } from '../../../model/order.model';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-show-cancel-request-order',
  templateUrl: './show-cancel-request-order.component.html',
  styleUrls: ['./show-cancel-request-order.component.scss']
})
export class ShowCancelRequestOrderComponent implements OnInit {

  constructor(private dialogRef: MatDialogRef<ShowCancelRequestOrderComponent>,
    private cancelationService: CancelationService,
    private snackbar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public order: Order) { }

  ngOnInit(): void {
  }

  close() {
    this.dialogRef.close(false);
  }

  cancel() {
    this.cancelationService.accept(this.order.id).pipe(take(1)).subscribe(() => {
      this.snackbar.open('Stornierung wurde akzeptiert', "Schließen", { duration: 5000 });
      window.location.reload();
      this.dialogRef.close(true);
    }, error => this.snackbar.open("Es ist ein Fehler aufgetreten", "Schließen", { duration: 10000, panelClass: ['warn-snackbar'] }));
  }

  reject() {
    this.cancelationService.reject(this.order.id).pipe(take(1)).subscribe(() => {
      this.snackbar.open('Stornierung wurde abgelehnt', "Schließen", { duration: 5000 });
      window.location.reload();
      this.dialogRef.close(true);
    }, error => this.snackbar.open("Es ist ein Fehler aufgetreten", "Schließen", { duration: 10000, panelClass: ['warn-snackbar'] }));
  }

}
