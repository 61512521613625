import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sortMenusSumByDate',
  pure: false
})
export class SortMenusSumByDatePipe implements PipeTransform {

  transform(menusSumByDate: { available_date: Date, menu_count: number, availableMenusCount: number }[]): { available_date: Date, menu_count: number, availableMenusCount: number }[] {
    return menusSumByDate.sort((a, b) => a.available_date < b.available_date ? -1 : (a.available_date > b.available_date ? 1 : 0));
  }
}
