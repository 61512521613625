import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {Order} from '../model/order.model';
import {PaginationInfo} from '../utils/pagination-info.model';
import {HttpClient} from '@angular/common/http';
import {take} from 'rxjs/operators';
import {environment} from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class OrderService {

  private _orders: BehaviorSubject<Order[]>;
  private _paginationInfo: BehaviorSubject<PaginationInfo>;

  get orders(): Observable<Order[]> {
    return this._orders.asObservable();
  }

  get paginationInfo(): Observable<PaginationInfo> {
    return this._paginationInfo.asObservable();
  }

  constructor(private http: HttpClient) {
    this._orders = new BehaviorSubject<Order[]>([]);
    this._paginationInfo = new BehaviorSubject<PaginationInfo>(null);
  }

  create(user_id: number, orderMenus: { menu_id: number, has_coupon: boolean }[]): Observable<Order> {
    return this.http.post<Order>(environment.apiURL + 'users/' + user_id + '/orders', {
      order_menus: orderMenus
    });
  }

  getAll(page: number = 1, count: number = 10, filter: string = null, order_id: number = null): any {
    this.http.get<{ 'pagination_info': PaginationInfo, 'orders': Order[] }>(environment.apiURL + `orders?page=${page}${filter ? '&filter=' + filter : ''}${order_id ? '&order_id=' + order_id : ''}`)
      .pipe(take(1)).subscribe(data => {
      this._orders.next(data.orders);
      this._paginationInfo.next(data.pagination_info);
    });
  }

  getOne(order_id: number): Observable<Order> {
    return this.http.get<Order>(environment.apiURL + `orders/${order_id}`);
  }

  getByCompany(company_id: number, page: number = 1, filter: string = null): any {
    this.http.get<{ 'pagination_info': PaginationInfo, 'orders': Order[] }>(environment.apiURL + `companies/${company_id}/orders?page=${page}${filter ? '&filter=' + filter : ''}`).pipe(take(1)).subscribe(data => {
      this._orders.next(data.orders);
      this._paginationInfo.next(data.pagination_info);
    });
  }

  getWithOpenCancelation(): Observable<Order[]> {
    return this.http.get<Order[]>(environment.apiURL + `orders/cancelation/open`);
  }

  setPaid(order_id: number): Observable<any> {
    return this.http.patch<any>(environment.apiURL + `orders/${order_id}/paid`, {});
  }

}
