import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './components/login/login.component';
import { CompaniesComponent } from './components/companies/companies.component';
import { UsersComponent } from './components/users/users.component';
import { AuthGuard } from './utils/auth.guard';
import { ActivateUserComponent } from './components/activate-user/activate-user.component';
import { DishesComponent } from './components/dishes/dishes.component';
import { CompanyDashboardComponent } from './components/companies/company-dashboard/company-dashboard.component';
import { AdminDashboardComponent } from './components/admin-dashboard/admin-dashboard.component';
import { CompanyAuthGuard } from './utils/company-auth.guard';
import { MenusComponent } from './components/menus/menus.component';
import { OrdersComponent } from './components/orders/orders.component';
import { OrderComponent } from './components/orders/order/order.component';
import { CompanyUsersComponent } from './components/companies/company-dashboard/company-users/company-users.component';
import { CompanyOrdersComponent } from './components/companies/company-dashboard/company-orders/company-orders.component';
import { CompanyOrderComponent } from './components/companies/company-dashboard/company-orders/company-order/company-order.component';
import { MessagesComponent } from './components/messages/messages.component';
import { OrderStatsComponent } from './components/order-stats/order-stats.component';
import { CouponViewComponent } from './components/coupon-view/coupon-view.component';
import { UsersOrderStatsComponent } from './components/users-order-stats/users-order-stats.component';
import { UsersOrderMonthlyStatsComponent } from './components/users-order-stats/users-order-monthly-stats/users-order-monthly-stats.component';
import { UsersOrderDailyStatsComponent } from './components/users-order-stats/users-order-daily-stats/users-order-daily-stats.component';
import { WeeklyUsersOrderStatsComponent } from './components/weekly-users-order-stats/weekly-users-order-stats.component';
import { OrderStatsLessDetailsComponent } from './components/order-stats/order-stats-less-details/order-stats-less-details.component';
import { OrderStatsMoreDetailsComponent } from './components/order-stats/order-stats-more-details/order-stats-more-details.component';

const routes: Routes = [
  { path: 'dashboard', canActivate: [AuthGuard], component: AdminDashboardComponent },
  { path: 'login', component: LoginComponent },
  { path: 'activate', component: ActivateUserComponent },
  {
    path: 'companies', children: [
      { path: '', component: CompaniesComponent, canActivate: [AuthGuard] },
      {
        path: ':company_id', canActivate: [CompanyAuthGuard], children: [
          { path: '', component: CompanyDashboardComponent },
          { path: 'users', component: CompanyUsersComponent },
          {
            path: 'orders', children: [
              { path: '', component: CompanyOrdersComponent },
              { path: ':order_id', component: CompanyOrderComponent }
            ]
          },
          { path: 'coupon-view', component: CouponViewComponent },
          {
            path: 'users-order-stats', children: [
              { path: 'weekly', component: WeeklyUsersOrderStatsComponent },
              {
                path: '', component: UsersOrderStatsComponent, children: [
                  { path: 'monthly', component: UsersOrderMonthlyStatsComponent },
                  { path: 'daily', component: UsersOrderDailyStatsComponent },
                  { path: '**', component: UsersOrderMonthlyStatsComponent },
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  {
    path: 'users', canActivate: [AuthGuard], children: [
      { path: '', component: UsersComponent },
    ]
  },
  {
    path: 'menus', canActivate: [AuthGuard], children: [
      { path: '', component: MenusComponent },
    ]
  },
  {
    path: 'orders', canActivate: [AuthGuard], children: [
      { path: '', component: OrdersComponent },
      { path: ':order_id', component: OrderComponent }
    ]
  },
  { path: 'dishes', component: DishesComponent, canActivate: [AuthGuard] },
  {
    path: 'order-stats', component: OrderStatsComponent, canActivate: [AuthGuard], children: [
      { path: '', component: OrderStatsLessDetailsComponent },
      { path: 'details', component: OrderStatsMoreDetailsComponent }
    ]
  },
  {
    path: 'messages', canActivate: [AuthGuard], children: [
      { path: '', component: MessagesComponent },
    ]
  },
  { path: '**', redirectTo: 'dashboard', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
