<h1 mat-dialog-title>Gericht bearbeiten</h1>

<div mat-dialog-content>

  <form [formGroup]="editDishForm">

    <mat-form-field>
      <mat-label>Name</mat-label>
      <input matInput type="text" formControlName="name">
      <mat-error *ngIf="editDishForm.get('name').hasError('required')">Ein Name ist erforderlich</mat-error>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Preis</mat-label>
      <input type="number" matInput formControlName="price">
      <mat-hint>Wert in €</mat-hint>
      <mat-error *ngIf="editDishForm.get('price').hasError('required')">Die Preis ist erforderlich</mat-error>
      <mat-error *ngIf="editDishForm.get('price').hasError('min')">Der Preis darf nicht unter 0 sein</mat-error>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Beschreibung</mat-label>
      <textarea type="text" matInput formControlName="description"></textarea>
    </mat-form-field>

    <mat-form-field *ngIf="dish.image_url && !image">
      <input matInput type="text" style="display: none;">
      <label for="image" class="select-image-btn">
        <img [src]="url+dish.image_url"> Anzeigebild<span *ngIf='!image'> ändern</span><span *ngIf='image'>:
        {{image.name}}</span>
      </label>
      <input type="file" style="visibility:hidden;" id="image" data-buttonText="Bild auswählen"
             (change)="imageUpload($event)">
    </mat-form-field>

    <mat-form-field *ngIf="!dish.image_url ||image">
      <input matInput type="text" style="display: none;">
      <label for="image" class="select-image-btn">
        <mat-icon>insert_photo</mat-icon>
        Anzeigebild<span *ngIf='!image'> auswählen</span><span *ngIf='image'>:
        {{image.name}}</span>
      </label>
      <input type="file" style="visibility:hidden;" id="image" data-buttonText="Bild auswählen"
             (change)="imageUpload($event)">
    </mat-form-field>

    <mat-checkbox class="vegetarian-cbx" formControlName="vegetarian">Gericht ist vegetarisch</mat-checkbox>

    <mat-checkbox class="vegetarian-cbx" formControlName="special">Special</mat-checkbox>

    <mat-checkbox class="vegetarian-cbx" formControlName="low_carb">Low Carb</mat-checkbox>
  </form>

  <h3>Allergene auswählen</h3>

  <section class="allergen-cbx" *ngIf="allergensCbx">
    <mat-checkbox *ngFor="let a of allergensCbx" [(ngModel)]="a.checked">{{a.allergen.name}}</mat-checkbox>
  </section>

</div>

<div mat-dialog-actions>

  <button mat-button (click)="cancel()">Abbrechen</button>
  <button mat-stroked-button (click)="edit()" [disabled]="editDishForm.invalid" cdkFocusInitial>Bearbeiten</button>

</div>
