import { Component, OnInit, Inject, ElementRef, ViewChild } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UserService } from 'src/app/services/user.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Company } from '../../../../../model/company.model';
import { take } from 'rxjs/operators';
import { User } from 'src/app/model/user.model';
import { CreateUserPdfComponent } from 'src/app/components/users/create-user-pdf/create-user-pdf.component';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-add-company-user',
  templateUrl: './add-company-user.component.html',
  styleUrls: ['./add-company-user.component.scss']
})
export class AddCompanyUserComponent implements OnInit {

  addCompanyUserForm: UntypedFormGroup = new UntypedFormGroup({
    firstname: new UntypedFormControl('', [Validators.required]),
    lastname: new UntypedFormControl('', [Validators.required]),
    password: new UntypedFormControl(this.makePassword(8), [Validators.required, Validators.minLength(6)]),
    role: new UntypedFormControl('NORMAL', [Validators.required])
  });

  stayOpen: boolean = false;
  @ViewChild('firstname') firstnameInput: ElementRef;

  constructor(private dialogRef: MatDialogRef<AddCompanyUserComponent>,
    private userService: UserService,
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
    private route:ActivatedRoute,
    @Inject(MAT_DIALOG_DATA) public company: Company) { }

  ngOnInit(): void {
  }

  private makePassword(length: number) {
    var result = '';
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  cancel() {
    this.dialogRef.close(false);
  }

  add() {
    this.userService.registerCompanyUser(
      this.addCompanyUserForm.get("firstname").value,
      this.addCompanyUserForm.get("lastname").value,
      this.addCompanyUserForm.get("password").value,
      this.addCompanyUserForm.get("role").value,
      this.company.id).pipe(take(1)).subscribe(
        (user: User) => {
          this.snackBar.open(`Der Benutzer ${user.firstname} ${user.lastname} wurde erstellt`, "Schließen", { duration: 3000 });
          this.userService.getUsersByCompany(this.company.id,+(this.route.snapshot.queryParams['page']??1));
          if (this.stayOpen) {
            this.addCompanyUserForm.get("firstname").setValue("");
            this.addCompanyUserForm.get("lastname").setValue("");
            this.addCompanyUserForm.get("password").setValue(this.makePassword(8));
            this.firstnameInput.nativeElement.focus();
          } else
            this.dialogRef.close(true);
        },
        error => this.snackBar.open("Es ist ein Fehler aufgetreten", "Schließen", { duration: 10000, panelClass: ['warn-snackbar'] })
      )
  }

}
