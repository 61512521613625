import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Order } from '../../../model/order.model';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { CancelationService } from '../../../services/cancelation.service';
import { take } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { OrderService } from 'src/app/services/order.service';


@Component({
  selector: 'app-cancel-order',
  templateUrl: './cancel-order.component.html',
  styleUrls: ['./cancel-order.component.scss']
})
export class CancelOrderComponent implements OnInit {

  addCancelationForm: UntypedFormGroup = new UntypedFormGroup({
    message: new UntypedFormControl('', [Validators.required])
  });

  constructor(private dialogRef: MatDialogRef<CancelOrderComponent>,
    @Inject(MAT_DIALOG_DATA) public order: Order,
    private cancelationService: CancelationService,
    private snackbar: MatSnackBar,
    private route: ActivatedRoute,
    private orderService: OrderService) { }

  ngOnInit(): void {
  }

  cancel() {
    this.dialogRef.close();
  }

  add() {
    this.cancelationService.create(this.order.id, this.addCancelationForm.get('message').value).pipe(take(1)).subscribe(() => {

      this.snackbar.open("Die Bestellung wurde stoniert und der Benutzer benachrichtigt", "Schließen", { duration: 5000 });
      this.orderService.getAll(!isNaN(+this.route.snapshot.queryParams['page']) ? +this.route.snapshot.queryParams['page'] : 1);
      this.dialogRef.close();

    },
      error => this.snackbar.open("Es ist ein Fehler aufgetreten", "Schließen", { duration: 10000, panelClass: ['warn-snackbar'] })
    );
  }

}

