<h1 mat-dialog-title>Benutzer erstellen</h1>

<div mat-dialog-content>

  <form [formGroup]="addUserForm">

    <mat-form-field>
      <mat-label>Vorname</mat-label>
      <input matInput type="text" formControlName="firstname">
      <mat-error *ngIf="addUserForm.get('firstname').hasError('required')">Ein Vorname ist erforderlich</mat-error>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Nachname</mat-label>
      <input type="text" matInput formControlName="lastname">
      <mat-error *ngIf="addUserForm.get('lastname').hasError('required')">Ein Nachname ist erforderlich</mat-error>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Passwort</mat-label>
      <input type="text" matInput formControlName="password">
      <mat-hint>Das Passwort muss beim ersten Anmelden geändert werden</mat-hint>
      <mat-error *ngIf="addUserForm.get('password').hasError('required')">Ein Passwort ist erforderlich</mat-error>
      <mat-error *ngIf="addUserForm.get('password').hasError('minlength')">Das Passwort muss mindestens 6 Zeichen lang
        sein</mat-error>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Benutzer-Art</mat-label>
      <mat-select formControlName="role">
        <mat-option value="NORMAL">Normal</mat-option>
        <mat-option value="COMPANY">Firmenadmin</mat-option>
        <mat-option value="ADMIN">Administrator</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Firma</mat-label>
      <input type="text" aria-label="Number" matInput formControlName="company" [matAutocomplete]="auto">
      <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
        <mat-option *ngFor="let company of companyOptions | async" [value]="company">
          {{company.name}}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>

  </form>

</div>

<div mat-dialog-actions>

  <button mat-button (click)="cancel()">Abbrechen</button>

  <!-- needs this much validation to ensure no COMPANY or NORMAL User is created without company_id -->
  <!-- and that it's not possible to submit a faulty company -->
  <button mat-stroked-button (click)="add()" [disabled]="addUserForm.invalid
  || (addUserForm.get('role').value!='ADMIN'&&addUserForm.get('company').value=='')
  || !(addUserForm.get('company').value==''||isObject(addUserForm.get('company').value))"
    cdkFocusInitial>Erstellen</button>

</div>
