import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { take } from 'rxjs/operators';
import { User } from 'src/app/model/user.model';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-users-order-daily-stats',
  templateUrl: './users-order-daily-stats.component.html',
  styleUrls: ['./users-order-daily-stats.component.scss']
})
export class UsersOrderDailyStatsComponent implements OnInit {

  startDate: Date;
  endDate: Date;
  usersByDate: { date: Date, users: User[] }[];

  constructor(private route: ActivatedRoute,
    private userService: UserService) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.usersByDate = null;
      let currentDate: Date = new Date;
      let month: number = +(params['month'] ?? currentDate.getMonth());
      let year: number = +(params['year'] ?? currentDate.getFullYear());

      this.startDate = new Date(year, month, 1);
      this.endDate = new Date(year, month + 1, 0);

      this.userService.getUsersByCompanyWithMenuCountPerDay(+this.route.parent.snapshot.paramMap.get('company_id'), this.makeDateString(this.startDate), this.makeDateString(this.endDate)).pipe(take(1)).subscribe(
        (users) => {
          this.usersByDate = [];

          users.forEach(user => {
            let found = false;
            this.usersByDate.forEach(ubd => {
              if (ubd.date == user.available_date)
                found = true;
            })
            if (found) {
              let updateUbd = this.usersByDate.find(ubd => ubd.date == user.available_date);
              let index = this.usersByDate.indexOf(updateUbd);

              updateUbd.users.push(user);
              this.usersByDate[index] = updateUbd;
            } else {
              this.usersByDate.push({ date: user.available_date, users: [user] });
            }
          });
        });
    });
  }


  total(users: User[]): number {
    return users.reduce((a, b) => a + (b.menu_count || 0), 0);
  }

  private makeDateString(date: Date): string {
    return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`
  }

}
