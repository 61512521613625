<mat-card class="body-wrapper">

  <mat-card-content>

    <app-return-button [path]="'/dashboard'" [text]="'Dashboard'"></app-return-button>
    <h1>Gerichte</h1>

    <button mat-stroked-button color="primary" (click)="add()">
      <mat-icon>add</mat-icon> Erstellen
    </button>

    <mat-form-field class="search" appearance="fill">
      <mat-label>Suchen</mat-label>
      <mat-icon matPrefix>search</mat-icon>
      <input type="text" matInput [(ngModel)]="searchDish">
      <button *ngIf="searchDish" matSuffix mat-icon-button aria-label="Clear" (click)="searchDish=''">
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>

    <mat-list>
      <mat-list-item *ngFor="let dish of dishes|async|searchDish:searchDish" class="dish-list-item">

        <div class="content">

          <img [src]="dish.image_url?url+dish.image_url:null">

          <div class="list-data-wrapper">

            <div class="main-text">
              {{dish.name}}
              <mat-icon class="vegetarian-icon" *ngIf="dish.vegetarian">eco</mat-icon>
              <mat-icon class="low-carb-icon" *ngIf="dish.low_carb">no_food</mat-icon>
            </div>

            <div class="secondary-text">
              €{{dish.price}}
            </div>

          </div>

          <div class="spacer"></div>

          <mat-icon matTooltip="Gericht bearbeiten" (click)="edit(dish)">edit</mat-icon>

          <mat-icon matTooltip="Gericht löschen" (click)="remove(dish)">clear</mat-icon>

          <mat-divider></mat-divider>

        </div>

      </mat-list-item>
    </mat-list>

  </mat-card-content>

</mat-card>
