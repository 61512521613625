import { Pipe, PipeTransform } from '@angular/core';
import { Order } from '../../model/order.model';

@Pipe({
  name: 'hideWithOpenCancelation',
  pure: false
})
export class HideWithOpenCancelationPipe implements PipeTransform {

  transform(orders: Order[]): Order[] {
    return orders.filter(order => !(order.cancelation && !order.cancelation.accepted && !order.cancelation.rejected));
  }

}
