<mat-card class="body-wrapper" *ngIf="company">

  <mat-card-content>

    <app-return-button [path]="'/companies'" [text]="'Firmen'" *ngIf="currentUser.role.toString()=='ADMIN'">
    </app-return-button>

    <h1>{{company.name}}</h1>

    <button mat-stroked-button color="primary" (click)="edit()">
      <mat-icon>edit</mat-icon> Bearbeiten
    </button>

    <div class="company-data">
      <table>
        <tbody>

        <tr>
          <td><strong>Mitarbeiter</strong></td>
          <td class="detail">{{company.user_count}}</td>
        </tr>

        <tr>
          <td><strong>Domaine</strong></td>
          <td class="detail">{{company.domain}}</td>
        </tr>

        <tr>
          <td><strong>Lieferart</strong></td>
          <td class="detail">{{company.company_type.name}}</td>
        </tr>

        <tr>
          <td><strong>Besteck</strong></td>
          <td class="detail">{{company.needs_cutlery?'Ja':'Nein'}}</td>
        </tr>

        <tr *ngIf="company.uses_coupons">
          <td><strong>Coupon Wert</strong></td>
          <td class="detail">€ {{company.coupon_discount}}</td>
        </tr>

        <tr *ngIf="company.max_coupons_week">
          <td><strong>Essensmarken/Woche</strong></td>
          <td class="detail">{{company.max_coupons_week}}</td>
        </tr>

        </tbody>
      </table>
    </div>

    <div class="dashboard-wrapper">

      <mat-card routerLink="users">
        <mat-card-header>
          <mat-icon mat-card-avatar>group</mat-icon>
          <mat-card-title>Benutzer</mat-card-title>
          <mat-card-subtitle>Übersicht aller Benutzer</mat-card-subtitle>
        </mat-card-header>
      </mat-card>

      <mat-card routerLink="orders">
        <mat-card-header>
          <mat-icon mat-card-avatar>shopping_basket</mat-icon>
          <mat-card-title>Bestellungen</mat-card-title>
          <mat-card-subtitle>Alle Bestellungen <br><br></mat-card-subtitle>
        </mat-card-header>
      </mat-card>

      <mat-card routerLink="coupon-view" [queryParams]="{'date':startDateString}" *ngIf="company.uses_coupons">
        <mat-card-header>
          <mat-icon mat-card-avatar>verified</mat-icon>
          <mat-card-title>Couponliste</mat-card-title>
          <mat-card-subtitle>Alle Mitarbeiter mit Coupons pro Woche</mat-card-subtitle>
        </mat-card-header>
      </mat-card>

      <mat-card routerLink="users-order-stats/monthly" [queryParams]="{'month':date.getMonth(),'year':date.getFullYear()}"
                *ngIf="!company.uses_coupons">
        <mat-card-header>
          <mat-icon mat-card-avatar>table_chart</mat-icon>
          <mat-card-title>Bestellstatistik</mat-card-title>
          <mat-card-subtitle>Bestellungen der Mitarbeiter im Monat</mat-card-subtitle>
        </mat-card-header>
      </mat-card>

      <mat-card routerLink="users-order-stats/weekly" [queryParams]="{'date':startDateString}">
        <mat-card-header>
          <mat-icon mat-card-avatar>analytics</mat-icon>
          <mat-card-title>Bestellübersicht der Mitarbeiter</mat-card-title>
        </mat-card-header>
      </mat-card>

    </div>

  </mat-card-content>

</mat-card>

<app-loading-animation [condition]="!company"></app-loading-animation>
