import { Component, OnInit } from '@angular/core';
import { Company } from 'src/app/model/company.model';
import { CompanyService } from '../../../../services/company.service';
import { ActivatedRoute, Router } from '@angular/router';
import { take } from 'rxjs/operators';
import { User } from 'src/app/model/user.model';
import { UserService } from '../../../../services/user.service';
import { MatDialog } from '@angular/material/dialog';
import { EditUserComponent } from '../../../users/edit-user/edit-user.component';
import { ConfirmationDialogComponent } from '../../../../utils/confirmation-dialog/confirmation-dialog.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ResetUserPasswordComponent } from '../../../users/reset-user-password/reset-user-password.component';
import { CreateUserPdfComponent } from '../../../users/create-user-pdf/create-user-pdf.component';
import { AddCompanyUserComponent } from './add-company-user/add-company-user.component';
import { CreateUsersPdfComponent } from './create-users-pdf/create-users-pdf.component';
import { Observable } from 'rxjs';
import { PaginationInfo } from '../../../../utils/pagination-info.model';


@Component({
  selector: 'app-company-users',
  templateUrl: './company-users.component.html',
  styleUrls: ['./company-users.component.scss']
})
export class CompanyUsersComponent implements OnInit {

  currentUser: User;
  company: Company;
  users: Observable<User[]>;
  private _users: User[];
  paginationInfo: PaginationInfo;
  searchUser:string;

  constructor(private companyService: CompanyService,
    private route: ActivatedRoute,
    private userService: UserService,
    private dialog: MatDialog,
    private snackbar: MatSnackBar,
    private router:Router) { }

  ngOnInit(): void {
    this.route.params.subscribe(params => this.companyService.getOne(+params['company_id']).pipe(take(1)).subscribe((company: Company) => this.company = company));
    this.userService.user.subscribe(user => this.currentUser = user);

    this.users = this.userService.users;
    this.userService.user.subscribe(user => this.currentUser = user);
    this.userService.paginationInfo.subscribe(pi => this.paginationInfo = pi);

    this.route.queryParams.subscribe(params => {
      this.userService.getUsersByCompany(+this.route.snapshot.params['company_id'], +(params['page'] ?? 1));
    });
  }

  search() {
    if (this.searchUser && this.searchUser.length > 2) {
      this.userService.getUsersByCompany(+this.route.snapshot.params['company_id'],1, 20, this.searchUser);
      this.router.navigate(
        [],
        {
          relativeTo: this.route,
          queryParams: { 'page': 1 },
          queryParamsHandling: 'merge'
        });
    }
    if (!this.searchUser || this.searchUser.length == 0) {
      this.userService.getUsersByCompany(+this.route.snapshot.params['company_id'],1);
      this.router.navigate(
        [],
        {
          relativeTo: this.route,
          queryParams: { 'page': 1 },
          queryParamsHandling: 'merge'
        });
    }
  }

  add() {
    const dialogRef = this.dialog.open(AddCompanyUserComponent, {
      width: "600px",
      data: this.company
    });

    dialogRef.afterClosed().subscribe(result => {
      this.reload();
    });
  }

  edit(user: User) {
    const dialogRef = this.dialog.open(EditUserComponent, {
      width: "600px",
      data: user
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result)
        this.userService.getUsersByCompany(this.company.id, +(this.route.snapshot.queryParams["page"] ?? 1))
    });
  }

  remove(user: User) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: "350px",
      data: `Wollen Sie den Benutzer "${user.firstname} ${user.lastname}" und alle seine Daten wirklich löschen?`
    });
    dialogRef.afterClosed().subscribe(result => {
      if (!result) return;

      this.userService.delete(user.id).subscribe(() => {
        this.userService.getUsersByCompany(this.company.id,+(this.route.snapshot.queryParams['page']??1));        
        this.snackbar.open(`Der Benutzer "${user.firstname} ${user.lastname}" wurde gelöscht`, "Schließen", { duration: 3000 });
        this.reload();
      }, error =>
        this.snackbar.open("Beim Löschen ist ein Fehler aufgetreten", "Schließen", { duration: 10000, panelClass: ['warn-snackbar'] })
      );
    });
  }

  changePassword(user: User) {
    this.dialog.open(ResetUserPasswordComponent, {
      width: '600px',
      data: user
    });
  }

  createUserPdf(user: User) {
    this.dialog.open(CreateUserPdfComponent, {
      data: {
        "user": user,
        "password": user.generated_password,
        "company": user.company
      }
    });
  }

  reload() {
    this.companyService.getOne(+this.route.snapshot.params['company_id']).pipe(take(1)).subscribe((company: Company) => this.company = company);
  }

  createUsersPDF() {
    this.dialog.open(CreateUsersPdfComponent, {
      data: this.company.id
    })
  }

}
