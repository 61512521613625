import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from 'src/app/services/user.service';
import { take, map } from 'rxjs/operators';
import { ERole } from 'src/app/model/user.model';
import { User } from '../../model/user.model';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-activate-user',
  templateUrl: './activate-user.component.html',
  styleUrls: ['./activate-user.component.scss']
})
export class ActivateUserComponent implements OnInit {

  user: User;

  activateUserForm: UntypedFormGroup = new UntypedFormGroup({
    "old_password": new UntypedFormControl('', [Validators.required, Validators.minLength(6)]),
    "password": new UntypedFormControl('', [Validators.required, Validators.minLength(6)]),
    "password_confirmation": new UntypedFormControl('', [Validators.required, Validators.minLength(6)])
  });

  constructor(private router: Router,
    private userService: UserService,
    private snackBar: MatSnackBar) { }

  ngOnInit(): void {
    this.userService.user.subscribe(user => {
      if (!user) {
        this.router.navigate(['login']);
        return;
      }
      if (user.activated) {
        this.router.navigate(['dashboard']);
        return;
      }
      this.user = user;
    });
  }

  activate() {
    this.userService.activate(
      this.activateUserForm.get('old_password').value,
      this.activateUserForm.get('password').value,
      this.activateUserForm.get('password_confirmation').value).pipe(take(1)).subscribe(() => {

        this.snackBar.open(`Der Benutzer ${this.user.firstname} ${this.user.lastname} wurde erfolgreich aktiviert`, 'Schließen', { duration: 5000 });
        this.userService.logout();

      }, error => this.snackBar.open(error.error.error, "Schließen", { duration: 10000, panelClass: ['warn-snackbar'] }));
  }

}
