<mat-card class="body-wrapper" *ngIf="company">

  <app-return-button [path]="'/companies/'+company.id" [text]="company.name"></app-return-button>

  <h1>Bestellübersicht der Mitarbeiter <span matTooltipPosition="above"
                                                 [matTooltip]="(startDate|date:'dd. MM. yyyy')+' – '+(endDate|date:'dd. MM. yyyy')">für Kalenderwoche
    {{getWeekNumber()}}</span> von {{endDate.getFullYear()}}</h1>

  <app-week-pagination-buttons></app-week-pagination-buttons>

  <h3 *ngIf="wuosElements?.length==0">In dieser Woche wurden noch
    keine Menüs bestellt</h3>

  <div class="table-responsive">
    <table *ngIf="wuosElements?.length>0">

      <thead>
      <tr>

        <th>Name</th>

        <th *ngFor="let d of wuosElements[0].days">
          <div class="date">{{d.date |date:'EEEE, d. M.'}}</div>
        </th>

        <th>Gesamt</th>

      </tr>
      </thead>

      <tbody>
      <tr *ngFor="let wuos of wuosElements | sortWeeklyUsersOrderStats">

        <td [matTooltip]="wuos.user.username">
          {{wuos.user.firstname}} {{wuos.user.lastname}}
        </td>

        <td *ngFor="let d of wuos.days" class="menu-count">

          <span *ngIf="d.meatCount>0"><b>{{d.meatCount}}</b> x Fleisch</span>
          <br>
          <span *ngIf="d.vegCount>0"><b>{{d.vegCount}}</b> x Veggie</span>
          <br>
          <span *ngIf="d.specialCount>0"><b>{{d.specialCount}}</b> x Special</span>
          <br>
          <span *ngIf="d.lowCarbCount>0"><b>{{d.lowCarbCount}}</b> x Low Carb</span>

        </td>

        <td class="menu-count">
          <span *ngIf="wuos.menuCount>0"><b>{{wuos.menuCount}}</b> x</span>
        </td>

      </tr>
      </tbody>

      <tfoot>
      <tr>

        <td class="total">Gesamt</td>

        <td *ngFor="let d of wuosElements[0].days" class="menu-sum">
          <span><b>{{sumMenusOnDay(d.date)}}</b> x am {{d.date|date:'EE'}}</span>
        </td>

        <td class="menu-sum">
          <span><b>{{sumAllMenus()}}</b> x in KW {{getWeekNumber()}}</span>
        </td>

      </tr>
      </tfoot>

    </table>
  </div>

</mat-card>
<app-loading-animation [condition]="!company || !wuosElements"></app-loading-animation>
