<h1 mat-dialog-title>Benachrichtigung erstellen</h1>
<div mat-dialog-content>
    <form [formGroup]="addMessageForm">

        <mat-form-field>
            <mat-label>Titel</mat-label>
            <input matInput type="text" formControlName="subject">
            <mat-error *ngIf="addMessageForm.get('subject').hasError('required')">Ein Titel ist erforderlich</mat-error>
        </mat-form-field>

        <mat-form-field>
            <mat-label>Nachricht</mat-label>
            <textarea type="text" matInput formControlName="message"></textarea>
            <mat-error *ngIf="addMessageForm.get('message').hasError('required')">Eine Nachricht ist erforderlich
            </mat-error>
        </mat-form-field>

        <mat-radio-group formControlName="reciever">
            <mat-radio-button value="all">An Alle schicken</mat-radio-button>
            <mat-radio-button value="company">An bestimmte Firma schicken</mat-radio-button>
            <mat-radio-button value="user">An bestimmten Benutzer schicken</mat-radio-button>
        </mat-radio-group>

        <mat-form-field *ngIf="addMessageForm.get('reciever').value=='company'">
            <mat-label>Firma</mat-label>
            <input type="text" aria-label="Number" matInput formControlName="company" [matAutocomplete]="autoCompany">
            <mat-autocomplete #autoCompany="matAutocomplete" [displayWith]="displayFnCompany">
                <mat-option *ngFor="let company of companyOptions | async" [value]="company">
                    {{company.name}}
                </mat-option>
            </mat-autocomplete>
        </mat-form-field>

        <mat-form-field *ngIf="addMessageForm.get('reciever').value=='user'">
            <mat-label>Benutzer</mat-label>
            <input type="text" aria-label="Number" matInput formControlName="user" [matAutocomplete]="autoUser">
            <mat-autocomplete #autoUser="matAutocomplete" [displayWith]="displayFnUser">
                <mat-option *ngFor="let user of userOptions | async | filterUserWithoutApp" [value]="user.user">
                    {{user.name}}
                </mat-option>
            </mat-autocomplete>
        </mat-form-field>

        <div style="min-height: 50px;" *ngIf="addMessageForm.get('reciever').value=='all'"></div>

        <mat-radio-group formControlName="sendNow">
            <mat-radio-button [value]="true">Sofort abschicken</mat-radio-button>
            <mat-radio-button [value]="false">Zeitpunkt auswählen</mat-radio-button>
        </mat-radio-group>

        <mat-form-field *ngIf="!addMessageForm.get('sendNow').value">
            <input matInput [ngxMatDatetimePicker]="picker" (focus)="picker.open()"
                placeholder="Datum und Zeit auswählen" formControlName="send_time" [min]="date_now">
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <ngx-mat-datetime-picker #picker>
            </ngx-mat-datetime-picker>
        </mat-form-field>
        
    </form>
</div>
<div mat-dialog-actions>
    <button mat-button (click)="cancel()">Abbrechen</button>
    <button mat-stroked-button (click)="add()"
        [disabled]="addMessageForm.invalid || 
        (addMessageForm.get('reciever').value=='user'&&!isObject(addMessageForm.get('user').value)) || 
        (addMessageForm.get('reciever').value=='company'&&!isObject(addMessageForm.get('company').value))||
        (!addMessageForm.get('sendNow').value && (!isDate(addMessageForm.get('send_time').value)|| !addMessageForm.get('send_time').value))"
        cdkFocusInitial>Abschicken</button>
</div>