<h1 mat-dialog-title>Menü erstellen</h1>
<div mat-dialog-content>
  <mat-form-field>
    <mat-label>Datum des Menüs</mat-label>
    <input matInput (focus)="picker.open()" [formControl]="availableDateFormControl" [matDatepicker]="picker" [matDatepickerFilter]="dayFilter">
    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-datepicker #picker></mat-datepicker>
  </mat-form-field>
  <h3>Gerichte auswählen</h3>
  <mat-form-field appearance="fill" class="full-width">
    <mat-label>Suchen</mat-label>
    <mat-icon matPrefix>search</mat-icon>
    <input type="text" matInput [(ngModel)]="searchDish">
    <button *ngIf="searchDish" matSuffix mat-icon-button aria-label="Clear" (click)="searchDish=''">
      <mat-icon>close</mat-icon>
    </button>
  </mat-form-field>
  <mat-divider *ngIf="dishesList.options?.length>0"></mat-divider>
  <mat-selection-list #dishesList [(ngModel)]="selectedDishes">
    <mat-list-option *ngFor="let dish of dishes|async|searchDishes:searchDish" [value]="dish.id">
      <div class="dish-details">
        <img [src]="dish.image_url?url+dish.image_url:null">
        <div class="list-data-wrapper">
          <div class="main-text">
            {{dish.name}}<mat-icon class="vegetarian-icon" *ngIf="dish.vegetarian">eco</mat-icon>
          </div>
          <div class="secondary-text">
            €{{dish.price}}
          </div>
        </div>
      </div>
    </mat-list-option>
    <button mat-stroked-button color="primary" class="add-btn" (click)="addDish()">
      <mat-icon>add</mat-icon> Neues Gericht hinzufügen
    </button>
  </mat-selection-list>
  <mat-divider *ngIf="dishesList.options?.length>0"></mat-divider>
</div>
<div mat-dialog-actions>
  <button mat-button [mat-dialog-close]="false">Abbrechen</button>
  <button mat-stroked-button (click)="add()" cdkFocusInitial
    [disabled]="availableDateFormControl.invalid||selectedDishes.length<1">Erstellen</button>
</div>
