import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { UserService } from '../services/user.service';
import { take, map } from 'rxjs/operators';
import { ERole, User } from '../model/user.model';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard  {

  constructor(private userService: UserService,
    private router: Router) {
  }


  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.userService.user.pipe(take(1), map((user:User) => {
      if (user&&user.activated && user.role.toString() == "ADMIN"){
        return true;
      }
      else if (user && user.activated && user.role.toString() === "COMPANY") {
        this.router.navigate(['/companies', user.company_id]);
        return false;
      } else {
        this.router.navigate(['login']);
        return false;
      }
    }));
  }

}
