import {Menu} from '../../../../model/menu.model';
import * as moment from 'moment';

// this model is for grouping and sorting a given list of menus to the header of the GroupedCompanyOrderStatsTableComponent
export class GroupedCompanyOrderStatsTableHeader {

  private _daysWithMenus: { date: Date, menus: Menu[] }[];

  public get daysWithMenus(): { date: Date, menus: Menu[] }[] {
    let returnDWM = this._daysWithMenus;

    // sort menus array of each day by id
    returnDWM.map(dwm => dwm.menus.sort((a, b) => a.id < b.id ? -1 : (a.id > b.id ? 1 : 0)));


    // make sure to only return a day if there is a menu
    // and them by date
    return returnDWM.filter(dwm => dwm.menus.length > 0).sort((a, b) => a.date < b.date ? -1 : (a.date > b.date ? 1 : 0));
  }

  public get startDate(): Date {
    return this._startDate;
  }

  public get endDate(): Date {
    return this._endDate;
  }

  constructor(
    private _startDate: Date,
    private _endDate: Date,
    private _menus: Menu[]) {
    this.groupMenus();
  }

  public menuCount(): number {
    return this._daysWithMenus.reduce<number>((menuCount, dwm) => menuCount + dwm.menus.length, 0);
  }


  private groupMenus(): void {
    this.createEmptyDayList();

    // add all menus to matching day
    this._menus.forEach(m => {

      let dwm = this._daysWithMenus.find(dwm => moment(m.available_date).isSame(dwm.date));

      dwm.menus.push(m);
    });
  }

  // will create the initial empty day array of type { date: Date, meatCount: number, vegCount: number }[]
  // and will initialize meatCount and vegCount with 0
  private createEmptyDayList(): void {

    this._daysWithMenus = [];

    // iterate over each day between startDate and endDate
    for (
      let i: Date = this._startDate;
      moment(i).isSameOrBefore(this._endDate);
      i = moment(i).add(1, 'day').toDate()
    )
      this._daysWithMenus.push({date: i, menus: []});

  }
}
