<mat-card class="body-wrapper">

  <mat-card-content>

    <app-return-button [routerLink]="'/dashboard'" [text]="'Dashboard'"></app-return-button>

    <h1>Bestellungen</h1>

    <button class="add-btn" mat-stroked-button color="primary" (click)="add()">
      <mat-icon>add</mat-icon>
      Erstellen
    </button>


    <div class="filter-pagination-wrapper">

      <mat-form-field appearance="fill">
        <mat-label>Filter</mat-label>

        <mat-icon matPrefix>filter_list</mat-icon>

        <mat-select [(ngModel)]="filter" (ngModelChange)="changeFilter()">
          <mat-option value="none">Kein Filter</mat-option>
          <mat-option value="canceled">Stornierte</mat-option>
          <mat-option value="unpaid">Nicht bezahlte</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field appearance="fill">
        <mat-label>Bestellnummer</mat-label>
        <mat-icon matPrefix>search</mat-icon>

        <input type="number" matInput [(ngModel)]="searchOrderId" (ngModelChange)="search()">

        <!--        <button mat-button *ngIf="searchOrderId" matSuffix mat-icon-button aria-label="Clear"-->
        <!--                (click)="resetSearch()">-->
        <!--          <mat-icon>close</mat-icon>-->
        <!--        </button>-->

        <button *ngIf="searchOrderId" matSuffix mat-icon-button aria-label="Clear"
                (click)="resetSearch()">
          <mat-icon>close</mat-icon>
        </button>

      </mat-form-field>

      <app-pagination [paginationInfo]="paginationInfo"></app-pagination>

    </div>

    <div *ngIf="ordersWithOpenCancelation">
      <app-order-item *ngFor="let order of ordersWithOpenCancelation" [order]="order" [user]="order.user">
      </app-order-item>
    </div>

    <app-order-item *ngFor="let order of orders | async | hideWithOpenCancelation" [order]="order" [user]="order.user">
    </app-order-item>

    <app-pagination [paginationInfo]="paginationInfo"></app-pagination>

  </mat-card-content>

</mat-card>
