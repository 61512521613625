import { Component, OnInit, Inject } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { User } from '../../../model/user.model';
import { UserService } from '../../../services/user.service';
import { map, startWith, take } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CreateUserPdfComponent } from '../create-user-pdf/create-user-pdf.component';
import { ActivatedRoute } from '@angular/router';
import { Company } from 'src/app/model/company.model';
import { CompanyService } from 'src/app/services/company.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-edit-user',
  templateUrl: './edit-user.component.html',
  styleUrls: ['./edit-user.component.scss']
})
export class EditUserComponent implements OnInit {

  editUserForm: UntypedFormGroup = new UntypedFormGroup({
    'firstname': new UntypedFormControl(this.user.firstname, [Validators.required]),
    'lastname': new UntypedFormControl(this.user.lastname, [Validators.required]),
    'role': new UntypedFormControl(this.user.role, [Validators.required]),
    'company': new UntypedFormControl(this.user.company)
  });
  currentUser: User;
  companyOptions: Observable<Company[]>;

  constructor(@Inject(MAT_DIALOG_DATA) public user: User,
    private dialogRef: MatDialogRef<EditUserComponent>,
    private userService: UserService,
    private companyService: CompanyService,
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
    private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.userService.user.subscribe(user => this.currentUser = user);

    // enables autocompletion for company input field
    this.companyService.getAll();
    this.companyService.companies.subscribe((companies: Company[]) => {
      this.companyOptions = this.editUserForm.get("company").valueChanges
        .pipe(
          startWith(''),
          map(value => typeof value === 'string' ? value : value.name),
          map(name => name ?
            companies.filter(option => option.name.toLowerCase().indexOf(name.toLowerCase()) >= 0) :
            companies.slice())
        );
    })
  }

  displayFn(company: Company): string {
    return company && company.name ? company.name : '';
  }

  edit() {
    this.userService.update(
      this.user.id,
      this.editUserForm.get("firstname").value,
      this.editUserForm.get("lastname").value,
      this.editUserForm.get("role").value,
      this.editUserForm.get("company").value.id
    ).pipe(take(1)).subscribe((user: User) => {

      this.snackBar.open(`Der Benutzer "${user.firstname} ${user.lastname}" wurde bearbeitet`, "Schließen", { duration: 5000 });
      this.dialog.open(CreateUserPdfComponent, {
        data: {
          "user": user,
          "password": user.generated_password,
          "company": user.company
        }
      });

      this.userService.getAll(+(this.route.snapshot.queryParams["page"] ?? 1));
      this.dialogRef.close(user);

    }, error => this.snackBar.open("Beim Bearbeiten ist ein Fehler aufgetreten", "Schließen", { duration: 10000, panelClass: ["warn-snackbar"] }))
  }

  cancel() {
    this.dialogRef.close(false);
  }

  isObject(val): boolean { return val instanceof Object; }
}
