import { Component, OnInit, Pipe } from '@angular/core';
import { Company } from 'src/app/model/company.model';
import { Observable } from 'rxjs';
import { Order } from 'src/app/model/order.model';
import { OrderService } from '../../../../services/order.service';
import { CompanyService } from '../../../../services/company.service';
import { ActivatedRoute, Router } from '@angular/router';
import { take } from 'rxjs/operators';
import { PaginationInfo } from '../../../../utils/pagination-info.model';

@Component({
  selector: 'app-company-orders',
  templateUrl: './company-orders.component.html',
  styleUrls: ['./company-orders.component.scss']
})
export class CompanyOrdersComponent implements OnInit {

  company: Company;
  orders: Observable<Order[]>;
  paginationInfo: PaginationInfo;
  filter: string;

  constructor(private orderService: OrderService,
    private companyService: CompanyService,
    private route: ActivatedRoute,
    private router: Router) { }

  ngOnInit(): void {
    this.orderService.paginationInfo.subscribe(pi => this.paginationInfo = pi);
    this.orders = this.orderService.orders;
    this.route.params.subscribe(params =>
      this.companyService.getOne(+params['company_id']).pipe(take(1)).subscribe((company: Company) => {
        this.company = company;
        this.orderService.getByCompany(this.company.id, +(this.route.snapshot.queryParams['page'] ?? 1),this.route.snapshot.queryParams['filter']);
      })
    );

    this.route.queryParams.subscribe(params =>
      this.companyService.getOne(+this.route.snapshot.params['company_id']).pipe(take(1)).subscribe((company: Company) => {
        
        this.company = company;

        this.filter = params['filter'] ?? 'none';

        this.orderService.getByCompany(+this.route.snapshot.params['company_id'], +(params['page'] ?? 1),this.filter);

      }));
  }

  changeFilter() {
    this.router.navigate([], { queryParams: { 'page': 1, 'filter': this.filter }, queryParamsHandling: 'merge' });
  }

}
