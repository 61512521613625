import { Component, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { User } from 'src/app/model/user.model';
import { Company } from '../../../model/company.model';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
pdfMake.vfs = pdfFonts.pdfMake.vfs;


@Component({
  selector: 'app-create-user-pdf',
  templateUrl: './create-user-pdf.component.html',
  styleUrls: ['./create-user-pdf.component.scss']
})
export class CreateUserPdfComponent implements OnInit {

  user: User;
  company: Company;
  password: string;

  constructor(private dialogRef: MatDialogRef<CreateUserPdfComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.user = data.user;
    this.password = data.password;
    this.company = data.company;
  }

  ngOnInit(): void {
  }

  cancel() {
    this.dialogRef.close();
  }

  download() {
    if (this.password) {
      var documentDefinition = {
        content: [
          {
            table: {
              headerRows: 1,
              body: [
                [
                  { text: 'Vorname', bold: true },
                  { text: 'Nachname', bold: true },
                  { text: 'Benutzername', bold: true },
                  { text: 'Passwort', bold: true },
                  { text: 'Domäne', bold: true },
                  { text: 'Firmenname', bold: true }
                ],
                [
                  this.user.firstname,
                  this.user.lastname,
                  this.user.username,
                  this.password,
                  this.company?.domain ?? "",
                  this.company?.name ?? ""
                ]
              ]
            }
          }]
      };
    } else {
      var documentDefinition = {
        content: [
          {
            table: {
              headerRows: 1,
              body: [
                [
                  { text: 'Vorname', bold: true },
                  { text: 'Nachname', bold: true },
                  { text: 'Benutzername', bold: true },
                  { text: 'Domäne', bold: true },
                  { text: 'Firmenname', bold: true }
                ],
                [
                  this.user.firstname,
                  this.user.lastname,
                  this.user.username,
                  this.company?.domain ?? "",
                  this.company?.name ?? ""
                ]
              ]
            }
          }]
      };
    }
    pdfMake.createPdf(documentDefinition).download(`${this.user.firstname}_${this.user.lastname}_Zugangsdaten`);
  }

}
