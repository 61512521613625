<h1 mat-dialog-title>Benachrichtigungsdetails</h1>
<mat-dialog-content>
    <h2>Titel</h2>
    <p>{{message.subject}}</p>
    <h2>Nachricht</h2>
    <p>{{message.message}}</p>
    <h3>
        <strong>
            {{(message.send_time && parseDate(message.send_time).getTime()<=now.getTime())?'Gesendet am:':'Geplant für:'}}
        </strong>
        {{message.send_time |dateTimeFormatFilter:'D.MM.YYYY HH:mm'}}
    </h3>
    <h3 *ngIf="message.company">
        <strong>An: </strong>
        <span class="reciever-link" [routerLink]="['/companies',message.company_id]" (click)="close()">
            {{message.company.name}}
        </span>
    </h3>
    <h3 *ngIf="message.user">
        <strong>An: </strong>
        {{message.user.firstname}} {{message.user.lastname}}
    </h3>
    <h3 *ngIf="message.user && message.user.company">
        <strong>Firma: </strong>
        <span class="reciever-link" [routerLink]="['/companies',message.user.company_id]" (click)="close()">
            {{message.user.company.name}}
        </span>
    </h3>
</mat-dialog-content>
<mat-dialog-actions>
    <button mat-button mat-dialog-close>Schließen</button>
    <button *ngIf="parseDate(message.send_time).getTime()>now.getTime()" mat-stroked-button (click)="remove()"
        cdkFocusInitial color="warn">Löschen</button>
</mat-dialog-actions>
