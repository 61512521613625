import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {CompanyType} from 'src/app/model/company-type.model';
import {GroupedCompanyOrderStatsTableHeader} from './grouped-company-order-stats-table-header.model';
import {GroupedCompanyOrderStatsTableElement} from './grouped-company-order-stats-table-element.model';
import {Menu} from 'src/app/model/menu.model';
import {MatDialog} from '@angular/material/dialog';
import {MenuDetailsComponent} from 'src/app/components/menus/menu-details/menu-details.component';
import {take} from 'rxjs/operators';
import {CompanyService} from '../../../../services/company.service';
import {Subscription} from 'rxjs';
import {GroupedCompanyOrderStatsTableFooter} from './grouped-company-order-stats-table-footer.model';

@Component({
  selector: 'app-grouped-company-order-stats-table',
  templateUrl: './grouped-company-order-stats-table.component.html',
  styleUrls: ['./grouped-company-order-stats-table.component.scss']
})
export class GroupedCompanyOrderStatsTableComponent implements OnInit, OnChanges, OnDestroy {

  @Input() company_type: CompanyType;
  @Input() gcostHeader: GroupedCompanyOrderStatsTableHeader;
  @Output() calculatedGcostFooter = new EventEmitter<GroupedCompanyOrderStatsTableFooter>();


  gcostElementsSub: Subscription;
  gcostElements: GroupedCompanyOrderStatsTableElement[];
  gcostFooter: GroupedCompanyOrderStatsTableFooter;

  constructor(private dialog: MatDialog,
              private companyService: CompanyService) {
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    // dont apply changes if input is null
    if (this.company_type == null || this.gcostHeader == null)
      return;

    this.gcostElements = [];

    this.gcostElementsSub = this.companyService.getByCompanyTypeWithMenus(
      this.makeDateString(this.gcostHeader.startDate),
      this.makeDateString(this.gcostHeader.endDate),
      this.company_type.id)
      .pipe(take(1))
      .subscribe(
        companies => companies.forEach(company => this.gcostElements.push(new GroupedCompanyOrderStatsTableElement(company, company.menus, this.gcostHeader))),
        error => console.log(error),
        () => {
          this.gcostFooter = new GroupedCompanyOrderStatsTableFooter(this.gcostHeader, this.gcostElements);
          this.calculatedGcostFooter.emit(this.gcostFooter);
        }
      );
  }

  ngOnDestroy(): void {
    this.gcostElementsSub.unsubscribe();
    this.company_type = null;
    this.gcostElements = null;
    this.gcostHeader = null;
  }

  openMenuDetails(menu: Menu): void {
    this.dialog.open(MenuDetailsComponent, {
      data: menu
    });
  }

  private makeDateString(date: Date): string {
    return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
  }

  // createPDF(): void {
  //   let data = document.getElementById('groupedStatsTable-' + this.company_type.name);   // Id of the table
  //   html2canvas(data).then(canvas => {
  //     // Few necessary setting options
  //     let imgWidth = 208;
  //     let pageHeight = 295;
  //     let imgHeight = canvas.height * imgWidth / canvas.width;
  //     let heightLeft = imgHeight;
  //
  //     const contentDataURL = canvas.toDataURL('image/png');
  //     let pdf = new jsPDF('p', 'mm', 'a4'); // A4 size page of PDF
  //     let position = 0;
  //     pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight);
  //     let pdfName = 'Bestellungsübersicht ' + this.company_type.name + '.pdf';
  //     pdf.save(pdfName); // Generated PDF
  //   });
  // }

}
