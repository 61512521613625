import { Component, OnInit, OnDestroy } from '@angular/core';
import { PaginationInfo } from '../../../utils/pagination-info.model';
import { MessageService } from '../../../services/message.service';
import { Observable, Subscription } from 'rxjs';
import { Message } from 'src/app/model/message.model';
import { ActivatedRoute } from '@angular/router';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-all-messages',
  templateUrl: './all-messages.component.html',
  styleUrls: ['./all-messages.component.scss']
})
export class AllMessagesComponent implements OnInit {

  paginationInfo: PaginationInfo;
  messages: Observable<Message[]>;
  querySub: Subscription;;

  constructor(private messageService: MessageService,
    private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.messages = this.messageService.messages;
    this.messageService.paginationInfo.subscribe(pi => this.paginationInfo = pi);
  }
}
