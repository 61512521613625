<div class="week-pagination-wrapper">
    <button mat-stroked-button color="primary" matTooltipPosition="above"
        [matTooltip]="(startDatePreviousWeek|date:'dd. MM. yyyy')+' – '+(endDatePreviousWeek|date:'dd. MM. yyyy')"
        (click)="previousWeek()">
        <mat-icon>chevron_left</mat-icon>
        <span>KW {{(getWeekNumber()-1)!=0?(getWeekNumber()-1):52}}</span>
    </button>
    <button mat-stroked-button color="primary" matTooltipPosition="above"
        [matTooltip]="(startDateNextWeek|date:'dd. MM. yyyy')+' – '+(endDateNextWeek|date:'dd. MM. yyyy')"
        (click)="nextWeek()">
        <span>
            KW {{(getWeekNumber()+1)!=53?(getWeekNumber()+1):1}}
        </span>
        <mat-icon>chevron_right</mat-icon>
    </button>
</div>