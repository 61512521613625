import {Menu} from '../../model/menu.model';
import {User} from 'src/app/model/user.model';
import * as moment from 'moment';

// this class is for transforming the api output to rows of the WeeklyUsersOrderStats table
// the constructor needs the startDate, the endDate, the user and all of the menus the user ordered in the timespan between startDate and endDate
export class WeeklyUsersOrderStatsElement {

  private _days: { date: Date, meatCount: number, vegCount: number, specialCount: number, lowCarbCount: number }[];

  public get days(): { date: Date, meatCount: number, vegCount: number, specialCount: number, lowCarbCount: number }[] {
    return this._days.sort((a, b) => a.date < b.date ? -1 : (a.date > b.date ? 1 : 0));
  }

  public get menuCount(): number {
    return this._menus.length;
  }

  public constructor(
    private _startDate: Date,
    private _endDate: Date,
    public user: User,
    private _menus: Menu[]) {

    this.transformMenusToDays();

  }

  private transformMenusToDays(): void {
    this.createEmptyDayList();

    this._menus.forEach(menu => {

      this._days.forEach(d => {
        if (moment(menu.available_date).isSame(d.date)) {

          // increase count for special, vegetarian or meat menus
          if (menu.low_carb)
            d.lowCarbCount++;
          else if (menu.special)
            d.specialCount++;
          else if (menu.vegetarian)
            d.vegCount++;
          else
            d.meatCount++;

          return;
        }
      });

    });

  }

  // will create the initial empty day array of type { date: Date, meatCount: number, vegCount: number }[]
  // and will initialize meatCount and vegCount with 0
  private createEmptyDayList(): void {

    this._days = [];

    // iterate over each day between startDate and endDate
    for (
      let i: Date = this._startDate;
      moment(i).isSameOrBefore(this._endDate);
      i = moment(i).add(1, 'day').toDate()
    )
      this._days.push({date: i, meatCount: 0, vegCount: 0, specialCount: 0, lowCarbCount: 0});

  }
}
