<h3 *ngIf="companiesWithGroupedMenus?.length==0 && menusSumByDate?.length==0 && !loading">Für diese Woche wurden
  noch keine Menüs angelegt</h3>

<h3 *ngIf="companiesWithGroupedMenus?.length==0 && menusSumByDate?.length>0 && !loading">In dieser Woche wurden noch
  keine Menüs bestellt</h3>

<div class="table-responsive">

  <table *ngIf="companiesWithGroupedMenus?.length>0 && menusSumByDate?.length>0 && !loading">
    <thead>

    <tr>

      <th class="customer" colspan="1">
        Kunde
      </th>

      <th *ngFor="let md of menusByDate|sortMenusByDate" class="date-dish-wrapper" [colSpan]="md.menus.length">
        <div class="date">{{md.date |date:'EEEE, d. M.'}}</div>
      </th>

    </tr>

    <tr>

      <th class="invisible"></th>

      <th *ngFor="let menu of menus|async|sortMenus" [matTooltip]="menu.name" class="menu"
          (click)="openMenuDetails(menu)"
          [ngClass]="{
          'vegetarian':menu.vegetarian,
          'meat':!menu.vegetarian,
          'low-carb':menu.low_carb,
          'special':menu.special}">
        {{menu.special ? "Special" : (menu.vegetarian ? "Veggie" : "Fleisch")}} <span class="id">ID:{{menu.id}}</span>
      </th>

    </tr>

    </thead>

    <tbody>

    <tr *ngFor="let cgm of companiesWithGroupedMenus | sortCompaniesByName">

      <td class="company" [routerLink]="['/companies',cgm.company_id]">
        {{cgm.company_name}}
      </td>

      <td *ngFor="let gm of cgm.groupedMenus|sortGroupedMenus" class="menu-count"
          [ngClass]="{
          'vegetarian':gm.vegetarian,
          'meat':!gm.vegetarian,
          'low-carb':gm.low_carb,
          'special':gm.special}">
        {{gm.menu_count}}
      </td>

    </tr>

    </tbody>

    <tfoot>

    <tr>

      <td class="total">Gesamt</td>

      <td *ngFor="let ms of menusSum|sortMenusSum" class="menu-sum"
          [ngClass]="{
          'vegetarian':ms.vegetarian,
          'meat':!ms.vegetarian,
          'low-carb':ms.low_carb,
          'special':ms.special}">
        {{ms.menu_count}} <span class="id">ID:{{ms.menu_id}}</span></td>

    </tr>

    <tr>
      <td class="invisible"></td>

      <td *ngFor="let msd of menusSumByDate|sortMenusSumByDate" class="menu-sum menu-sum-by-date"
          [colSpan]="msd.availableMenusCount">{{msd.menu_count}}</td>

    </tr>

    </tfoot>
  </table>

</div>

<mat-spinner *ngIf="loading"></mat-spinner>
