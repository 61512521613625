import {Menu} from '../../../../model/menu.model';
import {Company} from '../../../../model/company.model';
import {GroupedCompanyOrderStatsTableHeader} from './grouped-company-order-stats-table-header.model';

// this model represents a row in the body of the GroupedCompanyOrderStatsTableComponent
// it needs a company with all its ordered menus in a given timespan and a GroupedCompanyOrderStatsTableHeader
export class GroupedCompanyOrderStatsTableElement {

  private _daysWithMenus: { date: Date, menus: { menu: Menu, count: number }[] }[];

  public get daysWithMenus(): { date: Date, menus: { menu: Menu, count: number }[] }[] {
    let returnDWM = this._daysWithMenus;

    // sort menus of each day by id
    returnDWM.map(dwm => dwm.menus.sort((a, b) => a.menu.id < b.menu.id ? -1 : (a.menu.id > b.menu.id ? 1 : 0)));

    // sort days by date
    return returnDWM.sort((a, b) => a.date < b.date ? -1 : (a.date > b.date ? 1 : 0));
  }

  public constructor(
    public company: Company,
    private _menus: Menu[],
    private _gcostHeader: GroupedCompanyOrderStatsTableHeader
  ) {
    this.groupMenus();
  }

  private groupMenus(): void {
    this._daysWithMenus = [];

    this._gcostHeader.daysWithMenus.forEach(dwm => {
      let newDwm = {date: dwm.date, menus: []};

      dwm.menus.forEach(m => {
        let newMenuElement = {menu: m, count: 0};

        // count how many menus were ordered
        this._menus.forEach(m => {
          if (m.id == newMenuElement.menu.id)
            newMenuElement.count++;
        });

        newDwm.menus.push(newMenuElement);
      });
      this._daysWithMenus.push(newDwm);
    });
  }
}
