import { Pipe, PipeTransform } from '@angular/core';
import { Dish } from 'src/app/model/dish.model';

@Pipe({
  name: 'searchDish',
  pure: false
})
export class SearchDishPipe implements PipeTransform {

  transform(dishes: Dish[], searchString: string): Dish[] {
    return dishes.filter(d => d.name.toLowerCase().includes(searchString));
  }

}
