import {GroupedCompanyOrderStatsTableHeader} from './grouped-company-order-stats-table-header.model';
import {GroupedCompanyOrderStatsTableElement} from './grouped-company-order-stats-table-element.model';
import {Menu} from '../../../../model/menu.model';
import * as moment from 'moment';

// this model is for grouping and sorting a given list of menus to the footer of the GroupedCompanyOrderStatsTableComponent
// it takes as input the header and all of the rows (elements of the table
export class GroupedCompanyOrderStatsTableFooter {

  private _daysWithMenus: { date: Date, menuCount: number, menus: { menu: Menu, count: number }[] }[];

  public get daysWithMenus(): { date: Date, menuCount: number, menus: { menu: Menu, count: number }[] }[] {
    let returnDWM = this._daysWithMenus;

    // sort menus array of each day by id
    returnDWM.map(dwm => dwm.menus.sort((a, b) => a.menu.id < b.menu.id ? -1 : (a.menu.id > b.menu.id ? 1 : 0)));

    // sort days by date
    return returnDWM.sort((a, b) => a.date < b.date ? -1 : (a.date > b.date ? 1 : 0));
  }

  constructor(private _gcostHeader: GroupedCompanyOrderStatsTableHeader,
              private _gcostElements: GroupedCompanyOrderStatsTableElement[]) {
    this.sumMenus();
  }

  private sumMenus(): void {
    this.createEmptyDayList();

    // iterate through every row of the gcost table
    this._gcostElements.forEach(e => {

      e.daysWithMenus.forEach(dwm => {
        let currentDwm = this._daysWithMenus.find(cdwm => moment(cdwm.date).isSame(dwm.date));

        dwm.menus.forEach(m => {
          let currentMenu = currentDwm.menus.find(cm => cm.menu.id == m.menu.id);
          currentMenu.count += m.count;
          currentDwm.menuCount += m.count;
        });

      });

    });
  }

  // will create the inital list with all the distinct menus in the week
  // will set the menu count to 0
  private createEmptyDayList(): void {
    this._daysWithMenus = [];

    this._gcostHeader.daysWithMenus.forEach(dwm => {
      let newDwm = {date: dwm.date, menuCount: 0, menus: []};

      dwm.menus.forEach(m => newDwm.menus.push({menu: m, count: 0}));

      this._daysWithMenus.push(newDwm);
    });

  }
}
