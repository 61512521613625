<div class="month-pagination-wrapper">
    <button mat-stroked-button color="primary"
        (click)="gotoPreviousMonth()">
        <mat-icon>chevron_left</mat-icon>
        <span>
            {{previousMonth|date:"MMMM"}}
        </span>
    </button>
    <button mat-stroked-button color="primary" 
        (click)="gotoNextMonth()">
        <span>
            {{nextMonth|date:"MMMM"}}
        </span>
        <mat-icon>chevron_right</mat-icon>
    </button>
</div>