<h1 mat-dialog-title>{{menu.name}}</h1>
<mat-dialog-content>
    <img [src]="url+menu.image_url" *ngIf="menu.image_url">
    <h2 *ngIf="menu.description">Beschreibung</h2>
    <p>{{menu.description}}</p>
    <h3><strong>Verfügbares Datum: </strong>{{menu.available_date |date:"dd.MM.yyyy"}}</h3>
    <h3><strong>Preis: </strong>€ {{menu.price}}</h3>
    <h3><strong>Bestellungen: </strong>{{menu.order_count}}</h3>
    <h2 *ngIf="menu.allergens?.length>0">Allergene</h2>
    <ul *ngIf="menu.allergens?.length>0" class="allergen-wrapper">
        <li *ngFor="let allergen of menu.allergens">{{allergen.abbreviation}} - {{allergen.name}}</li>
    </ul>
</mat-dialog-content>
<mat-dialog-actions>
    <button mat-button mat-dialog-close>Schließen</button>
  </mat-dialog-actions>