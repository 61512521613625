import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import * as moment from 'moment';
import {combineLatest} from 'rxjs';
import {take, tap} from 'rxjs/operators';
import {Company} from 'src/app/model/company.model';
import {CompanyService} from 'src/app/services/company.service';
import {UserService} from 'src/app/services/user.service';
import {WeeklyUsersOrderStatsElement} from './weekly-users-order-stats-element.model';

@Component({
  selector: 'app-weekly-users-order-stats',
  templateUrl: './weekly-users-order-stats.component.html',
  styleUrls: ['./weekly-users-order-stats.component.scss']
})
export class WeeklyUsersOrderStatsComponent implements OnInit {

  company: Company;
  startDate: Date;
  endDate: Date;
  wuosElements: WeeklyUsersOrderStatsElement[];

  constructor(private companyService: CompanyService,
              private userService: UserService,
              private route: ActivatedRoute) {
  }

  ngOnInit(): void {

    // combine the last values of the query params and the current company
    combineLatest([this.route.queryParams, this.companyService.getOne(+this.route.snapshot.params['company_id'])])
      .pipe(
        // fetch all necessary menu data
        tap(([params, company]) => {
          this.wuosElements = null;
          this.company = company;

          if (moment(params['date'], "YYYY-M-D", true).isValid())
            this.startDate = moment(params['date'], "YYYY-M-D").toDate(); // parse date with moment.js to also work in Safari
          else
            this.startDate = new Date();

          this.endDate = moment(params['date'], "YYYY-M-D").toDate(); // parse date with moment.js to also work in Safari
          this.endDate.setDate(this.endDate.getDate() + 3);

          this.userService.getUsersByCompanyWithMenus(company.id, this.makeDateString(this.startDate), this.makeDateString(this.endDate))
            .pipe(
              take(1),
              tap(usersWithMenus => {
                this.wuosElements = [];

                // create element list for table
                usersWithMenus.forEach(uwm =>
                  this.wuosElements.push(new WeeklyUsersOrderStatsElement(
                    this.startDate,
                    this.endDate,
                    {...uwm},
                    uwm.menus)));

              }))
            .subscribe();

        })
      ).subscribe();


  }


  sumMenusOnDay(day: Date): number {

    return this.wuosElements.reduce<number>((menuCount, wuos) => {
      let d = wuos.days.find(d => moment(day).isSame(d.date));

      if (d != undefined)
        return menuCount + d.meatCount + d.vegCount + d.lowCarbCount;

      return 0;

    }, 0);
  }

  sumAllMenus(): number {
    return this.wuosElements.reduce<number>((menuCount, wuos) => menuCount + wuos.menuCount, 0);
  }

  getWeekNumber() {
    var tdt = new Date(this.startDate);
    var dayn = (this.startDate.getDay() + 6) % 7;
    tdt.setDate(tdt.getDate() - dayn + 3);
    var firstThursday = tdt.valueOf();
    tdt.setMonth(0, 1);
    if (tdt.getDay() !== 4) {
      tdt.setMonth(0, 1 + ((4 - tdt.getDay()) + 7) % 7);
    }
    return 1 + Math.ceil((firstThursday - tdt.valueOf()) / 604800000);
  }

  private makeDateString(date: Date): string {
    return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`
  }
}
