import { Component, OnInit } from '@angular/core';
import { CompanyService } from '../../../services/company.service';
import { ActivatedRoute } from '@angular/router';
import { Company } from 'src/app/model/company.model';
import { take } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { EditCompanyComponent } from '../edit-company/edit-company.component';
import { User } from 'src/app/model/user.model';
import { UserService } from '../../../services/user.service';

@Component({
  selector: 'app-company-dashboard',
  templateUrl: './company-dashboard.component.html',
  styleUrls: ['./company-dashboard.component.scss']
})
export class CompanyDashboardComponent implements OnInit {

  date: Date = new Date();
  startDateString: string;
  endDateString: string;
  company: Company;
  currentUser: User;


  constructor(private companyService: CompanyService,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private userService: UserService) { }

  ngOnInit(): void {
    this.setStartAndEndDateOfCurrentWeek();
    this.route.params.subscribe(params => {
      this.companyService.getOne(+params['company_id']).pipe(take(1)).subscribe((company: Company) => this.company = company);
    });
    this.userService.user.subscribe(user => this.currentUser = user);
  }

  edit() {
    var dialogRef = this.dialog.open(EditCompanyComponent, {
      width: "600px",
      data: this.company
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result)
        this.companyService.getOne(this.company.id).pipe(take(1)).subscribe((company: Company) => this.company = company);
    });
  }


  private setStartAndEndDateOfCurrentWeek() {
    var curr = new Date();
    var first = curr.getDate() - ((curr.getDay() == 0 ? 7 : curr.getDay()) - 1);
    var startDate = new Date(curr.setDate(first));
    this.startDateString = `${startDate.getFullYear()}-${startDate.getMonth() + 1}-${startDate.getDate()}`;

    var endDate = startDate;
    endDate.setDate(startDate.getDate() + 3);
    this.endDateString = `${endDate.getFullYear()}-${endDate.getMonth() + 1}-${endDate.getDate()}`;
  }
}
