import {Component, OnInit, Inject} from '@angular/core';
import {UntypedFormGroup, UntypedFormControl, Validators} from '@angular/forms';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {DishService} from '../../../services/dish.service';
import {AllergenService} from '../../../services/allergen.service';
import {Dish} from 'src/app/model/dish.model';
import {take} from 'rxjs/operators';
import {Allergen} from 'src/app/model/allergen.model';
import {environment} from '../../../../environments/environment';

@Component({
  selector: 'app-edit-dish',
  templateUrl: './edit-dish.component.html',
  styleUrls: ['./edit-dish.component.scss']
})
export class EditDishComponent implements OnInit {

  editDishForm: UntypedFormGroup = new UntypedFormGroup({
    name: new UntypedFormControl(this.dish.name, [Validators.required]),
    price: new UntypedFormControl(this.dish.price, [Validators.required, Validators.min(0)]),
    description: new UntypedFormControl(this.dish.description),
    vegetarian: new UntypedFormControl(this.dish.vegetarian, [Validators.required]),
    special: new UntypedFormControl(this.dish.special, [Validators.required]),
    low_carb: new UntypedFormControl(this.dish.low_carb, [Validators.required])
  });

  image: any = null;
  allergensCbx: { checked: boolean, allergen: Allergen }[] = [];
  url: string = environment.url;

  constructor(private dialogRef: MatDialogRef<EditDishComponent>,
              private snackbar: MatSnackBar,
              private dishService: DishService,
              private allergenService: AllergenService,
              @Inject(MAT_DIALOG_DATA) public dish: Dish) {
  }

  ngOnInit(): void {
    this.allergenService.getAll().pipe(take(1)).subscribe((allergens: Allergen[]) => {
      allergens.forEach(a1 => {
          var set = false;
          this.dish.allergens.forEach(a2 => {
            if (a1.id == a2.id) {
              this.allergensCbx.push({checked: true, allergen: a1});
              set = true;
              return;
            }
          });
          if (!set)
            this.allergensCbx.push({checked: false, allergen: a1});
        }
      );
    });
  }

  cancel() {
    this.dialogRef.close();
  }

  edit() {
    var formData = new FormData()
    formData.append('name', this.editDishForm.get('name').value);
    formData.append('price', this.editDishForm.get('price').value);
    formData.append('description', this.editDishForm.get('description').value ?? '');
    formData.append('vegetarian', this.editDishForm.get('vegetarian').value);
    formData.append('special', this.editDishForm.get('special').value);
    formData.append('low_carb', this.editDishForm.get('low_carb').value);

    if (this.dish.image_url)
      formData.append('image_url', this.dish.image_url);

    if (this.image)
      formData.append('image', this.image);

    var allergenIds = [];
    this.allergensCbx.forEach(a => a.checked ? allergenIds.push(a.allergen.id) : null);
    formData.append('allergens', JSON.stringify(allergenIds));

    this.dishService.update(this.dish.id, formData).pipe(take(1)).subscribe((dish: Dish) => {
      this.snackbar.open("Das Gericht wurde erfolgreich bearbeitet", "Schließen", {duration: 5000});
      this.dishService.getAll();
      this.dialogRef.close();
    }, error => this.snackbar.open(error.error.error, "Schließen", {duration: 10000, panelClass: ['warn-snackbar']}))
  }

  imageUpload(event) {
    this.image = event.target.files[0];
  }

}
