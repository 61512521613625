import { Component, OnInit } from '@angular/core';
import { Company } from '../../model/company.model';
import { ActivatedRoute, Router } from '@angular/router';
import { CompanyService } from '../../services/company.service';
import { take, startWith } from 'rxjs/operators';
import { UserService } from '../../services/user.service';
import { User } from '../../model/user.model';

@Component({
  selector: 'app-users-order-stats',
  template: `
    <mat-card class="body-wrapper" *ngIf="company">

      <app-return-button [path]="'/companies/'+company.id" [text]="company.name"></app-return-button>

      <h1>Bestellungen Mitarbeiter im {{startDate|date:"MMMM yyyy"}}</h1>

      <button mat-raised-button (click)="changeView()">
        <mat-icon>table_view</mat-icon>
        <span>{{view=='monthly'?"Zur Tagesübersicht wechseln":"Zur Monatsübersicht wechseln"}}</span>
      </button>

      <app-month-pagination></app-month-pagination>

      <router-outlet></router-outlet>

    </mat-card>

    <app-loading-animation [condition]="!company"></app-loading-animation>`,
  styles: [
    'h1 { text-align: center; }',
    'button { margin: 0 auto; display: block; }'
  ]
})
export class UsersOrderStatsComponent implements OnInit {

  company: Company;
  startDate: Date;
  view: string;

  constructor(private route: ActivatedRoute,
    private router: Router,
    private companyService: CompanyService) { }

  ngOnInit(): void {
    this.companyService.getOne(+this.route.snapshot.params['company_id']).pipe(take(1)).subscribe((company: Company) => this.company = company);

    this.view = this.route.snapshot.firstChild.url[0]?.path ?? 'monthly';


    this.route.queryParams.subscribe(params => {

      let currentDate: Date = new Date;
      let month: number = +(params['month'] ?? currentDate.getMonth());
      let year: number = +(params['year'] ?? currentDate.getFullYear());

      this.startDate = new Date(year, month, 1);
    });
  }

  changeView() {
    if (this.view == 'monthly') {
      this.router.navigate(['/companies', this.company.id, 'users-order-stats', 'daily'], { queryParamsHandling: 'preserve' });
      this.view = 'daily';
    } else {
      this.router.navigate(['/companies', this.company.id, 'users-order-stats', 'monthly'], { queryParamsHandling: 'preserve' });
      this.view = 'monthly';
    }
  }
}
