import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'shorten'
})
export class ShortenPipe implements PipeTransform {

  transform(value: string, maxlength: number = 40): string {
    return value.substring(0, maxlength) + ((value.length > maxlength) ? '...' : '');
  }

}
