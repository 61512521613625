import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';

import { MenuService } from 'src/app/services/menu.service';
import { DishService } from 'src/app/services/dish.service';
import { Dish } from 'src/app/model/dish.model';
import { startWith, map, take } from 'rxjs/operators';
import { Menu } from 'src/app/model/menu.model';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-add-menu-fast',
  templateUrl: './add-menu-fast.component.html',
  styleUrls: ['./add-menu-fast.component.scss']
})
export class AddMenuFastComponent implements OnInit {

  addMenuForm: UntypedFormGroup = new UntypedFormGroup({
    "available_date": new UntypedFormControl('', [Validators.required]),
    "dish": new UntypedFormControl('', [Validators.required])
  });

  dishOptions: Observable<Dish[]>;

  @ViewChild('dish') dishInput: ElementRef;

  constructor(private dialog: MatDialog,
    private snackbar: MatSnackBar,
    private menuService: MenuService,
    private dishService: DishService,
    private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.dishService.getAll();
    this.dishService.dishes.subscribe((dishes: Dish[]) => {
      this.dishOptions = this.addMenuForm.get("dish").valueChanges
        .pipe(
          startWith(''),
          map(value => typeof value === 'string' ? value : value.name),
          map(name => name ?
            dishes.filter(option => option.name.toLowerCase().indexOf(name.toLowerCase()) >= 0) :
            dishes.slice())
        );
    })
  }

  displayFn(dish: Dish): string {
    return dish && dish.name ? dish.name : '';
  }

  isObject(val): boolean { return val instanceof Object; }

  add() {
    var dish: Dish = this.addMenuForm.get("dish").value;
    var available_date: Date = new Date(this.addMenuForm.get("available_date").value);
    var datestring: string = available_date.getFullYear() + '-' + (available_date.getMonth() + 1) + '-' + available_date.getDate();
    this.menuService.create(datestring, dish.id).pipe(take(1)).subscribe((menu: Menu) => {
      console.log(this.route.snapshot.queryParams['to']);
      this.menuService.getAll(this.route.snapshot.queryParams['from'], this.route.snapshot.queryParams['to']);
      this.addMenuForm.get("dish").setValue('');
      this.snackbar.open("Das Menü wurde hinzugefügt", "Schließen", { duration: 5000 });
      this.dishInput.nativeElement.focus();
    }, error => this.snackbar.open(error.error.error, "Schließen", { duration: 10000, panelClass: ['warn-snackbar'] }));
  }

  dayFilter = (d: Date | null): boolean => {
    const day = (d || new Date()).getDay();
    return day !== 0 && day !== 6 && day !== 5;
  }

}
