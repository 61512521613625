import { Component, Input, OnInit } from '@angular/core';
import { Message } from 'src/app/model/message.model';
import { MessageService } from '../../../services/message.service';
import { take } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { MessageInfoComponent } from '../message-info/message-info.component';

@Component({
  selector: 'app-message-item',
  templateUrl: './message-item.component.html',
  styleUrls: ['./message-item.component.scss']
})
export class MessageItemComponent implements OnInit {

  @Input('message')
  message: Message;
  now: Date = new Date();

  constructor(private messageService:MessageService,
    private snackbar:MatSnackBar,
    private route:ActivatedRoute,
    private dialog:MatDialog) { }

  ngOnInit(): void {
  }

  remove() {
    this.messageService.delete(this.message.id).pipe(take(1)).subscribe(()=>{
      var params = this.route.snapshot.queryParams;
      this.messageService.getAll(+(params['page'] ?? 1), params['for'] ?? 'user');
      this.snackbar.open("Die Benachrichtigung wurde gelöscht","Schließen",{duration:5000})
    },()=>this.snackbar.open("Es ist ein Fehler aufgetreten","Schließen",{duration:10000,panelClass:['warn-snackbar']}))
  }

  openInfo(){
    this.dialog.open(MessageInfoComponent,{
      data:this.message
    })
  }

  parseDate(dateString:string):Date{
    return new Date(dateString.replace(/-/g,"/"));
  }

}
