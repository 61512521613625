import { Pipe, PipeTransform } from '@angular/core';
import { Company } from '../../model/company.model';

@Pipe({
  name: 'searchCompany',
  pure: false
})
export class SearchCompanyPipe implements PipeTransform {

  transform(companies: Company[], searchString: string): Company[] {
    return companies.filter(c => c.name.toLowerCase().includes(searchString.toLowerCase()) || c.domain.toLowerCase().includes(searchString.toLowerCase()))
  }

}
