import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { Message } from 'src/app/model/message.model';
import { MessageService } from 'src/app/services/message.service';
import { PaginationInfo } from 'src/app/utils/pagination-info.model';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-company-messages',
  templateUrl: './company-messages.component.html',
  styleUrls: ['./company-messages.component.scss']
})
export class CompanyMessagesComponent implements OnInit {

  paginationInfo: PaginationInfo;
  messages: Observable<Message[]>;
  querySub: Subscription;

  constructor(private messageService: MessageService,
    private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.messages = this.messageService.messages;
    this.messageService.paginationInfo.subscribe(pi => this.paginationInfo = pi);
  }
}
