import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';

@Component({
  selector: 'app-week-pagination',
  templateUrl: './week-pagination.component.html',
  styleUrls: ['./week-pagination.component.scss']
})
export class WeekPaginationComponent implements OnInit {

  startDate: Date;
  endDate: Date;

  constructor(private route: ActivatedRoute,
    private router: Router) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      if (moment(params['from'], "YYYY-M-D", true).isValid() && moment(params['to'], "YYYY-M-D", true).isValid()) {
        this.startDate = moment(params['from'], "YYYY-M-D").toDate();
        this.endDate = moment(params['to'], "YYYY-M-D").toDate();
      }
    });
  }

  previousWeek() {
    this.startDate.setDate(this.startDate.getDate() - 7);
    this.endDate.setDate(this.endDate.getDate() - 7);
    this.navigate();
  }

  nextWeek() {
    this.startDate.setDate(this.startDate.getDate() + 7);
    this.endDate.setDate(this.endDate.getDate() + 7);
    this.navigate();
  }


  private navigate() {
    this.router.navigate([], {
      queryParams: { 'from': this.makeDateString(this.startDate), 'to': this.makeDateString(this.endDate) },
      queryParamsHandling: 'merge'
    });
  }

  private makeDateString(date: Date): string {
    return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`
  }

}
